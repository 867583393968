import { Box, Card, Grid, Typography, useTheme } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import type { CoachMetricTemplate } from "@trainwell/types";
import type Metric from "src/interfaces/Metric";
import MetricSubmodule from "./MetricSubmodule";

const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

type Props = {
  metricSnapshot: any;
  coachMetrics: Metric[];
  template: CoachMetricTemplate;
  companyMetrics: any;
  allCompanyMetrics: Metric[];
  window: number;
  sortedSubmoduleMetrics: any;
  trainerName: string;
  admin: boolean;
};

export default function MetricModule({
  metricSnapshot,
  coachMetrics,
  template,
  companyMetrics,
  allCompanyMetrics,
  window,
  sortedSubmoduleMetrics,
  trainerName,
  admin,
}: Props) {
  const theme = useTheme();

  const offsetHours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];

  const timezoneOffset = new Date().getTimezoneOffset() / 60;

  for (let i = 0; i < timezoneOffset; i++) {
    const x = offsetHours.shift();
    offsetHours.push(x!);
  }

  const messagesData = hours.map((h) => {
    return {
      x: h,
      y: metricSnapshot.messages.count_sent_by_hour[offsetHours[h]],
    };
  });

  const workoutsData = hours.map((h) => {
    return {
      x: h,
      y: metricSnapshot.workouts.count_internal_by_hour[offsetHours[h]],
    };
  });

  let chartName = "Chart";
  let chartData: { x: number; y: number }[] = [];

  if (template.module_title === "Messaging") {
    chartName = "Messages Sent";
    chartData = messagesData;
  } else if (template.module_title === "Workouts") {
    chartName = "Workouts";
    chartData = workoutsData;
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h2" sx={{ mb: 1 }}>
        {template.module_title}
      </Typography>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          p: 2,
        }}
      >
        <Grid container spacing={2}>
          {template.submodules.map((submoduleTemplate, i) => (
            <Grid item xs={4} key={i}>
              <MetricSubmodule
                metricSnapshot={metricSnapshot}
                coachMetrics={coachMetrics}
                submodule={submoduleTemplate}
                companyMetrics={companyMetrics}
                allCompanyMetrics={allCompanyMetrics}
                window={window}
                sortedSubmoduleMetrics={sortedSubmoduleMetrics}
                trainerName={trainerName}
                admin={admin}
              />
            </Grid>
          ))}
        </Grid>
        {(template.module_title === "Messaging" ||
          template.module_title === "Workouts") && (
          <Box sx={{ width: "250px", height: "200px" }}>
            <BarChart
              colors={[theme.palette.primary.main]}
              dataset={chartData}
              xAxis={[
                {
                  scaleType: "band",
                  dataKey: "x",
                  valueFormatter: (value) => `${value}:00`,
                },
              ]}
              series={[
                {
                  type: "bar",
                  dataKey: "y",
                  label: chartName,
                },
              ]}
              width={250}
              height={300}
              skipAnimation
            />
          </Box>
        )}
      </Card>
    </Box>
  );
}
