import { Typography } from "@mui/material";
import { Link } from "@tiptap/extension-link";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import StarterKit from "@tiptap/starter-kit";
import debounce from "lodash-es/debounce";
import {
  LinkBubbleMenuHandler,
  MenuButtonBlockquote,
  MenuButtonBulletedList,
  MenuButtonHorizontalRule,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonTaskList,
  MenuButtonUndo,
  MenuControlsContainer,
  RichTextEditor,
} from "mui-tiptap";
import { useCallback, useState } from "react";

type Props = {
  label: string;
  defaultValue?: string;
  helperText?: string;
  onSave: (newValue: string, blured?: boolean) => void;
};

export function AutosaveRichTextField({
  label,
  helperText,
  defaultValue,
  onSave,
}: Props) {
  const [saved, setSaved] = useState(false);

  const debouncedSave = useCallback(
    debounce(async (newValue: string) => {
      console.log("Autosave: saving rich text field");

      await onSave(newValue);

      setSaved(true);
    }, 3000),
    [],
  );

  let displayedHelperText = helperText;

  if (saved) {
    if (displayedHelperText) {
      displayedHelperText += "\nSaved";
    } else {
      displayedHelperText = "Saved";
    }
  }

  const CustomLinkExtension = Link.extend({
    inclusive: false,
  });

  return (
    <>
      <Typography variant="h6">{label}</Typography>
      <RichTextEditor
        extensions={[
          StarterKit,
          TaskList,
          TaskItem.configure({
            nested: true,
          }),
          CustomLinkExtension.configure({
            // autolink is generally useful for changing text into links if they
            // appear to be URLs (like someone types in literally "example.com"),
            // though it comes with the caveat that if you then *remove* the link
            // from the text, and then add a space or newline directly after the
            // text, autolink will turn the text back into a link again. Not ideal,
            // but probably still overall worth having autolink enabled, and that's
            // how a lot of other tools behave as well.
            autolink: true,
            linkOnPaste: true,
            openOnClick: false,
          }),
          LinkBubbleMenuHandler,
        ]}
        content={
          defaultValue?.includes("<p>")
            ? defaultValue
            : `<p>${defaultValue?.split("\n").join("</p><p>")}</p>`
        }
        renderControls={() => (
          <MenuControlsContainer>
            <MenuButtonOrderedList />
            <MenuButtonBulletedList />
            <MenuButtonTaskList />
            <MenuButtonHorizontalRule />
            <MenuButtonBlockquote />
            <MenuButtonUndo />
            <MenuButtonRedo />
          </MenuControlsContainer>
        )}
        onUpdate={({ editor }) => {
          debouncedSave(editor.getHTML());
        }}
      />
      {displayedHelperText && (
        <Typography variant="overline">{displayedHelperText}</Typography>
      )}
    </>
  );
}
