import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { alpha, Box, Typography } from "@mui/material";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { isHabitDayInPast, isProgramHabit } from "src/lib/habits";
import {
  makeSelectHabitTasksForDay,
  selectHabitPlanById,
  selectPreferredWorkoutDays,
} from "src/slices/clientSlice";
import HabitTaskCell from "./HabitTaskCell";
import WorkoutTaskCell from "./WorkoutTaskCell";
import WorkoutTaskCellPast from "./WorkoutTaskCellPast";

type Props = {
  habitWeekId: string;
  weekPlanId: string;
  isSelected: boolean;
  dayIndex: number;
  isEditMode: boolean;
  forceTaskNames?: string[];
};

export default function HabitDayCell({
  habitWeekId,
  weekPlanId,
  isSelected,
  dayIndex,
  isEditMode,
  forceTaskNames,
}: Props) {
  const habitWeekPlanDate = useAppSelector(
    (state) => selectHabitPlanById(state, weekPlanId)?.date,
  );
  const selectHabitTasksForDay = useMemo(makeSelectHabitTasksForDay, []);
  const habitTasks = useAppSelector((state) =>
    selectHabitTasksForDay(state, {
      planId: weekPlanId,
      dayIndex: dayIndex,
      habitWeekId: habitWeekId,
    }),
  );
  const habitWeek = useAppSelector(
    (state) =>
      selectHabitPlanById(state, weekPlanId)?.habit_weeks.find(
        (w) => w.id === habitWeekId,
      )!,
  );

  const isProgramDay = isProgramHabit(habitWeek);

  const isInPast = isHabitDayInPast(habitWeekPlanDate!, dayIndex);

  return (
    <>
      {isSelected && !isProgramDay && habitTasks.length <= 0 && (
        <HabitTaskCell
          isEditMode={isEditMode}
          dayIndex={dayIndex}
          habitWeek={habitWeek}
          weekPlanId={weekPlanId}
          forceTaskNames={forceTaskNames}
        />
      )}
      {habitTasks.map((task, index) => {
        if (task.movement_type === "copilot_workout") {
          return (
            <WorkoutTaskCellPast
              key={task.id}
              habitTaskId={task.id}
              isLast={index === habitTasks.length - 1}
            />
          );
        }
        return (
          <HabitTaskCell
            key={task.id}
            habitTask={task}
            isEditMode={isEditMode}
            dayIndex={dayIndex}
            habitWeek={habitWeek}
            weekPlanId={weekPlanId}
            isLast={index === habitTasks.length - 1}
          />
        );
      })}
      {isProgramDay && !isInPast && (
        <HabitDayCellDroppableContent
          habitWeekId={habitWeekId}
          weekPlanId={weekPlanId}
          dayIndex={dayIndex}
        />
      )}
    </>
  );
}

type DroppableContentProps = {
  habitWeekId: string;
  weekPlanId: string;
  dayIndex: number;
  isOver?: boolean;
};

const HabitDayCellDroppableContent = memo(
  function HabitDayCellDroppableContent({
    habitWeekId,
    weekPlanId,
    dayIndex,
  }: DroppableContentProps) {
    const isDraggingPhase = useAppSelector(
      (state) => state.client.isDraggingPhase,
    );
    const weekPlan = useAppSelector((state) =>
      selectHabitPlanById(state, weekPlanId),
    );
    const habitWeek = weekPlan?.habit_weeks.find((w) => w.id === habitWeekId)!;
    const anchoredWorkouts = habitWeek.anchored_workout_days
      ? habitWeek.anchored_workout_days[dayIndex]
      : null;
    const preferredWorkoutDays = useAppSelector(selectPreferredWorkoutDays);
    const selectHabitTasksForDay = useMemo(makeSelectHabitTasksForDay, []);
    const habitTasks = useAppSelector((state) =>
      selectHabitTasksForDay(state, {
        planId: weekPlanId,
        dayIndex: dayIndex,
        habitWeekId: habitWeek.id,
      }),
    );

    const isProgramDay = isProgramHabit(habitWeek);

    const isInPast = isHabitDayInPast(weekPlan!.date, dayIndex);

    const newAnchoredWorkouts = anchoredWorkouts?.filter(
      (workoutId) => !habitTasks.some((task) => task.workout_id === workoutId),
    );

    const isEligiblePhaseDay =
      isProgramDay && !isInPast && preferredWorkoutDays[dayIndex];

    const isEmptyWorkoutDay =
      (!newAnchoredWorkouts || newAnchoredWorkouts?.length === 0) &&
      !isDraggingPhase;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {newAnchoredWorkouts &&
          newAnchoredWorkouts.length >= 1 &&
          newAnchoredWorkouts?.map((workoutId, workoutIdIndex) => (
            <WorkoutTaskCell
              workoutId={workoutId}
              habitWeekId={habitWeek.id}
              key={workoutIdIndex}
              index={workoutIdIndex}
              weekPlanId={weekPlanId}
              dayIndex={dayIndex}
            />
          ))}
        {(isEmptyWorkoutDay || (isEligiblePhaseDay && isDraggingPhase)) && (
          <WorkoutDroppable
            weekPlanId={weekPlanId}
            dayIndex={dayIndex}
            forPhase={isDraggingPhase}
          />
          // <Box
          //   sx={{
          //     p: 1,
          //     height: "100%",
          //   }}
          // >
          //   <Box
          //     sx={{
          //       borderStyle: "dashed",
          //       borderWidth: "2px",
          //       borderRadius: 1,
          //       borderColor: (theme) =>
          //         isOver ? theme.palette.primary.main : theme.palette.divider,
          //       width: "100%",
          //       display: "flex",
          //       justifyContent: "center",
          //       alignItems: "center",
          //       flexDirection: "column",
          //       transition: "border-color 0.2s ease",
          //     }}
          //   >
          //     <AddRoundedIcon
          //       sx={{
          //         color: (theme) =>
          //           isOver
          //             ? theme.palette.primary.main
          //             : theme.palette.divider,
          //         transition: "color 0.2s ease",
          //       }}
          //     />
          //     {isDraggingPhase && (
          //       <Typography
          //         sx={{
          //           fontSize: 10,
          //           color: (theme) =>
          //             isOver
          //               ? theme.palette.primary.main
          //               : theme.palette.text.secondary,
          //           textAlign: "center",
          //           transition: "color 0.2s ease",
          //         }}
          //       >
          //         Start phase here
          //       </Typography>
          //     )}
          //   </Box>
          // </Box>
        )}
      </Box>
    );
  },
);

type DroppableProps = {
  weekPlanId: string;
  dayIndex: number;
  forPhase?: boolean;
};

function WorkoutDroppable({ weekPlanId, dayIndex, forPhase }: DroppableProps) {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    // disabled: isInPast || (isDraggingPhase && !isEligiblePhaseDay),
    return dropTargetForElements({
      element: el,
      getData: () => ({
        type: "day",
        weekPlanId: weekPlanId,
        dayIndex: dayIndex,
        index: 0,
      }),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      canDrop({ source }) {
        return (
          source.data.type === "workout" ||
          source.data.type === "workout_task" ||
          source.data.type === "workout_task_past" ||
          source.data.type === "phase"
        );
      },
    });
  }, [weekPlanId, dayIndex]);

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        height: "100%",
        backgroundColor: (theme) =>
          isDraggedOver ? alpha(theme.palette.primary.main, 0.2) : undefined,
        flexDirection: "column",
        alignItems: "center",
        transitionProperty: "background-color",
        transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
        transitionDuration: "350ms",
        py: 1,
      }}
    >
      <AddRoundedIcon
        sx={{
          color: (theme) =>
            isDraggedOver
              ? theme.palette.primary.main
              : forPhase
                ? theme.palette.text.secondary
                : theme.palette.divider,
          transitionProperty: "color",
          transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
          transitionDuration: "350ms",
        }}
      />
      {forPhase && (
        <Typography
          sx={{
            fontSize: 10,
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            textAlign: "center",
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        >
          Start phase here
        </Typography>
      )}
    </Box>
  );
}
