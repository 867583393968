import {
  Box,
  CardActionArea,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import type { Client } from "@trainwell/types";
import {
  differenceInCalendarDays,
  format,
  formatDistanceToNow,
  isFuture,
  startOfToday,
} from "date-fns";
import React, { useState } from "react";
import ClientName from "src/components/misc/ClientName";
import ClientProfilePicture from "src/components/misc/ClientProfilePicture";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getClientStatusColor, getClientSummary } from "src/lib/client";
import { getDateWithTimezoneOffset } from "src/lib/date";
import { toSentenceCase } from "src/lib/misc";
import { toggleSelectedChat } from "src/slices/chatSlice";
import { selectClientById, toggleClientTag } from "src/slices/clientsSlice";
import { DialogTitleWithClose } from "../misc/DialogTitleWithClose";
import { TagList } from "../misc/TagList";

type Props = {
  userId: string;
  index: number;
  tenureType: "with_coach" | "with_trainwell";
};

const ClientCell = React.memo<Props>(function ClientCell({
  index,
  userId,
  tenureType,
}: Props) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const client = useAppSelector((state) =>
    selectClientById(state, userId),
  ) as Client;
  const possibleTags = useAppSelector((state) => state.clients.possibleTags);

  const clientInfo = useAppSelector((state) => state.clients.clientInfo);
  const [open, setOpen] = useState(false);
  const isSelectingChats = useAppSelector(
    (state) => state.chat.isSelectingChats,
  );
  const isSelected = useAppSelector((state) =>
    state.chat.selectedChatIds.includes(userId),
  );

  if (client === undefined) {
    return null;
  }

  const today = startOfToday();

  let daysSinceWorkout: number | undefined = undefined;

  if ("last_workout_date" in client && client.last_workout_date) {
    const lastWorkoutDate = getDateWithTimezoneOffset(
      new Date(client.last_workout_date),
      client.default_timezone_offset ?? 0,
    );
    daysSinceWorkout = differenceInCalendarDays(today, lastWorkoutDate);
  }

  // Start date

  let startDate = new Date();

  if (client.account.membership.date_membership_started) {
    startDate = new Date(client.account.membership.date_membership_started);
  } else if (client.account.membership.date_account_created) {
    startDate = new Date(client.account.membership.date_account_created);
  }

  // Days since message

  let lastMessageDate: Date | undefined = undefined;

  if (
    "last_trainer_message_date" in client &&
    client.last_trainer_message_date
  ) {
    lastMessageDate = new Date(client.last_trainer_message_date);
  }

  let daysSinceMessage: undefined | number = undefined;
  if (lastMessageDate !== undefined) {
    daysSinceMessage = differenceInCalendarDays(today, lastMessageDate);
  }

  const handleClose = () => {
    setOpen(false);
  };

  function toggleTag(userId: string, tag: string) {
    dispatch(
      toggleClientTag({
        tag: tag,
        userId: userId,
      }),
    );
  }

  let color = theme.palette.errorSurface.main;

  if (client.account.dashboard.is_hidden) {
    color = theme.palette.backgroundSecondary.main;
  } else {
    if (!client.account.dashboard.date_onboarded) {
      color = theme.palette.blueSurface.main;
    } else if (
      client.account.plan.date_trial_end &&
      isFuture(new Date(client.account.plan.date_trial_end))
    ) {
      color = theme.palette.purpleSurface.main;
    } else if (clientInfo[userId]?.weeks >= 2) {
      color = theme.palette.successSurface.main;
    } else if (clientInfo[userId]?.weeks >= 1) {
      color = theme.palette.background.paper;
    }
  }

  let smallColor = color;

  if (color === theme.palette.purpleSurface.main) {
    if (clientInfo[userId]?.weeks >= 2) {
      smallColor = theme.palette.successSurface.main;
    } else if (clientInfo[userId]?.weeks >= 1) {
      smallColor = theme.palette.background.paper;
    } else if (clientInfo[userId]?.weeks === 0) {
      smallColor = theme.palette.background.paper;
    }
  }

  const clientSummary = getClientSummary(client.account);

  return (
    <>
      <CardActionArea
        onClick={() => {
          sessionStorage.setItem("current_client_index", JSON.stringify(index));
        }}
        href={client.account.dashboard.is_hidden ? "" : `/clients/${userId}`}
        sx={{
          py: 0.5,
          backgroundColor: color,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={3}
            sx={{
              minWidth: "200px",
              display: "flex",
              alignItems: "center",
              pl: 1,
            }}
          >
            {isSelectingChats && (
              <Checkbox
                checked={isSelected}
                onChange={() => {
                  dispatch(toggleSelectedChat(userId));
                }}
                inputProps={{ "aria-label": "controlled" }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              />
            )}
            <ClientProfilePicture
              userId={client.user_id}
              dimension={36}
              onClick={(event) => {
                event.stopPropagation();
                setOpen(true);
              }}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
            />
            <Box sx={{ ml: 2 }}>
              <ClientName userId={client.user_id} sx={{ fontWeight: "bold" }} />
              {clientSummary.isSpecial && (
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => getClientStatusColor(client, theme),
                    lineHeight: 1,
                  }}
                >
                  {clientSummary.text}
                </Typography>
              )}
            </Box>
          </Grid>
          {client.account.dashboard.is_hidden ? null : (
            <>
              <Grid item xs>
                <Typography>
                  {clientInfo[userId]?.daysUntilNextWorkout === null
                    ? "--"
                    : clientInfo[userId]?.daysUntilNextWorkout === 0
                      ? "Today"
                      : clientInfo[userId]?.daysUntilNextWorkout === 1
                        ? "Tomorrow"
                        : `${clientInfo[userId]?.daysUntilNextWorkout} days`}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                // sx={{
                //   color: (theme) =>
                //     (client.missed_workout_day_streak ?? 0) > 0 &&
                //     (client.missed_workout_day_streak ?? 0) < 4
                //       ? theme.palette.error.main
                //       : undefined,
                // }}
                >
                  {client.missed_workout_day_streak === undefined
                    ? "--"
                    : client.missed_workout_day_streak === 0
                      ? "0 days"
                      : (client.missed_workout_day_streak ?? 0) +
                        (client.missed_workout_day_streak === 1
                          ? " day"
                          : " days")}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography sx={{}}>
                  {client.metrics?.workout_completion_week === undefined ||
                  client.metrics?.workout_completion_week === null
                    ? "--"
                    : `${Math.round(
                        client.metrics?.workout_completion_week! * 100,
                      )}%`}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                // sx={{
                //   color: (theme) =>
                //     daysSinceMessage === undefined
                //       ? undefined
                //       : (!client.account.dashboard.is_inactive &&
                //             daysSinceMessage! >= 3) ||
                //           daysSinceMessage! >= 7
                //         ? theme.palette.error.main
                //         : undefined,
                // }}
                >
                  {daysSinceMessage === undefined
                    ? "--"
                    : daysSinceMessage === 0
                      ? "Today"
                      : daysSinceMessage +
                        (daysSinceMessage === 1 ? " day" : " days")}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography>
                  {daysSinceWorkout === undefined
                    ? "--"
                    : daysSinceWorkout === 0
                      ? "Today"
                      : daysSinceWorkout === 1
                        ? "Yesterday"
                        : `${daysSinceWorkout} days`}
                </Typography>
              </Grid>
              <Grid item xs>
                <Tooltip
                  disableInteractive
                  title={
                    <>
                      {client.date_latest_coach_switch ? (
                        <>
                          {`With you: ${formatDistanceToNow(
                            new Date(client.date_latest_coach_switch),
                          )} (${format(
                            new Date(client.date_latest_coach_switch),
                            "MMM do, yyyy",
                          )})`}
                          <br />
                          <br />
                        </>
                      ) : undefined}
                      With trainwell: {formatDistanceToNow(new Date(startDate))}{" "}
                      ({format(new Date(startDate), "MMM do, yyyy")})
                    </>
                  }
                >
                  <Typography>
                    {toSentenceCase(
                      formatDistanceToNow(
                        tenureType === "with_coach"
                          ? new Date(
                              client.date_latest_coach_switch ?? startDate,
                            )
                          : startDate,
                      ),
                    )}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs>
                <Box
                  sx={{
                    backgroundColor: smallColor,
                    border: smallColor !== color ? 1 : undefined,
                    borderColor: "divider",
                    borderRadius: 1,
                    px: 1,
                    mr: 1,
                    display: smallColor !== color ? "flex" : undefined,
                    alignItems: smallColor !== color ? "center" : undefined,
                    height: smallColor !== color ? "100%" : undefined,
                  }}
                >
                  <Typography
                    sx={{
                      color: (theme) =>
                        (clientInfo[userId]?.weeks ?? 0) <= 0 &&
                        client.account.dashboard.date_onboarded
                          ? theme.palette.error.main
                          : undefined,
                    }}
                  >
                    {(clientInfo[userId]?.weeks ?? 0) +
                      ((clientInfo[userId]?.weeks ?? 0) === 1
                        ? " week"
                        : " weeks")}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        {client.tags && client.tags.length > 0 && (
          <TagList
            value={(client.tags ?? []).map((t) => ({ id: t, label: t }))}
            options={possibleTags.map((t) => ({ id: t, label: t }))}
            onAdd={(tag) => {
              toggleTag(client.user_id, tag);
            }}
            onRemove={(tag) => {
              toggleTag(client.user_id, tag);
            }}
            sx={{ ml: 11 }}
          />
        )}
      </CardActionArea>
      <Dialog onClose={handleClose} aria-labelledby="client-name" open={open}>
        <DialogTitleWithClose onClose={handleClose}>
          {client.full_name}
        </DialogTitleWithClose>
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <img
              width={500}
              height={500}
              src={
                client.headshot_url
                  ? client.headshot_url
                  : "/assets/profile.png"
              }
              alt="client headshot"
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default ClientCell;
