import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import {
  Box,
  CardActionArea,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import WorkoutPreviewPopover from "src/components/ClientPage/WorkoutPreviewPopover";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectTemplateById } from "src/slices/templatesSlice";
import { openWorkoutTemplate } from "src/slices/workoutBuilderSlice";

type Props = {
  draggableId: string;
  workoutTemplateId: string;
  isLast?: boolean;
  onDelete: () => void;
  onCopy: () => void;
  disabled?: boolean;
  dayIndex: number;
};

export function WorkoutTemplateDraggable({
  draggableId,
  workoutTemplateId,
  isLast,
  onDelete,
  onCopy,
  disabled,
  dayIndex,
}: Props) {
  const { setNodeRef, listeners, isDragging, transform, transition } =
    useSortable({
      id: draggableId,
      disabled: disabled,
      data: {
        type: "phase_template_workout_template",
        workoutTemplateId: workoutTemplateId,
        dayIndex: dayIndex,
      },
    });
  const workoutTemplate = useAppSelector((state) =>
    selectTemplateById(state, workoutTemplateId),
  );

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  if (!workoutTemplate) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>Error</Typography>
        <IconButton
          size="small"
          color="error"
          onClick={(event) => {
            event.stopPropagation();

            onDelete();
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          <DeleteRoundedIcon fontSize="inherit" />
        </IconButton>
      </Box>
    );
  }

  return (
    <div ref={setNodeRef} style={style} {...listeners}>
      <WorkoutTemplateCell
        workoutTemplateId={workoutTemplateId}
        isLast={isLast}
        onDelete={onDelete}
        onCopy={onCopy}
        isDragging={isDragging}
        disabled={disabled}
      />
    </div>
  );
}

type CellProps = {
  workoutTemplateId: string;
  isLast?: boolean;
  onDelete: () => void;
  onCopy: () => void;
  isDragging?: boolean;
  disabled?: boolean;
};

export function WorkoutTemplateCell({
  workoutTemplateId,
  isLast,
  onDelete,
  onCopy,
  isDragging,
  disabled,
}: CellProps) {
  const dispatch = useAppDispatch();
  const workoutTemplate = useAppSelector((state) =>
    selectTemplateById(state, workoutTemplateId),
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  if (!workoutTemplate) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>Error</Typography>
        <IconButton
          size="small"
          color="error"
          onClick={(event) => {
            event.stopPropagation();

            onDelete();
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          <DeleteRoundedIcon fontSize="inherit" />
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <CardActionArea
        disabled={isDragging}
        onContextMenu={(event) => {
          event.preventDefault();

          setAnchorEl(event.currentTarget);
        }}
        onClick={
          disabled
            ? undefined
            : () => {
                dispatch(openWorkoutTemplate(workoutTemplateId));
              }
        }
        sx={{
          borderBottom: isLast ? 0 : 1,
          opacity: isDragging ? 0.4 : undefined,
          borderColor: "divider",
          // border: isDragging ? "2px" : undefined,
          // borderWidth: isDragging ? "2px" : undefined,
          // borderStyle: isDragging ? "solid" : undefined,
          backgroundColor: (theme) => theme.palette.background.paper,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", px: 1, py: 0.5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography>{workoutTemplate.name}</Typography>
            {!disabled && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    setMoreAnchorEl(event.currentTarget);
                  }}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                >
                  <MoreVertRoundedIcon fontSize="inherit" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </CardActionArea>
      <WorkoutPreviewPopover
        templateId={workoutTemplateId}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      />
      <Menu
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(moreAnchorEl)}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            onCopy();
            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ContentCopyRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Make a copy" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete();
            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            sx={{ color: (theme) => theme.palette.error.main }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
