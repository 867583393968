import { Box, ThemeProvider, useMediaQuery } from "@mui/material";
import { Suspense, useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Outlet } from "react-router-dom";
import WorkoutBuilderDialog from "src/components/WorkoutBuilderPage/WorkoutBuilderDialog";
import ActionItemHelper from "src/components/app/ActionItemHelper";
import AppHooks from "src/components/app/AppHooks";
import AuthHelper from "src/components/app/AuthHelper";
import PwaHelper from "src/components/app/PwaHelper";
import { ChatDraggable } from "src/components/chat/ChatDraggable";
import ChatList from "src/components/chat/ChatList";
import { ChatListHeader } from "src/components/chat/ChatListHeader";
import CheckInPanel from "src/components/chat/CheckInPanel";
import ClientChat from "src/components/chat/ClientChat";
import { ClientChatHeader } from "src/components/chat/ClientChatHeader";
import MassMessageView from "src/components/chat/MassMessageView";
import { ThreadsList } from "src/components/chat/threads/ThreadsList";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { AppProviders } from "src/providers/AppProviders";
import { selectSelectedChat, toggleHideClientChat } from "src/slices/chatSlice";
import { closeWorkoutBuilder } from "src/slices/workoutBuilderSlice";
import getTheme from "src/theme/theme";
import type { ThemeMode } from "src/theme/themeModes";

export function AppRoot() {
  const dispatch = useAppDispatch();
  const isBigChatMode = useAppSelector(
    (state) =>
      state.chat.chatMode === "big_left" || state.chat.chatMode === "big_right",
  );
  const isSelectingChats = useAppSelector(
    (state) => state.chat.isSelectingChats,
  );
  const isChatFull = useAppSelector((state) => state.chat.chatMode === "full");
  const workoutTemplateId = useAppSelector(
    (state) => state.workoutBuilder.workoutTemplateId,
  );
  const hideClientChat = useAppSelector((state) => state.chat.hideClientChat);
  const hasSelectedChat = useAppSelector((state) =>
    Boolean(selectSelectedChat(state)),
  );
  const chatThemeMode = useAppSelector(
    (state) => state.trainer.trainer?.settings?.theme_chat ?? "light",
  ) as ThemeMode;
  const chatTheme = useMemo(() => getTheme(chatThemeMode), [chatThemeMode]);
  const showFullChats = useMediaQuery("(min-width:1800px)") || !hasSelectedChat;
  const chatView = useAppSelector((state) => state.chat.view);
  const trainerLoaded = useAppSelector((state) =>
    Boolean(state.trainer.trainer?.trainer_id),
  );

  useHotkeys("c", () => {
    dispatch(toggleHideClientChat());
  });

  const width =
    chatView === "mass_message" || chatView === "threads" || isSelectingChats
      ? "400px"
      : !isSelectingChats
        ? "320px"
        : "240px";

  return (
    <Suspense fallback={<LoadingComponent message="Loading component" />}>
      <AppProviders>
        <Box sx={{ display: "flex" }}>
          {!isChatFull && isBigChatMode && trainerLoaded && (
            <ThemeProvider theme={chatTheme}>
              <Box
                sx={{
                  minWidth: showFullChats ? width : "48px",
                  width: showFullChats ? width : "48px",
                  borderRight: 1,
                  borderColor: chatTheme.palette.divider,
                  mt: "38px",
                  overflowY: "auto",
                  height: "calc(100vh - 38px)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ChatListHeader condensed={!showFullChats} />
                <Box sx={{ flex: 1 }}>
                  {chatView === "threads" ? (
                    <ThreadsList />
                  ) : chatView === "mass_message" ? (
                    <MassMessageView />
                  ) : chatView === "check_in" ? (
                    <CheckInPanel condensed={!showFullChats} />
                  ) : (
                    <ChatList condensed={!showFullChats} />
                  )}
                </Box>
              </Box>
              {(chatView === "default" || chatView === "check_in") &&
                !hideClientChat && (
                  <>
                    {hasSelectedChat && (
                      <Box
                        sx={{
                          width: "320px",
                          minWidth: "320px",
                          borderRight: 1,
                          borderColor: "divider",
                          mt: "38px",
                          overflowY: "auto",
                          height: "calc(100vh - 38px)",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <ClientChatHeader />
                        <ClientChat condensed={!showFullChats} />
                      </Box>
                    )}
                  </>
                )}
            </ThemeProvider>
          )}
          <Box sx={{ flex: 1, maxWidth: "100%" }}>
            <Outlet />
          </Box>
        </Box>
        {!isChatFull && !isBigChatMode && <ChatDraggable />}
        {/* <Grid container>
          {!isChatFull && isBigChatMode && <ChatBar />}
            <Grid item xs={isBigChatMode ? 9 : 12}>
              <Outlet />
            </Grid>
            {!isChatFull && !isBigChatMode && <ChatDraggable />}
          </Grid> */}
        {workoutTemplateId && (
          <WorkoutBuilderDialog
            open={Boolean(workoutTemplateId)}
            onClose={() => {
              dispatch(closeWorkoutBuilder());
            }}
            templateId={workoutTemplateId ?? undefined}
          />
        )}
        <AuthHelper />
        <PwaHelper />
        <AppHooks />
        <ActionItemHelper />
      </AppProviders>
    </Suspense>
  );
}
