import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import EmojiEmotionsRoundedIcon from "@mui/icons-material/EmojiEmotionsRounded";
import type { SxProps, Theme } from "@mui/material";
import {
  Box,
  Button,
  Chip,
  DialogActions,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import Document from "@tiptap/extension-document";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { RichTextEditor, type RichTextEditorRef } from "mui-tiptap";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "src/hooks/stateHooks";
import { stageMassMessages } from "src/slices/chatSlice";
import { EmojiPicker } from "../misc/EmojiPicker";
import {
  magicKetSuggestionsFlat,
  magicKeySuggestionOptions,
  magicKeySuggestions,
} from "./magicKeySuggestionOptions";

type Props = {
  onBack?: () => void;
  onContinue?: () => void;
  onUpdate?: (html: string) => void;
  userIds?: string[];
  sx?: SxProps<Theme>;
};

export function MagicKeyTextField({
  onBack,
  onContinue,
  onUpdate,
  userIds,
  sx = [],
}: Props) {
  const dispatch = useAppDispatch();
  const [emojiPickerAnchorEl, setEmojiPickerAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const isEmojiPickerOpen = Boolean(emojiPickerAnchorEl);
  const [magicKeyMenuData, setDynamicKeyMenuData] = useState<null | {
    id: string;
    anchorEl: HTMLElement;
  }>(null);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const magicKeyMenuOpen = Boolean(magicKeyMenuData);
  const rteRef = useRef<RichTextEditorRef>(null);

  const [messagePreview, setMessagePreview] = useState<string | null>(null);

  function addEmoji(emoji: string) {
    rteRef.current?.editor?.commands.insertContent(emoji);

    setEmojiPickerAnchorEl(null);

    setTimeout(() => {
      rteRef.current?.editor?.chain().focus().run();
    }, 100);
  }

  useEffect(() => {
    // @ts-expect-error
    setHeight(ref.current?.clientHeight);
  }, [messagePreview]);

  return (
    <Box sx={sx}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <AutoAwesomeRoundedIcon
          sx={{
            fontSize: "inherit",
          }}
        />
        <Typography variant="overline" sx={{ ml: 1 }}>
          Magic keys
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          mb: 2,
        }}
      >
        {magicKeySuggestions.map((magicKey) => (
          <Tooltip
            key={magicKey.id}
            title={
              magicKey.tooltip ??
              (magicKey.example ? `Example: ${magicKey.example}` : undefined)
            }
            disableInteractive
          >
            <Chip
              label={magicKey.label}
              onClick={(event) => {
                if (magicKey.subMagicKeys) {
                  setDynamicKeyMenuData({
                    anchorEl: event.currentTarget,
                    id: magicKey.id,
                  });
                } else {
                  rteRef.current?.editor?.commands.insertContent({
                    type: "mention",
                    attrs: {
                      id: magicKey.id,
                      label: magicKey.label,
                    },
                  });
                }
              }}
              size="small"
            />
          </Tooltip>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          "&& .MuiTiptap-FieldContainer-root": {
            flex: 1,
          },
          "&& .mention": {
            fontWeight: "bold",
            borderRadius: "8px",
            padding: "2px 4px",
          },
        }}
      >
        <IconButton
          onClick={(event) => {
            setEmojiPickerAnchorEl(event.currentTarget);
          }}
          size="small"
          sx={{ mr: 1, mt: 1.25 }}
        >
          <EmojiEmotionsRoundedIcon fontSize="small" />
        </IconButton>
        <Box sx={{ flex: 1 }}>
          <RichTextEditor
            ref={rteRef}
            onUpdate={({ editor }) => {
              const preview = editor.getText({
                blockSeparator: "\n",
                textSerializers: {
                  mention: ({ node }) => {
                    return (
                      magicKetSuggestionsFlat.find(
                        (leaf) => leaf.id === node.attrs.id,
                      )?.example ?? "{ ERROR }"
                    );
                  },
                },
              });

              setMessagePreview(preview);

              onUpdate?.(editor.getHTML());
            }}
            extensions={[
              Document,
              Paragraph,
              Text,
              Mention.configure({
                deleteTriggerWithBackspace: true,
                suggestion: magicKeySuggestionOptions,
                HTMLAttributes: {
                  class: "mention",
                },
              }),
            ]}
          />
          <Typography variant="overline">
            Type @ to search for a magic key
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="body2"
        sx={{ color: (theme) => theme.palette.text.secondary, mb: 0.25, mt: 2 }}
      >
        Live preview
      </Typography>
      <Typography
        ref={ref}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.primary.contrastText,
          borderRadius: `${height <= 35 ? "50px" : "10px"} ${
            height <= 35 ? "50px" : "10px"
          } ${"0px"} ${height <= 35 ? "50px" : "10px"}`,
          px: 1,
          py: 0.5,
          whiteSpace: "pre-line",
          wordWrap: "break-word",
          maxWidth: "300px",
        }}
      >
        {messagePreview || "Message"}
      </Typography>
      {onBack && onContinue && (
        <DialogActions sx={{ mt: 4 }}>
          <Button
            variant="text"
            onClick={() => {
              onBack();
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (!rteRef.current?.editor) {
                return;
              }

              dispatch(
                stageMassMessages({
                  messageHtml: rteRef.current.editor.getHTML(),
                  userIds,
                }),
              );
              onContinue();
            }}
          >
            Continue
          </Button>
        </DialogActions>
      )}
      <Menu
        anchorEl={magicKeyMenuData?.anchorEl}
        open={magicKeyMenuOpen}
        onClose={() => {
          setDynamicKeyMenuData(null);
        }}
        MenuListProps={{ dense: true }}
      >
        {magicKeySuggestions
          .find((magicKey) => magicKey.id === magicKeyMenuData?.id)
          ?.subMagicKeys?.map((subMagicKey) => (
            <Tooltip
              title={
                subMagicKey.example
                  ? `Example: ${subMagicKey.example}`
                  : undefined
              }
              disableInteractive
              key={subMagicKey.id}
            >
              <MenuItem
                onClick={() => {
                  rteRef.current?.editor?.commands.insertContent({
                    type: "mention",
                    attrs: {
                      id: subMagicKey.id,
                      label: subMagicKey.label,
                    },
                  });

                  setDynamicKeyMenuData(null);
                }}
              >
                <Typography>{subMagicKey.label}</Typography>
              </MenuItem>
            </Tooltip>
          ))}
      </Menu>
      <Popover
        open={isEmojiPickerOpen}
        anchorEl={emojiPickerAnchorEl}
        onClose={() => {
          setEmojiPickerAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 1260 }}
      >
        <EmojiPicker onSelect={addEmoji} />
      </Popover>
    </Box>
  );
}
