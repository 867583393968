import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import { useMilestones } from "@trainwell/features";
import { useState } from "react";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppSelector } from "src/hooks/stateHooks";
import { MilestoneCellExercise } from "./MilestoneCellExercise";
import { MilestoneCellProgressMetric } from "./MilestoneCellProgressMetric";
import { MilestoneCellText } from "./MilestoneCellText";
import MilestonePopover from "./MilestonePopover";

type Props = {
  sx?: SxProps<Theme>;
};

export function Milestones({ sx = [] }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const milestonesQuery = useMilestones({ userId: client?.user_id ?? "" });
  const milestones = milestonesQuery.data ?? [];
  const [createMilestoneAnchorEl, setCreateMilestoneAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [showCompleted, setShowCompleted] = useState(false);

  if (!client) {
    return <LoadingPage message={"Loading your client 😄"} />;
  }

  const incompleteMilestones = milestones.filter(
    (milestone) => !milestone.date_achieved,
  );
  const completeMilestones = milestones.filter(
    (milestone) => milestone.date_achieved,
  );

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <EmojiEventsRoundedIcon fontSize="inherit" sx={{ mt: 0.5 }} />
          <Typography sx={{ ml: 1, fontWeight: "bold" }}>
            {client.goal?.name_custom ?? "No goal"}
          </Typography>
        </Box>
      </Box>
      <Stack spacing={0.5}>
        {incompleteMilestones.map((milestone) => {
          if (milestone.type === "text") {
            return (
              <MilestoneCellText key={milestone._id} milestone={milestone} />
            );
          } else if (milestone.type === "progress_metric") {
            return (
              <MilestoneCellProgressMetric
                key={milestone._id}
                milestone={milestone}
              />
            );
          } else if (milestone.type === "exercise") {
            return (
              <MilestoneCellExercise
                key={milestone._id}
                milestone={milestone}
              />
            );
          }

          return null;
        })}
      </Stack>
      <Stack direction={"row"} spacing={1} sx={{ mt: 1 }}>
        <IconButton
          size="small"
          sx={{
            ml: 2,
          }}
          onClick={(event) => {
            setCreateMilestoneAnchorEl(event.currentTarget);
          }}
        >
          <AddRoundedIcon fontSize="inherit" />
        </IconButton>
        {completeMilestones.length > 0 && (
          <Tooltip
            disableInteractive
            title={showCompleted ? "Hide completed" : "Show completed"}
          >
            <IconButton
              size="small"
              onClick={() => setShowCompleted(!showCompleted)}
            >
              {showCompleted ? (
                <ExpandLessRoundedIcon fontSize="inherit" />
              ) : (
                <ExpandMoreRoundedIcon fontSize="inherit" />
              )}
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      {showCompleted && (
        <Stack spacing={0.5} sx={{ mt: 1 }}>
          {completeMilestones.map((milestone) => {
            if (milestone.type === "text") {
              return (
                <MilestoneCellText key={milestone._id} milestone={milestone} />
              );
            } else if (milestone.type === "progress_metric") {
              return (
                <MilestoneCellProgressMetric
                  key={milestone._id}
                  milestone={milestone}
                />
              );
            } else if (milestone.type === "exercise") {
              return (
                <MilestoneCellExercise
                  key={milestone._id}
                  milestone={milestone}
                />
              );
            }

            return null;
          })}
        </Stack>
      )}
      <MilestonePopover
        userId={client.user_id}
        anchorEl={createMilestoneAnchorEl}
        onClose={() => {
          setCreateMilestoneAnchorEl(null);
        }}
      />
    </Box>
  );
}
