import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  Box,
  CardActionArea,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";
import WorkoutPreviewPopover from "src/components/ClientPage/WorkoutPreviewPopover";
import { DragPreview } from "src/components/WorkoutBuilderPage/DragPreview";
import { DropIndicator } from "src/components/WorkoutBuilderPage/DropIndicator";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectWorkoutById, updateWorkout } from "src/slices/phasesSlice";

type Props = {
  workoutId: string;
  isLast?: boolean;
  isFirst?: boolean;
  dayIndex: number;
  index: number;
  onDelete: () => void;
  onCopy: (type: "linked" | "not_linked") => void;
};

export default function PhaseWorkoutCellDraggable({
  workoutId,
  isLast,
  isFirst,
  index,
  dayIndex,
  onDelete,
  onCopy,
}: Props) {
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, workoutId ?? ""),
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "phase_workout",
      workoutId: workoutId,
      dayIndex: dayIndex,
      index: index,
    };

    return combine(
      draggable({
        element: element,
        getInitialData: () => data,
        onDragStart: () => setDragging(true),
        onDrop: () => setDragging(false),
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setPreviewContainer(container);
            },
          });
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return (
            source.data.type === "workout" ||
            source.data.type === "workout_task" ||
            source.data.type === "workout_task_past" ||
            source.data.type === "phase_workout"
          );
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self, source }) {
          const isSource = source.element === element;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== "number") {
            setClosestEdge(closestEdge);

            return;
          }

          if (source.data.dayIndex !== dayIndex) {
            setClosestEdge(closestEdge);

            return;
          }

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      }),
    );
  }, [index, dayIndex, workoutId]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          ref={ref}
          style={{
            opacity: dragging ? 0.5 : 1,
          }}
        >
          <PhaseWorkoutCell
            workoutId={workoutId}
            isLast={isLast}
            isFirst={isFirst}
            onDelete={onDelete}
            onCopy={onCopy}
          />
        </div>
        {closestEdge && <DropIndicator edge={closestEdge} gap="0px" />}
      </div>
      {previewContainer
        ? createPortal(
            <DragPreview text={workout?.name ?? "Workout"} />,
            previewContainer,
          )
        : null}
    </>
  );
}

type CellProps = {
  workoutId: string;
  isLast?: boolean;
  isFirst?: boolean;
  onDelete: () => void;
  onCopy: (type: "linked" | "not_linked") => void;
};

const PhaseWorkoutCell = memo(function PhaseWorkoutCell({
  workoutId,
  isLast,
  isFirst,
  onDelete,
  onCopy,
}: CellProps) {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, workoutId),
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLElement | null>(null);
  const isOnlyCopyInPhase = useAppSelector(
    (state) =>
      state.phases.phaseEditing?.days_draggable.filter((d) =>
        d.workouts.map((w) => w.workout_id).includes(workoutId),
      ).length === 1,
  );

  if (!workout) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>Error</Typography>
        <IconButton
          size="small"
          color="error"
          onClick={(event) => {
            event.stopPropagation();

            onDelete();
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          <RemoveCircleRoundedIcon fontSize="inherit" />
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <CardActionArea
        draggable={false}
        onContextMenu={(event) => {
          event.preventDefault();

          setAnchorEl(event.currentTarget);
        }}
        href={`/clients/${userId}/workouts/${workout.workout_id}`}
        sx={{
          borderTopLeftRadius: isFirst ? 12 : 0,
          borderTopRightRadius: isFirst ? 12 : 0,
          borderBottomLeftRadius: isLast ? 12 : 0,
          borderBottomRightRadius: isLast ? 12 : 0,
          overflow: "hidden",
          borderBottom: isLast ? 0 : 1,
          borderColor: "divider",
          backgroundColor: (theme) => theme.palette.background.paper,
          transition: "background-color 0.2s ease",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", px: 1, py: 0.5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography>{workout.name}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  setMoreAnchorEl(event.currentTarget);
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                <MoreVertRoundedIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
      <WorkoutPreviewPopover
        workoutId={workoutId}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      />
      <Menu
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(moreAnchorEl)}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            if (workout.is_extra) {
              dispatch(
                updateWorkout({
                  workout_id: workoutId,
                  is_extra: false,
                }),
              );
            } else {
              dispatch(
                updateWorkout({
                  workout_id: workoutId,
                  is_extra: true,
                }),
              );
            }

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            {workout.is_extra ? (
              <StarRoundedIcon fontSize="small" />
            ) : (
              <StarBorderRoundedIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText
            primary={workout.is_extra ? "Remove from extras" : "Add to extras"}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            onCopy("not_linked");
            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ContentCopyRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Make a copy" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            onCopy("linked");
            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <LinkRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Make a linked copy" />
        </MenuItem>
        <Box>
          <Divider sx={{ my: 1 }} />
        </Box>
        <MenuItem
          onClick={() => {
            onDelete();
            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            {isOnlyCopyInPhase ? (
              <DeleteRoundedIcon fontSize="small" color="error" />
            ) : (
              <RemoveCircleRoundedIcon fontSize="small" color="error" />
            )}
          </ListItemIcon>
          <ListItemText
            primary={isOnlyCopyInPhase ? "Delete" : "Remove"}
            sx={{ color: (theme) => theme.palette.error.main }}
          />
        </MenuItem>
      </Menu>
    </>
  );
});
