import AddRoundedIcon from "@mui/icons-material/AddRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import { Box, Button, Chip, Typography } from "@mui/material";
import type { HabitTemplate } from "@trainwell/types";
import { useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import SearchField from "src/components/misc/SearchField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  fetchHabitTemplates,
  selectHabitLibrary,
} from "src/slices/clientSlice";
import HabitCell from "./HabitCell";
import HabitEditDialog from "./HabitEditDialog";

const habitTypeFilters = {
  nutrition: "Nutrition",
  movement: "Movement",
  mindfulness: "Mindfulness",
  recovery: "Recovery",
};

type HabitTypeFilterType = keyof typeof habitTypeFilters;

const possibleHabitTypeFilters = Object.keys(
  habitTypeFilters,
).sort() as HabitTypeFilterType[];

export default function HabitPanel() {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const habitLibrary = useAppSelector(selectHabitLibrary);
  const [displayedHabits, setDisplayedHabits] = useState<HabitTemplate[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const habitTemplateStatus = useAppSelector(
    (state) => state.client.habitTemplateStatus,
  );

  useEffect(() => {
    if (habitTemplateStatus === "idle") {
      dispatch(fetchHabitTemplates());
    }
  }, [habitTemplateStatus, dispatch]);

  const libraryFilters = {
    trainwell: "trainwell",
    trainer: "Trainer",
    client: "Client",
  };

  type LibraryFilterType = keyof typeof libraryFilters;

  const possibleLibraryFilters = Object.keys(
    libraryFilters,
  ).sort() as LibraryFilterType[];

  const [selectedLibraryFilters, setSelectedLibraryFilters] = useState<
    LibraryFilterType[]
  >([]);
  const [selectedHabitTypeFilters, setSelectedHabitTypeFilters] = useState<
    HabitTypeFilterType[]
  >([]);

  useEffect(() => {
    if (habitLibrary) {
      let newHabits = [...habitLibrary];

      if (search !== "") {
        newHabits = newHabits.filter(
          (habit) =>
            habit.name!.toLowerCase().indexOf(search.toLowerCase()) !== -1,
        );
      }

      if (selectedLibraryFilters.length >= 1) {
        if (!selectedLibraryFilters.includes("client")) {
          newHabits = newHabits.filter((habit) => !habit.user_id);
        }

        if (!selectedLibraryFilters.includes("trainer")) {
          newHabits = newHabits.filter((habit) => !habit.trainer_id);
        }

        if (!selectedLibraryFilters.includes("trainwell")) {
          newHabits = newHabits.filter(
            (habit) => !(!habit.user_id && !habit.trainer_id),
          );
        }
      }

      if (selectedHabitTypeFilters.length >= 1) {
        newHabits = newHabits.filter((habit) =>
          selectedHabitTypeFilters.includes(habit.type as any),
        );
      }

      setDisplayedHabits(newHabits);
    } else {
      setDisplayedHabits([]);
    }
  }, [habitLibrary, search, selectedLibraryFilters, selectedHabitTypeFilters]);

  function Header() {
    return (
      <>
        <Box sx={{ m: 1 }}>
          <Typography variant="subtitle1">Library</Typography>
          {possibleLibraryFilters.map((key, i) => {
            return (
              <Chip
                key={i}
                label={libraryFilters[key]}
                onClick={() => {
                  const newSelectedBodyParts = [...selectedLibraryFilters];
                  const index = newSelectedBodyParts.indexOf(key);

                  if (index === -1) {
                    newSelectedBodyParts.push(key);
                  } else {
                    newSelectedBodyParts.splice(index, 1);
                  }

                  setSelectedLibraryFilters(newSelectedBodyParts);
                }}
                sx={{ mr: 1, mb: 1 }}
                variant={
                  !selectedLibraryFilters.includes(key) ? "outlined" : "filled"
                }
                color={
                  !selectedLibraryFilters.includes(key)
                    ? "default"
                    : "blueSurface"
                }
                size="small"
                icon={
                  key === "trainwell" ? (
                    <LanguageRoundedIcon />
                  ) : key === "trainer" ? (
                    <LocalLibraryRoundedIcon />
                  ) : undefined
                }
              />
            );
          })}
          <Typography variant="subtitle1">Habit type</Typography>
          {possibleHabitTypeFilters.map((key, i) => {
            return (
              <Chip
                key={i}
                label={habitTypeFilters[key]}
                onClick={() => {
                  const newSelectedBodyParts = [...selectedHabitTypeFilters];
                  const index = newSelectedBodyParts.indexOf(key);

                  if (index === -1) {
                    newSelectedBodyParts.push(key);
                  } else {
                    newSelectedBodyParts.splice(index, 1);
                  }

                  setSelectedHabitTypeFilters(newSelectedBodyParts);
                }}
                sx={{ mr: 1, mb: 1 }}
                variant={
                  !selectedHabitTypeFilters.includes(key)
                    ? "outlined"
                    : "filled"
                }
                color={
                  !selectedHabitTypeFilters.includes(key)
                    ? "default"
                    : "blueSurface"
                }
                size="small"
              />
            );
          })}
        </Box>
        <Box
          sx={{
            borderStyle: "dashed",
            borderWidth: "2px",
            borderRadius: 1,
            borderColor: (theme) => theme.palette.divider,
            m: 1,
          }}
        >
          <Button
            fullWidth
            variant="text"
            startIcon={<AddRoundedIcon />}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Create habit
          </Button>
        </Box>
      </>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          p: 1,
          backgroundColor: (theme) => theme.palette.background.paper,
          display: "flex",
          alignItems: "center",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <SearchField
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
          onClear={() => {
            setSearch("");
          }}
          name="habitPanel.search"
        />
        {/* <IconButton
          sx={{ ml: 1 }}
          onClick={(event) => {
            setFilterAnchorEl(event.currentTarget);
          }}
        >
          <FilterListRoundedIcon />
        </IconButton> */}
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
        <Virtuoso
          style={{ height: "100%" }}
          data={displayedHabits}
          components={{
            Header: Header,
          }}
          itemContent={(habitIndex, habit) => {
            return (
              <HabitCell key={habit.id} index={habitIndex} habit={habit} />
            );
          }}
        />
      </Box>
      <HabitEditDialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </Box>
  );
}
