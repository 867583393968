import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Popover,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  useCreateMilestone,
  useUpdateMilestone,
  type CreateMilestone,
  type Milestone,
  type MilestoneExercise,
  type MilestoneProgressMetric,
  type MilestoneText,
} from "@trainwell/features";
import { exerciseLibrary } from "@trainwell/workout-lib";
import { startOfDay } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { getExerciseDisplayName } from "src/lib/mediaUtility";

const blankMilestoneData: CreateMilestone = {
  type: "text",
  content: {
    text: "",
  },
  user_id: "",
  notes: "",
};

type Props = {
  userId: string;
  defaultMilestone?: Milestone;
  defaultExerciseMasterId?: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

export default function MilestonePopover({
  userId,
  defaultMilestone,
  defaultExerciseMasterId,
  anchorEl,
  onClose,
}: Props) {
  const [milestoneData, setMilestoneData] = useState<CreateMilestone>({
    ...(defaultMilestone ?? blankMilestoneData),
    user_id: userId,
    ...(defaultExerciseMasterId && {
      type: "exercise",
      content: { exercise_master_id: defaultExerciseMasterId },
    }),
  });
  const createMilestone = useCreateMilestone();
  const updateMilestone = useUpdateMilestone();
  const progressMetrics = useAppSelector(
    (state) => state.progressMetrics.progressMetrics,
  );
  const exerciseMasterIds = useMemo(() => {
    const allExerciseMasterIds = Object.keys(exerciseLibrary).filter(
      (exerciseMasterId) => {
        const exercise = exerciseLibrary[exerciseMasterId];

        return !exercise.reps_variant_id && !exercise.not_quick_variant_id;
      },
    );

    return allExerciseMasterIds;
  }, []);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!open) {
      setMilestoneData({
        ...(defaultMilestone ?? blankMilestoneData),
        user_id: userId,
        ...(defaultExerciseMasterId && {
          type: "exercise",
          content: { exercise_master_id: defaultExerciseMasterId },
        }),
      });
    }
  }, [open, defaultMilestone, defaultExerciseMasterId, userId]);

  function handleClose() {
    onClose();
  }

  function handleSave() {
    if (!userId) {
      return;
    }

    if (!defaultMilestone) {
      createMilestone.mutate({
        data: milestoneData,
      });
    } else {
      updateMilestone.mutate({
        milestoneId: defaultMilestone._id,
        data: milestoneData,
      });
    }

    handleClose();
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      slotProps={{ paper: { sx: { p: 1.5, width: 300 } } }}
    >
      <Typography variant="h2" sx={{ mb: 2 }}>
        Create a milestone
      </Typography>
      <ToggleButtonGroup
        sx={{ mb: 2 }}
        value={milestoneData.type ?? "text"}
        exclusive
        onChange={(event, newValue) => {
          if (newValue === "text" && milestoneData.type !== "text") {
            setMilestoneData({
              ...milestoneData,
              type: "text",
              content: {
                text: "",
              },
            });
          } else if (
            newValue === "exercise" &&
            milestoneData.type !== "exercise"
          ) {
            setMilestoneData({
              ...milestoneData,
              type: "exercise",
              content: {
                exercise_master_id: "",
              },
            });
          } else if (
            newValue === "progress_metric" &&
            milestoneData.type !== "progress_metric"
          ) {
            setMilestoneData({
              ...milestoneData,
              type: "progress_metric",
              content: {
                progress_metric_id: "",
              },
            });
          }
        }}
      >
        <ToggleButton value="text">Text</ToggleButton>
        <ToggleButton value="exercise">Exercise</ToggleButton>
        <ToggleButton value="progress_metric">Progress metric</ToggleButton>
      </ToggleButtonGroup>
      <Box sx={{ mb: 2 }}>
        {milestoneData.type === "text" && (
          <TextField
            fullWidth
            autoFocus={!defaultMilestone}
            label="Milestone"
            multiline
            value={(milestoneData as MilestoneText).content.text ?? ""}
            onChange={(event) => {
              setMilestoneData({
                ...milestoneData,
                content: {
                  ...milestoneData.content,
                  text: event.target.value,
                },
              });
            }}
          />
        )}
        {milestoneData.type === "progress_metric" && (
          <Autocomplete
            options={progressMetrics.map((m) => m.id)}
            fullWidth
            getOptionLabel={(option) =>
              progressMetrics.find((m) => m.id === option)?.name ?? ""
            }
            renderInput={(params) => (
              <TextField {...params} label="Progress metric" />
            )}
            value={
              (milestoneData as MilestoneProgressMetric).content
                .progress_metric_id || null
            }
            onChange={(event, newValue) => {
              setMilestoneData({
                ...milestoneData,
                content: {
                  ...milestoneData.content,
                  progress_metric_id: newValue,
                },
              });
            }}
          />
        )}
        {milestoneData.type === "exercise" && (
          <Autocomplete
            options={exerciseMasterIds}
            fullWidth
            getOptionLabel={(exerciseMasterId) =>
              getExerciseDisplayName(exerciseMasterId) ?? ""
            }
            getOptionKey={(exerciseMasterId) => exerciseMasterId}
            renderInput={(params) => <TextField {...params} label="Exercise" />}
            value={
              (milestoneData as MilestoneExercise).content.exercise_master_id ||
              null
            }
            onChange={(event, newValue) => {
              setMilestoneData({
                ...milestoneData,
                content: {
                  exercise_master_id: newValue,
                },
              });
            }}
          />
        )}
        <TextField
          fullWidth
          label="Notes"
          size="small"
          multiline
          value={(milestoneData as MilestoneText).notes ?? ""}
          onChange={(event) => {
            setMilestoneData({
              ...milestoneData,
              notes: event.target.value,
            });
          }}
          sx={{ mt: 2 }}
        />
      </Box>
      <FormGroup sx={{ mb: 1 }}>
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(milestoneData.date_to_achieve)}
              onChange={(event) => {
                if (event.target.checked) {
                  setMilestoneData({
                    ...milestoneData,
                    date_to_achieve: startOfDay(new Date()).toISOString(),
                  });
                } else {
                  setMilestoneData({
                    ...milestoneData,
                    date_to_achieve: null,
                  });
                }
              }}
            />
          }
          label="Set a date"
        />
      </FormGroup>
      {Boolean(milestoneData.date_to_achieve) && (
        <DatePicker
          value={
            milestoneData.date_to_achieve
              ? new Date(milestoneData.date_to_achieve)
              : new Date()
          }
          onChange={(newValue) => {
            setMilestoneData({
              ...milestoneData,
              date_to_achieve: startOfDay(newValue ?? new Date()).toISOString(),
            });
          }}
          slotProps={{
            field: {
              sx: {
                width: "100%",
                mb: 2,
              },
            },
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button onClick={handleSave} sx={{ ml: 2 }}>
          Save
        </Button>
      </Box>
    </Popover>
  );
}
