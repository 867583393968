import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import StickyNote2RoundedIcon from "@mui/icons-material/StickyNote2Rounded";
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  type SxProps,
  type Theme,
} from "@mui/material";
import { ChartContainer, LinePlot } from "@mui/x-charts";
import { type MilestoneProgressMetric } from "@trainwell/features";
import { format, formatDistanceToNow } from "date-fns";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { useGetProgressMetricLogsQuery } from "src/slices/api/progressMetricLogsApi";
import { MilestoneMoreMenu } from "./MilestoneMoreMenu";

type Props = {
  sx?: SxProps<Theme>;
  milestone: MilestoneProgressMetric;
};

export function MilestoneCellProgressMetric({ sx = [], milestone }: Props) {
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const progressMetric = useAppSelector((state) =>
    state.progressMetrics.progressMetrics.find(
      (p) => p.id === milestone.content.progress_metric_id,
    ),
  );
  const { data: progressMetricLogs, isLoading } = useGetProgressMetricLogsQuery(
    {
      userId: milestone.user_id,
      progressMetricId: milestone.content.progress_metric_id,
    },
  );
  const preferredWeightSystem = useAppSelector(
    (state) => state.client.client?.preferred_weight_system ?? "imperial",
  );

  if (!progressMetric || !progressMetricLogs) {
    return null;
  }

  function round(number: number) {
    return Math.round(number * 10) / 10;
  }

  const units =
    preferredWeightSystem === "imperial"
      ? progressMetric.unit_imperial
      : progressMetric.unit_metric;

  const dataPoints = progressMetricLogs
    .map((log) => ({
      x: new Date(log.date).getTime(),
      y: round(
        (log.measurement ?? 0) *
          (preferredWeightSystem === "imperial"
            ? 1
            : (progressMetric.ratio_metric ?? 1)),
      ),
    }))
    .sort((a, b) => a.x - b.x);

  const firstValue = dataPoints.length > 0 ? dataPoints[0] : null;
  const lastValue =
    dataPoints.length > 1 ? dataPoints[dataPoints.length - 1] : null;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
        <Box sx={{ mr: 1 }}>
          <Typography>{progressMetric.name}</Typography>
          {milestone.date_to_achieve && (
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {format(milestone.date_to_achieve, "MMM do, yyyy")}
            </Typography>
          )}
          {milestone.date_achieved && (
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              🎉 {format(milestone.date_achieved, "MMM do, yyyy")}
            </Typography>
          )}
          {!milestone.date_achieved && firstValue && (
            <Stack direction={"row"} spacing={1}>
              <Tooltip
                title={formatDistanceToNow(firstValue.x) + " ago"}
                disableInteractive
              >
                <Typography variant="overline">
                  Start: {firstValue.y} {units}
                </Typography>
              </Tooltip>
              {lastValue && (
                <Tooltip
                  title={formatDistanceToNow(lastValue.x) + " ago"}
                  disableInteractive
                >
                  <Typography variant="overline">
                    Recent: {lastValue.y} {units}
                  </Typography>
                </Tooltip>
              )}
            </Stack>
          )}
        </Box>
        {dataPoints.length > 0 ? (
          <ChartContainer
            dataset={dataPoints}
            height={25}
            width={25}
            colors={[theme.palette.primary.main]}
            xAxis={[
              {
                dataKey: "x",
                valueFormatter: (value) =>
                  format(value as number, "MMM d, yyy"),
                label: "x",
                hideTooltip: true,
                min: Math.min(...dataPoints.map((d) => d.x)),
                max: Math.max(...dataPoints.map((d) => d.x)),
              },
            ]}
            yAxis={[
              {
                min: Math.max(Math.min(...dataPoints.map((d) => d.y)) - 2, 0),
                max: Math.max(...dataPoints.map((d) => d.y)) + 2,
              },
            ]}
            series={[
              {
                type: "line",
                dataKey: "y",
                label: "y",
                showMark: false,
                disableHighlight: true,
              },
            ]}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            disableAxisListener
          >
            <LinePlot skipAnimation />
          </ChartContainer>
        ) : (
          <Typography
            sx={{
              textAlign: "center",
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            No data
          </Typography>
        )}
      </Box>
      <Stack sx={{ ml: 1 }} direction={"row"} spacing={0} alignItems={"center"}>
        {milestone.notes && (
          <Tooltip title={milestone.notes} disableInteractive>
            <IconButton size="small">
              <StickyNote2RoundedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {!milestone.date_achieved && (
          <IconButton
            size="small"
            onClick={(event) => {
              setMoreAnchorEl(event.currentTarget);
            }}
          >
            <MoreVertRoundedIcon fontSize="inherit" />
          </IconButton>
        )}
      </Stack>
      <MilestoneMoreMenu
        anchorEl={moreAnchorEl}
        milestone={milestone}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      />
    </Box>
  );
}
