import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  Avatar,
  Box,
  Button,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { shallowEqual } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DragPreview } from "src/components/WorkoutBuilderPage/DragPreview";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import {
  selectClientErrors,
  selectClientsWorkoutValidity,
  selectWorkoutUsedCount,
} from "src/slices/clientSlice";
import {
  deletePhase,
  duplicatePhase,
  selectPhaseById,
  selectWorkoutById,
  updatePhase,
  updateWorkout,
} from "src/slices/phasesSlice";
import WorkoutPreviewPopover from "../WorkoutPreviewPopover";
import { SaveAsTemplatePopover } from "./SaveAsTemplatePopover";
import { ShareToClientPopover } from "./ShareToClientPopover";
import { ShareToCoachPopover } from "./ShareToCoachPopover";

type Props = {
  phaseId: string;
};

export default function WorkoutCellDraggable({ phaseId }: Props) {
  const phase = useAppSelector((state) => selectPhaseById(state, phaseId));
  const workoutId = phase?.workout_ids[0][0] ?? "";
  const ref = useRef(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "workout",
      workoutId: workoutId,
      phaseId: phaseId,
      isSouce: true,
    };

    return draggable({
      element: element,
      getInitialData: () => data,
      onGenerateDragPreview({ nativeSetDragImage }) {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          getOffset: pointerOutsideOfPreview({
            x: "16px",
            y: "8px",
          }),
          render({ container }) {
            setPreviewContainer(container);
          },
        });
      },
    });
  }, [workoutId, phaseId]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div ref={ref}>
          <WorkoutCell phaseId={phaseId} />
        </div>
      </div>
      {previewContainer
        ? createPortal(
            <DragPreview
              text={phase?.name ?? "Workout"}
              icon={
                <Avatar
                  sx={{
                    backgroundColor: (theme) => theme.palette.blue.main,
                    width: 20,
                    height: 20,
                    borderRadius: "6px",
                  }}
                >
                  <FitnessCenterRoundedIcon
                    sx={{
                      fontSize: 16,
                    }}
                  />
                </Avatar>
              }
            />,
            previewContainer,
          )
        : null}
    </>
  );
}

const WorkoutCell = memo(function WorkoutCell({ phaseId }: Props) {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const phase = useAppSelector((state) => selectPhaseById(state, phaseId));
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, phase?.workout_ids[0][0] ?? ""),
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const usedCount = useAppSelector((state) =>
    selectWorkoutUsedCount(state, workout?.workout_id ?? ""),
  );
  const [deleteDialogCount, setDeleteDialogCount] = useState<number | null>(
    null,
  );
  const clientErrors = useAppSelector(selectClientErrors, shallowEqual);
  const workoutValidityStatus = useAppSelector(
    (state) =>
      selectClientsWorkoutValidity(state, workout?.workout_id ?? "")?.status,
  );
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [saveAsTemplateAnchorEl, setSaveAsTemplateAnchorEl] =
    useState<null | HTMLButtonElement>(null);
  const [shareToClientAnchorEl, setShareToClientAnchorEl] =
    useState<null | HTMLButtonElement>(null);
  const [shareToCoachAnchorEl, setShareToCoachAnchorEl] =
    useState<null | HTMLButtonElement>(null);

  if (!workout) {
    return <>Loading</>;
  }

  const dateUpdated = !workout.metadata.dates_updated
    ? (workout.metadata.date_created ?? new Date())
    : (workout.metadata.dates_updated[
        workout.metadata.dates_updated.length - 1
      ] ?? workout.metadata.date_created);

  return (
    <>
      <CardActionArea
        onClick={() => {
          navigate(`/clients/${userId}/workouts/${workout.workout_id}`);
        }}
        onContextMenu={(event) => {
          event.preventDefault();
          setAnchorEl(event.currentTarget);
        }}
        sx={{
          pl: 1,
          py: 1,
          pr: 0.25,
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: (theme) =>
            !workout.metadata.date_last_opened
              ? theme.palette.warningSurface.main
              : workoutValidityStatus === "error"
                ? theme.palette.errorSurface.main
                : undefined,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Avatar
              sx={{
                backgroundColor: (theme) => theme.palette.blue.main,
                mr: 0.5,
                mt: 0.5,
                width: 15,
                height: 15,
                borderRadius: "4px",
              }}
            >
              <FitnessCenterRoundedIcon
                sx={{
                  fontSize: 12,
                }}
              />
            </Avatar>
            <Typography>{workout?.name}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();

                if (workout.is_extra) {
                  dispatch(
                    updateWorkout({
                      workout_id: workout.workout_id,
                      is_extra: false,
                    }),
                  );
                } else {
                  dispatch(
                    updateWorkout({
                      workout_id: workout.workout_id,
                      is_extra: true,
                    }),
                  );
                }
              }}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
              size="small"
            >
              {workout.is_extra ? (
                <StarRoundedIcon sx={{ fontSize: 18 }} />
              ) : (
                <StarBorderRoundedIcon sx={{ fontSize: 18 }} />
              )}
            </IconButton>
            {phase?.date_deleted ? (
              <Tooltip title="Restore" placement="right">
                <IconButton
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();

                    dispatch(
                      updatePhase({
                        id: phaseId,
                        dateDeleted: null,
                      }),
                    );
                  }}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <RestoreFromTrashRoundedIcon sx={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();

                  setMoreAnchorEl(event.currentTarget);
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              >
                <MoreVertRoundedIcon sx={{ fontSize: 18 }} />
              </IconButton>
            )}
          </Box>
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {workout?.notes_trainer}
        </Typography>
        <Typography variant="overline">
          Updated {formatDistanceToNow(new Date(dateUpdated))} ago
        </Typography>
      </CardActionArea>
      {anchorEl && (
        <WorkoutPreviewPopover
          anchorEl={anchorEl}
          workoutId={workout.workout_id}
          onClose={() => {
            setAnchorEl(null);
          }}
        />
      )}
      <Dialog
        open={deleteDialogCount !== null}
        onClose={() => {
          setDeleteDialogCount(null);
        }}
      >
        <DialogTitle>Also delete from plan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This workout is used <b>{deleteDialogCount}</b> times in your
            client&apos;s workout program. This action will remove them and save
            your plan.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteDialogCount(null);
            }}
            color="primary"
            variant="text"
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              dispatch(deletePhase({ phaseId }));
            }}
            color="error"
            variant="contained"
            disabled={clientErrors.length !== 0}
          >
            Delete and save
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        open={Boolean(moreAnchorEl)}
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            dispatch(
              duplicatePhase({
                phaseId,
              }),
            );

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ContentCopyRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Make a copy" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShareToClientAnchorEl(moreAnchorEl);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <IosShareRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Share to client" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShareToCoachAnchorEl(moreAnchorEl);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <IosShareRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Share to trainer" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSaveAsTemplateAnchorEl(moreAnchorEl);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ExtensionRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Save as template" />
        </MenuItem>
        <Box>
          <Divider sx={{ my: 1 }} />
        </Box>
        <MenuItem
          onClick={() => {
            if (usedCount >= 1) {
              setDeleteDialogCount(usedCount);
            } else {
              dispatch(deletePhase({ phaseId }));
            }

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            sx={{ color: (theme) => theme.palette.error.main }}
          />
        </MenuItem>
      </Menu>
      <ShareToClientPopover
        phaseId={phaseId}
        anchorEl={shareToClientAnchorEl}
        onClose={() => {
          setShareToClientAnchorEl(null);
        }}
      />
      <ShareToCoachPopover
        onShare={(targetTrainerId) => {
          return api.phases.saveAsTemplate({
            phaseId,
            trainerId: targetTrainerId,
          });
        }}
        anchorEl={shareToCoachAnchorEl}
        onClose={() => {
          setShareToCoachAnchorEl(null);
        }}
      />
      <SaveAsTemplatePopover
        phaseId={phaseId}
        anchorEl={saveAsTemplateAnchorEl}
        onClose={() => {
          setSaveAsTemplateAnchorEl(null);
        }}
      />
    </>
  );
});
