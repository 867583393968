import DataArrayRoundedIcon from "@mui/icons-material/DataArrayRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FiberNewRoundedIcon from "@mui/icons-material/FiberNewRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import MarkChatUnreadRoundedIcon from "@mui/icons-material/MarkChatUnreadRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";
import SearchField from "src/components/misc/SearchField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import { getUserIdsMatchingFilter } from "src/lib/chat";
import { selectSentActionItems } from "src/slices/actionItemSlice";
import {
  fetchAllMessages,
  fetchChats,
  fetchTicketChats,
  markChatAsUnread,
  selectSortedChats,
  selectUnreadTicketCount,
  setChatView,
  setCurrentTab,
  toggleChatFilter,
  toggleChatPinned,
} from "src/slices/chatSlice";
import {
  selectIsAuditing,
  selectPrimaryTrainer,
} from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";
import ClientFilterButton from "../HomePage/ClientFilterButton";
import ChatCell from "./ChatCell";
import ChatListFilter from "./ChatListFilter";
import ChatSortButton, {
  chatSortOptions,
  type ChatSort,
} from "./ChatSortButton";
import { PanelOfficialTrainwellChats } from "./PanelOfficialTrainwellChats";
import PanelTicketChats from "./PanelTicketChats";

type Props = {
  condensed?: boolean;
};

export default function ChatList({ condensed }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [search, setSearch] = useState("");
  const [chatSort, setChatSort] = useState<ChatSort>(
    trainer?.settings.chat_sort &&
      chatSortOptions
        .map((o) => o.id)
        .includes(trainer.settings.chat_sort as ChatSort)
      ? (trainer.settings.chat_sort as ChatSort)
      : "oldest",
  );
  const isSelectingChats = useAppSelector(
    (state) => state.chat.isSelectingChats,
  );
  const selectedChatCount = useAppSelector(
    (state) => state.chat.selectedChatIds.length,
  );
  const selectedChatId = useAppSelector((state) => state.chat.selectedChatId);
  const currentTab = useAppSelector((state) => state.chat.currentTab);
  const chats = useAppSelector((state) =>
    selectSortedChats(state, chatSort, search),
  );
  const ticketChatStatus = useAppSelector(
    (state) => state.chat.ticketChatsStatus,
  );
  const isAuditing = useAppSelector(selectIsAuditing);
  const unreadTicketCount = useAppSelector(selectUnreadTicketCount);
  const scrollRef = useRef<HTMLDivElement>();
  const selectedFilters = useAppSelector((state) => state.chat.selectedFilters);
  const clients = useAppSelector((state) => state.clients.clients);
  const actionItems = useAppSelector(selectSentActionItems);
  const clientInfo = useAppSelector((state) => state.clients.clientInfo);
  const [clientFilters, setClientFilters] = useState<string[]>(
    JSON.parse(sessionStorage.getItem("chat_filters") ?? '["visible"]'),
  );
  const [filterMode, setFilterMode] = useState<"and" | "or">("and");
  const clientsStatus = useAppSelector((state) => state.clients.status);

  useEffect(() => {
    if (clientsStatus === "succeeded") {
      dispatch(fetchChats());
      dispatch(fetchAllMessages());
    }
  }, [dispatch, clientsStatus]);

  const now = new Date().toISOString();

  let displayedChats = [...chats];

  if (clientFilters.length > 0 && search === "") {
    const filteredUserIdSets: string[][] = [];

    for (const filter of clientFilters) {
      const filteredUserIds = getUserIdsMatchingFilter(
        filter,
        clients,
        clientInfo,
        actionItems,
        trainer!,
      );

      filteredUserIdSets.push(filteredUserIds);
    }

    displayedChats = displayedChats.filter((chat) =>
      filterMode === "and"
        ? filteredUserIdSets.every((filteredUserIds) =>
            filteredUserIds.includes(chat.id),
          ) || chat.pinned
        : filteredUserIdSets.some((filteredUserIds) =>
            filteredUserIds.includes(chat.id),
          ) || chat.pinned,
    );
  }

  const pinnedChats = displayedChats.filter((chat) => chat.pinned);
  const pinnedChatIds = pinnedChats.map((chat) => chat.id);

  const trialChats = displayedChats.filter(
    (chat) =>
      (chat.oldestUnreadMessageFromClient ||
        actionItems.some((ai) => ai.user_id === chat.id)) &&
      (clients.find((c) => c.user_id === chat.id)?.account.plan
        .date_trial_end as string) > now &&
      !pinnedChatIds.includes(chat.id),
  );
  const trialChatIds = trialChats.map((chat) => chat.id);

  const dmChats = displayedChats.filter((chat) => {
    if (pinnedChatIds.includes(chat.id) || trialChatIds.includes(chat.id)) {
      return false;
    }

    if (trainer?.settings.chat_questions_includes_dms) {
      return (
        chat.messages.some(
          (message) =>
            message.from_id !== trainer?.trainer_id &&
            (message.type === "text" ||
              message.type === "image" ||
              message.type === "video") &&
            !message.read_date,
        ) || chat.oldestQuestionFromClient
      );
    } else {
      return chat.oldestQuestionFromClient;
    }
  });
  const dmChatIds = dmChats.map((chat) => chat.id);

  const otherChats = displayedChats.filter(
    (chat) =>
      !pinnedChatIds.includes(chat.id) &&
      !trialChatIds.includes(chat.id) &&
      !dmChatIds.includes(chat.id),
  );

  useEffect(() => {
    if (ticketChatStatus === "idle") {
      dispatch(fetchTicketChats());
    }
  }, [ticketChatStatus, dispatch, chatSort]);

  // useEffect(() => {
  //   if (chatSort === "action_items" || chatSort === "action_items_oldest") {
  //     if (!clientFilters.includes("has_action_items")) {
  //       setClientFilters([...clientFilters, "has_action_items"]);
  //     }
  //   }
  // }, [chatSort]);

  const areChatsSelected = selectedChatCount > 0;

  const noMoreChats =
    !pinnedChats.length &&
    !trialChats.length &&
    !dmChats.length &&
    !otherChats.length;

  const chatIdsInOrder = [
    ...pinnedChatIds,
    ...trialChatIds,
    ...dmChatIds,
    ...otherChats.map((chat) => chat.id),
  ];

  useHotkeys("up, left", () => {
    if (chatIdsInOrder.length > 0) {
      const currentIndex = chatIdsInOrder.findIndex(
        (id) => id === selectedChatId,
      );
      const newIndex = currentIndex === -1 ? -1 : currentIndex - 1;
      const newChatId = chatIdsInOrder.at(newIndex);

      if (newChatId === undefined) {
        return;
      }

      navigate(`/clients/${newChatId}`);
    }
  });

  useHotkeys("down, right", () => {
    if (chatIdsInOrder.length > 0) {
      const currentIndex = chatIdsInOrder.findIndex(
        (id) => id === selectedChatId,
      );
      const newIndex = currentIndex + 1;
      const newChatId = chatIdsInOrder.at(newIndex);

      if (newChatId === undefined) {
        return;
      }

      navigate(`/clients/${newChatId}`);
    }
  });

  return (
    <Box
      ref={scrollRef}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <TabContext value={currentTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          {isSelectingChats ? (
            <>
              <Box
                sx={{
                  py: 0.5,
                  px: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack direction={"row"} spacing={1}>
                  <Button
                    variant="text"
                    size="small"
                    disabled={!areChatsSelected}
                    startIcon={<PushPinRoundedIcon />}
                    onClick={() => {
                      dispatch(toggleChatPinned());
                    }}
                  >
                    Pin
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    disabled={!areChatsSelected}
                    startIcon={<MarkChatUnreadRoundedIcon />}
                    onClick={() => {
                      dispatch(markChatAsUnread());
                    }}
                  >
                    Unread
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    disabled={!areChatsSelected}
                    startIcon={<GroupsRoundedIcon />}
                    onClick={() => {
                      dispatch(setChatView("mass_message"));
                    }}
                  >
                    Message
                  </Button>
                </Stack>
                {areChatsSelected && (
                  <Typography sx={{ fontWeight: "bold" }}>
                    {selectedChatCount}
                  </Typography>
                )}
              </Box>
              <ChatListFilter
                onToggle={(filter) => {
                  dispatch(toggleChatFilter(filter));
                }}
                selectedFilters={selectedFilters}
                sx={{ px: 1 }}
              />
            </>
          ) : !condensed ? (
            <TabList
              onChange={(_, newValue) => {
                dispatch(setCurrentTab(newValue as any));
              }}
              variant="fullWidth"
              centered
              sx={{
                minHeight: "40px",
                "& .MuiTab-root": {
                  p: 1,
                  minHeight: "40px",
                },
              }}
            >
              <Tab label={"Clients"} value="clients" />
              <Tab
                label={`Tickets${
                  unreadTicketCount > 0 ? ` (${unreadTicketCount})` : ""
                }`}
                value="tickets"
              />
              {trainerHasAccess(
                trainer?.access_roles,
                "official_trainwell_group_chats",
              ) && <Tab label={"Support chat"} value="official_trainwell" />}
            </TabList>
          ) : null}
        </Box>
        <Box sx={{ flex: 1 }}>
          <TabPanel value="clients" sx={{ p: 0 }}>
            {!condensed && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  py: 1,
                  px: 1,
                  backgroundColor: (theme) => theme.palette.background.default,
                }}
              >
                <SearchField
                  value={search}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                  onClear={() => {
                    setSearch("");
                  }}
                  sx={{ mr: 1 }}
                />
                <ChatSortButton
                  value={chatSort}
                  onChange={(newSort) => {
                    setChatSort(newSort);

                    if (!isAuditing) {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer!.trainer_id,
                          settings: {
                            ...trainer!.settings,
                            chat_sort: newSort,
                          },
                        }),
                      );
                    }
                  }}
                />
                <ClientFilterButton
                  filters={clientFilters}
                  onChange={(newFilters) => {
                    setClientFilters(newFilters);

                    sessionStorage.setItem(
                      "chat_filters",
                      JSON.stringify(newFilters),
                    );
                  }}
                  mode={filterMode}
                  onModeChange={(newMode) => {
                    setFilterMode(newMode);
                  }}
                  disabled={search !== ""}
                />
              </Box>
            )}
            <Box>
              {search !== "" ? (
                displayedChats.map((chat) => (
                  <ChatCell key={chat.id} chat={chat} condensed={condensed} />
                ))
              ) : (
                <>
                  {noMoreChats && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        py: 4,
                      }}
                    >
                      <Tooltip
                        title="No chats"
                        placement="right"
                        disableInteractive
                      >
                        <DataArrayRoundedIcon
                          sx={{
                            color: (theme) => theme.palette.text.secondary,
                          }}
                        />
                      </Tooltip>
                      {!condensed && (
                        <Typography
                          variant="h6"
                          sx={{
                            ml: 2,
                            color: (theme) => theme.palette.text.secondary,
                          }}
                        >
                          No chats
                        </Typography>
                      )}
                    </Box>
                  )}
                  {pinnedChats.length > 0 && (
                    <Accordion
                      disableGutters
                      elevation={0}
                      square
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreRoundedIcon
                            fontSize={condensed ? "small" : undefined}
                          />
                        }
                        sx={{
                          borderTop: 1,
                          borderBottom: 1,
                          borderColor: "divider",
                          backgroundColor: (theme) =>
                            theme.palette.backgroundSecondary.main,
                        }}
                      >
                        {condensed ? (
                          <Tooltip
                            title={`${pinnedChats.length} Pinned`}
                            placement="right"
                            disableInteractive
                          >
                            <PushPinRoundedIcon fontSize="inherit" />
                          </Tooltip>
                        ) : (
                          <Typography sx={{ fontWeight: "bold" }}>
                            {pinnedChats.length} Pinned
                          </Typography>
                        )}
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0 }}>
                        {pinnedChats.map((chat) => (
                          <ChatCell
                            key={chat.id}
                            chat={chat}
                            condensed={condensed}
                          />
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {trialChats.length > 0 && (
                    <Accordion
                      disableGutters
                      elevation={0}
                      square
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreRoundedIcon
                            fontSize={condensed ? "small" : undefined}
                          />
                        }
                        sx={{
                          borderTop: 1,
                          borderBottom: 1,
                          borderColor: "divider",
                          backgroundColor: (theme) =>
                            theme.palette.backgroundSecondary.main,
                        }}
                      >
                        {condensed ? (
                          <Tooltip
                            title={`${trialChats.length} Unread trial clients`}
                            placement="right"
                            disableInteractive
                          >
                            <FiberNewRoundedIcon fontSize="inherit" />
                          </Tooltip>
                        ) : (
                          <Typography sx={{ fontWeight: "bold" }}>
                            {trialChats.length} Trial clients with AIs
                          </Typography>
                        )}
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0 }}>
                        {trialChats.map((chat) => (
                          <ChatCell
                            key={chat.id}
                            chat={chat}
                            condensed={condensed}
                          />
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {dmChats.length > 0 && (
                    <Accordion
                      disableGutters
                      elevation={0}
                      square
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreRoundedIcon
                            fontSize={condensed ? "small" : undefined}
                          />
                        }
                        sx={{
                          borderTop: 1,
                          borderBottom: 1,
                          borderColor: "divider",
                          backgroundColor: (theme) =>
                            theme.palette.backgroundSecondary.main,
                        }}
                      >
                        {condensed ? (
                          <Tooltip
                            title={`${dmChats.length} ${
                              trainer?.settings.chat_questions_includes_dms
                                ? "Questions and DMs"
                                : "Questions"
                            }`}
                            placement="right"
                            disableInteractive
                          >
                            {trainer?.settings.chat_questions_includes_dms ? (
                              <QuestionAnswerRoundedIcon fontSize="inherit" />
                            ) : (
                              <QuestionMarkRoundedIcon fontSize="inherit" />
                            )}
                          </Tooltip>
                        ) : (
                          <Typography sx={{ fontWeight: "bold" }}>
                            {dmChats.length}{" "}
                            {trainer?.settings.chat_questions_includes_dms
                              ? "Questions and DMs"
                              : "Questions"}
                          </Typography>
                        )}
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0 }}>
                        {dmChats.map((chat) => (
                          <ChatCell
                            key={chat.id}
                            chat={chat}
                            condensed={condensed}
                          />
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {otherChats.length > 0 && (
                    <Accordion
                      disableGutters
                      elevation={0}
                      square
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreRoundedIcon
                            fontSize={condensed ? "small" : undefined}
                          />
                        }
                        sx={{
                          borderTop: 1,
                          borderBottom: 1,
                          borderColor: "divider",
                          backgroundColor: (theme) =>
                            theme.palette.backgroundSecondary.main,
                        }}
                      >
                        {condensed ? (
                          <Tooltip
                            title={`${otherChats.length} Other`}
                            placement="right"
                            disableInteractive
                          >
                            <MoreHorizRoundedIcon fontSize="inherit" />
                          </Tooltip>
                        ) : (
                          <Typography sx={{ fontWeight: "bold" }}>
                            {otherChats.length} Other
                          </Typography>
                        )}
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0 }}>
                        {otherChats.map((chat) => (
                          <ChatCell
                            key={chat.id}
                            chat={chat}
                            condensed={condensed}
                          />
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  )}
                </>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="tickets" sx={{ p: 0, height: "100%" }}>
            <PanelTicketChats />
          </TabPanel>
          <TabPanel value="official_trainwell" sx={{ p: 0, height: "100%" }}>
            <PanelOfficialTrainwellChats />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}
