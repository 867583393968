import CallRoundedIcon from "@mui/icons-material/CallRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import { differenceInCalendarDays, format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getClientStatusColor, getClientSummary } from "src/lib/client";
import { getDateWithTimezoneOffset } from "src/lib/date";
import { selectActionItemsForClient } from "src/slices/actionItemSlice";
import { toggleDetails } from "src/slices/clientSlice";
import { selectUpcomingCallForUserId } from "src/slices/clientsSlice";
import SendPrecallSurveyButton from "../chat/SendPrecallSurveyButton";
import ClientTags from "../ClientOverview/ClientTags";
import ClientName from "../misc/ClientName";
import ClientProfilePicture from "../misc/ClientProfilePicture";
import { DialogTitleWithClose } from "../misc/DialogTitleWithClose";
import { CallClientButton } from "./CallClientButton";
import { ClientChips } from "./ClientChips";
import { ClientEquipmentDialog } from "./ClientEquipmentDialog";
import { ClientNotesDialog } from "./ClientNotesDialog";
import { Consistency } from "./Consistency";
import { Milestones } from "./Milestones";

type Props = {
  sx?: SxProps<Theme>;
  reduceInfo?: boolean;
  disableButtons?: boolean;
};

export default function ClientCard({
  sx = [],
  reduceInfo,
  disableButtons,
}: Props) {
  const client = useAppSelector((state) => state.client.client);
  const upcomingCall = useAppSelector((state) =>
    selectUpcomingCallForUserId(state, client?.user_id ?? ""),
  );
  const detailsOpen = useAppSelector((state) => state.client.detailsOpen);
  const dispatch = useAppDispatch();
  const [notesDialogOpen, setNotesDialogOpen] = useState(false);
  const [equipmentDialogOpen, setEquipmentDialogOpen] = useState(false);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [profilePictureOpen, setProfilePictureOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const hasCallSurveyActionItem = useAppSelector((state) =>
    selectActionItemsForClient(state, client?.user_id ?? "").some(
      (actionItem) => actionItem.type === "send_pre_check_in_survey",
    ),
  );

  useHotkeys("n", () => {
    setNotesDialogOpen(!notesDialogOpen);
  });

  useHotkeys("e", () => {
    setEquipmentDialogOpen(!equipmentDialogOpen);
  });

  useHotkeys("d", () => {
    dispatch(toggleDetails());
  });

  if (!client) {
    return <LoadingPage message={"Loading your client 😄"} />;
  }

  const onboarded = client.account.dashboard.date_onboarded;

  const now = new Date();

  const clientSummary = getClientSummary(client.account);

  return (
    <Card variant="outlined" sx={{ p: 2, ...sx }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();

              setProfilePictureOpen(true);
            }}
          >
            <ClientProfilePicture dimension={88} userId={client.user_id} />
          </Box>
          <Box sx={{ ml: 2 }}>
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
              <Typography variant="h1" sx={{ mr: 1 }}>
                👋
              </Typography>
              <ClientName variant="h1" userId={client.user_id} />
            </Box>
            {clientSummary.isSpecial && (
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => getClientStatusColor(client, theme),
                }}
              >
                {clientSummary.text}
              </Typography>
            )}
            <ClientChips sx={{ mb: 1, mt: 1 }} />
            <ClientTags userId={client.user_id} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <IconButton
            onClick={(event) => {
              event.stopPropagation();

              setMoreAnchorEl(event.currentTarget);
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          >
            <MoreVertRoundedIcon sx={{ fontSize: 18 }} />
          </IconButton>
          {upcomingCall && (
            <Card
              variant="outlined"
              sx={{
                p: 1,
                backgroundColor: (theme) => theme.palette.successSurface.main,
                borderColor: (theme) => theme.palette.success.main,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <VideocamRoundedIcon />
                <Typography sx={{ ml: 1 }}>
                  {upcomingCall.content.call_type === "onboarding"
                    ? "Onboarding"
                    : "Check-in"}{" "}
                  call
                </Typography>
              </Box>
              <Tooltip
                title={
                  "Client's time: " +
                  format(
                    getDateWithTimezoneOffset(
                      new Date(upcomingCall.content.date_of_call),
                      client?.default_timezone_offset ?? 0,
                    ),
                    "MMMM do 'at' hh:mm aaa",
                  )
                }
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {format(
                    new Date(upcomingCall.content.date_of_call),
                    "MMMM do 'at' hh:mm aaa",
                  )}
                </Typography>
              </Tooltip>
              {hasCallSurveyActionItem && (
                <SendPrecallSurveyButton sx={{ mt: 1 }} />
              )}
            </Card>
          )}
        </Box>
      </Box>
      {!reduceInfo && (
        <>
          <Box
            sx={{ display: "flex", alignItems: "flex-start", gap: 2, mb: 2 }}
          >
            <Card variant="outlined" sx={{ p: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <QuestionAnswerRoundedIcon fontSize="inherit" />
                <Typography sx={{ ml: 1, fontWeight: "bold" }}>
                  Last message
                </Typography>
              </Box>
              <Stack direction={"row"} spacing={4}>
                <Box>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: 24, lineHeight: 1 }}
                  >
                    {client.last_client_message_date
                      ? differenceInCalendarDays(
                          now,
                          client.last_client_message_date,
                        )
                      : "--"}
                    <Typography
                      component={"span"}
                      variant="overline"
                      sx={{ display: "inline" }}
                    >
                      d
                    </Typography>
                  </Typography>
                  <Typography variant="overline">From client</Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: 24, lineHeight: 1 }}
                  >
                    {client.last_trainer_message_date
                      ? differenceInCalendarDays(
                          now,
                          client.last_trainer_message_date,
                        )
                      : "--"}
                    <Typography
                      component={"span"}
                      variant="overline"
                      sx={{ display: "inline" }}
                    >
                      d
                    </Typography>
                  </Typography>
                  <Typography variant="overline">From you</Typography>
                </Box>
              </Stack>
            </Card>
            <Card
              variant="outlined"
              sx={{
                p: 1,
              }}
            >
              <Consistency />
            </Card>
            <Card variant="outlined" sx={{ p: 1 }}>
              <Milestones />
            </Card>
          </Box>
          {onboarded && !disableButtons && (
            <Stack
              direction={"row"}
              spacing={1}
              sx={{ mt: 2 }}
              alignItems={"center"}
            >
              <Tooltip disableInteractive title="'D' key">
                <Button
                  variant="outlined"
                  onClick={() => {
                    dispatch(toggleDetails());
                  }}
                  startIcon={
                    detailsOpen ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />
                  }
                  size="small"
                >
                  Details
                </Button>
              </Tooltip>
              <Tooltip disableInteractive title="'N' key">
                <IconButton
                  onClick={() => {
                    setNotesDialogOpen(true);
                  }}
                  size="small"
                >
                  <EditNoteRoundedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip disableInteractive title="'E' key">
                <IconButton
                  onClick={() => {
                    setEquipmentDialogOpen(true);
                  }}
                  size="small"
                >
                  <FitnessCenterRoundedIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </>
      )}
      <ClientNotesDialog
        open={notesDialogOpen}
        onClose={() => {
          setNotesDialogOpen(false);
        }}
      />
      <ClientEquipmentDialog
        open={equipmentDialogOpen}
        onClose={() => {
          setEquipmentDialogOpen(false);
        }}
      />
      <Menu
        open={Boolean(moreAnchorEl)}
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            navigator.clipboard
              .writeText(
                `https://account.trainwell.net/schedule?user_id=${client.user_id}`,
              )
              .then(() => {
                enqueueSnackbar("Copied to clipboard", {
                  variant: "success",
                });
              });

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ContentCopyRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Copy client's calendar link" />
        </MenuItem>
        <CallClientButton
          clientPhoneNumber={client.phone_number!}
          button={
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <ListItemIcon>
                <CallRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Call" />
            </MenuItem>
          }
        />
      </Menu>
      <Dialog
        onClose={() => {
          setProfilePictureOpen(false);
        }}
        open={profilePictureOpen}
        maxWidth="lg"
      >
        <DialogTitleWithClose
          onClose={() => {
            setProfilePictureOpen(false);
          }}
        >
          {client.full_name}
        </DialogTitleWithClose>
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <img
              height={500}
              width={500}
              src={client?.headshot_url || "/assets/profile.png"}
              alt="client profile picture"
              style={{
                maxWidth: "100%",
                display: "block",
                objectFit: "contain",
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Card>
  );
}
