import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  useDeleteMilestone,
  useUpdateMilestone,
  type Milestone,
} from "@trainwell/features";
import { useState } from "react";
import MilestonePopover from "./MilestonePopover";

type Props = {
  milestone: Milestone;
  anchorEl: null | HTMLElement;
  onClose: () => void;
};

export function MilestoneMoreMenu({ anchorEl, milestone, onClose }: Props) {
  const deleteMilestone = useDeleteMilestone();
  const updateMilestone = useUpdateMilestone();
  const [editOpen, setEditOpen] = useState(false);

  return (
    <>
      <Menu
        open={Boolean(anchorEl) && !editOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          onClose();
        }}
      >
        <MenuItem
          onClick={() => {
            updateMilestone.mutate({
              milestoneId: milestone._id,
              data: {
                date_achieved: new Date().toISOString(),
              },
            });
          }}
        >
          <ListItemIcon>
            <DoneRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Complete 🎉🎉🎉" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setEditOpen(true);
          }}
        >
          <ListItemIcon>
            <EditRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <Box>
          <Divider sx={{ my: 1 }} />
        </Box>
        <MenuItem
          onClick={() => {
            deleteMilestone.mutate({
              milestoneId: milestone._id,
            });

            onClose();
          }}
        >
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            sx={{ color: (theme) => theme.palette.error.main }}
          />
        </MenuItem>
      </Menu>
      <MilestonePopover
        anchorEl={editOpen ? anchorEl : null}
        onClose={() => {
          setEditOpen(false);
          onClose();
        }}
        userId={milestone.user_id}
        defaultMilestone={milestone}
      />
    </>
  );
}
