import DataArrayRoundedIcon from "@mui/icons-material/DataArrayRounded";
import { Box, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";
import SearchField from "src/components/misc/SearchField";
import { useAppSelector } from "src/hooks/stateHooks";
import { type Chat } from "src/slices/chatSlice";
import ChatCell from "./ChatCell";

type Props = {
  condensed?: boolean;
  userIds: string[];
};

export default function CheckInChatList({ condensed, userIds }: Props) {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const selectedChatId = useAppSelector((state) => state.chat.selectedChatId);
  const chats = useAppSelector((state) => {
    const chatRecord = state.chat.chats;
    const newChats: Chat[] = [];

    if (search === "") {
      for (const userId of userIds) {
        if (chatRecord[userId]) {
          newChats.push(chatRecord[userId]);
        }
      }
    } else {
      for (const chatId in chatRecord) {
        if (!chatRecord[chatId]?.isGroupChat) {
          newChats.push(chatRecord[chatId]);
        }
      }
    }

    return newChats;
  });

  const displayedChats =
    search === ""
      ? [...chats].filter((c) => userIds.includes(c.id))
      : [...chats].filter((c) =>
          c.clientName.toLowerCase().includes(search.toLowerCase()),
        );

  const noMoreChats = !displayedChats.length;

  useHotkeys("up, left", () => {
    if (userIds.length > 0) {
      const currentIndex = userIds.findIndex((id) => id === selectedChatId);
      const newIndex = currentIndex === -1 ? -1 : currentIndex - 1;
      const newChatId = userIds.at(newIndex);

      if (newChatId === undefined) {
        return;
      }

      navigate(`/clients/${newChatId}`);
    }
  });

  useHotkeys("down, right", () => {
    if (userIds.length > 0) {
      const currentIndex = userIds.findIndex((id) => id === selectedChatId);
      const newIndex = currentIndex + 1;
      const newChatId = userIds.at(newIndex);

      if (newChatId === undefined) {
        return;
      }

      navigate(`/clients/${newChatId}`);
    }
  });

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <Box sx={{ flex: 1 }}>
        {!condensed && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              py: 1,
              px: 1,
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: (theme) => theme.palette.background.default,
            }}
          >
            <SearchField
              value={search}
              onChange={(newValue) => {
                setSearch(newValue);
              }}
              onClear={() => {
                setSearch("");
              }}
              // sx={{ mr: 2 }}
            />
            {/* <ChatSortButton
              value={chatSort}
              onChange={(newSort) => {
                setChatSort(newSort);

                if (!isAuditing) {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer!.trainer_id,
                      settings: {
                        ...trainer!.settings,
                        chat_sort: newSort,
                      },
                    }),
                  );
                }
              }}
            /> */}
          </Box>
        )}
        <Box>
          {search !== "" ? (
            displayedChats.map((chat) => (
              <ChatCell key={chat.id} chat={chat} condensed={condensed} />
            ))
          ) : (
            <>
              {noMoreChats && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    py: 4,
                  }}
                >
                  <Tooltip
                    title="No chats"
                    placement="right"
                    disableInteractive
                  >
                    <DataArrayRoundedIcon
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    />
                  </Tooltip>
                  {!condensed && (
                    <Typography
                      variant="h6"
                      sx={{
                        ml: 2,
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      No chats
                    </Typography>
                  )}
                </Box>
              )}
              {displayedChats.length > 0 &&
                displayedChats.map((chat) => (
                  <ChatCell key={chat.id} chat={chat} condensed={condensed} />
                ))}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
