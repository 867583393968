import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getClientStatusColor, getClientSummary } from "src/lib/client";
import { toggleHideClientChat } from "src/slices/chatSlice";
import { ClientEquipment } from "../ClientOverview/ClientEquipment";
import ClientTags from "../ClientOverview/ClientTags";
import ClientProfilePicture from "../misc/ClientProfilePicture";
import ClientCard from "./ClientCard";
import { ClientChips } from "./ClientChips";
import { ClientNotes } from "./ClientNotes";

export function ClientQuickDetailsColumn() {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const company = useAppSelector((state) => state.company.company);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (!client) {
    return null;
  }

  const clientSummary = getClientSummary(client.account);

  return (
    <Box sx={{ p: 1, pb: 16 }}>
      <Box
        sx={{ display: "flex", justifyContent: "center", position: "relative" }}
      >
        <IconButton
          size="small"
          onClick={() => {
            dispatch(toggleHideClientChat());
          }}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        >
          <ChatBubbleRoundedIcon fontSize="inherit" />
        </IconButton>
        <Box
          onMouseEnter={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          onMouseLeave={() => {
            setAnchorEl(null);
          }}
        >
          <ClientProfilePicture dimension={88} userId={client.user_id} />
        </Box>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h1">👋 {client.full_name}</Typography>
        {client.goal ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>{client.goal.name_custom}</Typography>
          </Box>
        ) : (
          <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
            No goal yet
          </Typography>
        )}
        {clientSummary.isSpecial && (
          <Typography
            variant="body2"
            sx={{
              color: (theme) => getClientStatusColor(client, theme),
            }}
          >
            {clientSummary.text}
          </Typography>
        )}
        <ClientChips sx={{ mb: 1, mt: 1 }} />
        <ClientTags userId={client.user_id} />
      </Box>
      <Typography variant="h2" sx={{ mb: 1 }}>
        Equipment
      </Typography>
      <ClientEquipment
        userId={client.user_id}
        defaultCollapsed
        sx={{ mb: 2 }}
      />
      <Typography variant="h2" sx={{ mb: 1 }}>
        Notes
      </Typography>
      <ClientNotes userId={client.user_id} />
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        disableRestoreFocus
        slotProps={{
          paper: {
            sx: {
              maxWidth: 650,
            },
          },
        }}
      >
        <ClientCard disableButtons />
      </Popover>
    </Box>
  );
}
