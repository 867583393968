import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { ClientNotes } from "../ClientPage/ClientNotes";

type Props = {
  userId: string;
  defaultExpanded?: boolean;
  condensed?: boolean;
};

export default function NotesPanel({
  userId,
  defaultExpanded = false,
  condensed,
}: Props) {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const client = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? {
            quick_notes: state.client.client.quick_notes,
            personal_notes: state.client.client.personal_notes,
            outcome_notes: state.client.client.outcome_notes,
            process_notes: state.client.client.process_notes,
            injuries_notes: state.client.client.injuries_notes,
            source_notes: state.client.client.source_notes,
            programming_notes: state.client.client.programming_notes,
            notes: state.client.client.notes,
          }
        : undefined,
    shallowEqual,
  );
  const [expanded, setExpanded] = useState(defaultExpanded);

  useHotkeys("n", () => {
    setExpanded(true);
    setTimeout(() => {
      document
        .getElementById("panel_notes")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 50);
  });

  if (!trainer || !trainer.note_templates || !client) {
    return null;
  }

  return (
    <Accordion
      id={"panel_notes"}
      disableGutters
      elevation={0}
      square
      expanded={condensed ? false : expanded}
      onChange={(event, expanded) => {
        setExpanded(expanded);
      }}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      sx={{
        "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
        "& .MuiAccordionDetails-root": { display: expanded ? "block" : "none" },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">🗒️{condensed ? "" : " Notes"}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0.5 }}>
        <div>
          <ClientNotes userId={userId} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
