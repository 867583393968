import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { ClientEquipment } from "./ClientEquipment";

type Props = {
  userId: string;
  defaultExpanded?: boolean;
  freeFloating?: boolean;
  condensed?: boolean;
};

export default function EquipmentPanel({
  userId,
  defaultExpanded = false,
  freeFloating = false,
  condensed,
}: Props) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  useHotkeys("e", () => {
    setExpanded(true);
    setTimeout(() => {
      document
        .getElementById("panel_equipment")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 50);
  });

  return (
    <Accordion
      id="panel_equipment"
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      expanded={condensed ? false : expanded}
      onChange={(event, expanded) => {
        setExpanded(expanded);
      }}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      sx={{
        "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
        "& .MuiAccordionDetails-root": { display: expanded ? "block" : "none" },
      }}
      variant={freeFloating ? "outlined" : undefined}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">🏋️{condensed ? "" : " Equipment"}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <ClientEquipment userId={userId} sx={{ px: 1 }} />
      </AccordionDetails>
    </Accordion>
  );
}
