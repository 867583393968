import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import type { TooltipProps } from "@mui/material";
import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { shallowEqual } from "react-redux";
import ExerciseMedia from "src/components/misc/ExerciseMedia";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getExerciseById, getExercisesByGroupId } from "src/lib/exercises";
import { getExerciseIconURL } from "src/lib/mediaUtility";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import {
  addToExerciseBlacklist,
  removeFromExerciseBlacklist,
} from "src/slices/clientSlice";
import { toggleFavoriteExercise } from "src/slices/trainerSlice";
import { DragPreview } from "../DragPreview";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[8],
    fontSize: 16,
    maxWidth: 800,
    p: 0,
  },
}));

type Props = PropsDraggable & {
  isDragging: boolean;
};

function ExerciseSelectCell({
  exerciseMasterID,
  exerciseGroupID,
  isDragging,
}: Props) {
  const dispatch = useAppDispatch();
  const isTemplate = useAppSelector((state) => state.workout.isTemplate);
  const latestSets = useAppSelector(
    (state) => state.client.client?.latest_sets,
    shallowEqual,
  );
  const progressExercisesSelected = useAppSelector(
    (state) => state.client.client?.progress_exercises_selected,
    shallowEqual,
  );
  const exerciseSource = getExerciseById(exerciseMasterID);
  const blacklist = useAppSelector(
    (state) => state.client.client?.blacklisted_exercises,
  );
  const trainer = useAppSelector((state) => state.trainer.trainer);
  const exerciseVariants = getExercisesByGroupId(
    exerciseSource?.picker_group_id ?? "",
  );
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const filteredExerciseVariants = exerciseVariants?.filter(
    (value, index, self) => {
      return (
        self.findIndex(
          (v) => v.key_readable_copilot === value.key_readable_copilot,
        ) === index
      );
    },
  );

  if (!exerciseSource) {
    return (
      <Typography>
        Loading: {exerciseMasterID}, {exerciseGroupID}
      </Typography>
    );
  }

  const lastUsedDate = latestSets
    ? workoutLib.exercises.getLastUsedDate({
        client: { latest_sets: latestSets },
        exerciseSourceId: exerciseMasterID,
      })
    : undefined;

  const mainContent = () => (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        borderBottom: 1,
        borderColor: "divider",
        display: "flex",
        px: 0.5,
        py: 0.75,
        alignItems: "center",
      }}
    >
      <Tooltip
        disableInteractive
        title={
          trainer?.favorite_exercise_ids?.includes(exerciseGroupID)
            ? "Remove from your favorites"
            : "Add to your favorites"
        }
      >
        <IconButton
          size="small"
          onClick={() => {
            dispatch(toggleFavoriteExercise(exerciseGroupID));
          }}
        >
          {trainer?.favorite_exercise_ids?.includes(exerciseGroupID) ? (
            <StarRoundedIcon fontSize="inherit" />
          ) : (
            <StarBorderRoundedIcon fontSize="inherit" />
          )}
        </IconButton>
      </Tooltip>
      <LightTooltip
        placement="right"
        disableInteractive
        title={
          <Box sx={{ p: 1, maxWidth: 600 }}>
            <Typography variant="h2" sx={{ textAlign: "center", mb: 2 }}>
              {exerciseGroupID}
            </Typography>
            <Grid container spacing={2}>
              {filteredExerciseVariants?.map((exercise) => (
                <Grid
                  item
                  xs={
                    filteredExerciseVariants.length === 1
                      ? 12
                      : filteredExerciseVariants.length === 2
                        ? 6
                        : 4
                  }
                  key={exercise.id}
                >
                  <Typography variant="h3" sx={{ textAlign: "center" }}>
                    {exercise.key_readable_copilot}
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <ExerciseMedia
                      exerciseMasterId={exercise.id}
                      width={200}
                      height={200}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        }
      >
        <img
          style={{
            borderRadius: "4px",
          }}
          src={getExerciseIconURL(exerciseMasterID) ?? ""}
          alt={exerciseMasterID}
          width={30}
          height={30}
        />
      </LightTooltip>
      <Box sx={{ ml: 0.5 }}>
        <Typography
          sx={{
            color: (theme) =>
              !isTemplate && blacklist && blacklist.includes(exerciseMasterID)
                ? theme.palette.error.main
                : theme.palette.text.primary,
          }}
        >
          {progressExercisesSelected?.includes(exerciseSource.id) ? "📈 " : ""}
          {exerciseGroupID}
        </Typography>
        {!isTemplate && lastUsedDate && (
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontSize: "12px",
            }}
            variant="body2"
          >
            Used {formatDistanceToNow(lastUsedDate)} ago
          </Typography>
        )}
      </Box>
    </Box>
  );

  const content = () => {
    if (!isTemplate) {
      return (
        <div
          onContextMenu={(event) => {
            event.preventDefault();
            setContextMenu(
              contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                  }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null,
            );
          }}
        >
          {mainContent()}
          <Menu
            open={contextMenu !== null}
            onClose={() => {
              setContextMenu(null);
            }}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            {blacklist && blacklist.includes(exerciseMasterID) ? (
              <MenuItem
                onClick={() => {
                  dispatch(
                    removeFromExerciseBlacklist(
                      exerciseVariants?.map((variant) => variant.id) ?? [],
                    ),
                  );
                  setContextMenu(null);
                }}
              >
                <ListItemIcon>
                  <RemoveIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Remove all from blacklist</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  dispatch(
                    addToExerciseBlacklist(
                      exerciseVariants?.map((variant) => variant.id) ?? [],
                    ),
                  );
                  setContextMenu(null);
                }}
              >
                <ListItemIcon>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Add all to blacklist</ListItemText>
              </MenuItem>
            )}
          </Menu>
        </div>
      );
    } else {
      return mainContent();
    }
  };

  return content();
}

type PropsDraggable = {
  exerciseMasterID: string;
  exerciseGroupID: string;
};

function ExerciseSelectCellDraggable(props: PropsDraggable) {
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );

  const exerciseSource = getExerciseById(props.exerciseMasterID);

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "exercise_source",
      exerciseSourceId: props.exerciseMasterID,
      exerciseGroupId: props.exerciseGroupID,
    };

    return draggable({
      element: element,
      getInitialData: () => data,
      onDragStart: () => setDragging(true),
      onDrop: () => setDragging(false),
      onGenerateDragPreview({ nativeSetDragImage }) {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          getOffset: pointerOutsideOfPreview({
            x: "16px",
            y: "8px",
          }),
          render({ container }) {
            setPreviewContainer(container);
          },
        });
      },
    });
  }, [props.exerciseMasterID, props.exerciseGroupID]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div ref={ref}>
          <ExerciseSelectCell isDragging={dragging} {...props} />
        </div>
      </div>
      {previewContainer
        ? createPortal(
            <DragPreview
              text={exerciseSource?.picker_group_id ?? "Exercise source"}
            />,
            previewContainer,
          )
        : null}
    </>
  );
}

export default ExerciseSelectCellDraggable;
