import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded";
import {
  Box,
  Button,
  Paper,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import { LineChart } from "@mui/x-charts";
import type { CoachMetricTemplateSubmodule } from "@trainwell/types";
import { addDays } from "date-fns";
import React, { useState } from "react";
import type Metric from "src/interfaces/Metric";
import {
  calculateNetChange,
  formatMetrics,
  frames,
  queryMetric,
} from "src/lib/metrics";
import { round } from "src/lib/misc";

type Props = {
  allMetrics: Metric[];
  allCompanyMetrics: Metric[];
  submodule: CoachMetricTemplateSubmodule;
  window: number;
  trainerName: string;
};

export default function Trend({
  allMetrics,
  allCompanyMetrics,
  submodule,
  window,
  trainerName,
}: Props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [frame, setFrame] = useState(frames[0]);

  const open = Boolean(anchorEl);

  const trendMetrics = allMetrics.map((obj: any) => {
    let metric = queryMetric(obj, submodule.metric, true);
    metric = submodule.per_day ? metric / window : metric;
    metric = round(metric, submodule.precision);
    return {
      date: obj.date,
      metric: metric,
    };
  });

  const companyTrendMetrics = allCompanyMetrics.map((obj: any) => {
    let metric = queryMetric(obj, submodule.metric, true);
    metric = submodule.per_day ? metric / window : metric;
    metric = round(metric, submodule.precision);
    return {
      date: obj.date,
      metric: metric,
    };
  });

  const filteredTrendMetrics = formatMetrics(trendMetrics, frame);
  const filteredCompanyTrendMetrics = formatMetrics(companyTrendMetrics, frame);

  // Create maps of dates to metrics
  const trendMetricsMap = new Map(
    filteredTrendMetrics.map((metric) => [metric.date, metric.metric]),
  );
  const companyMetricsMap = new Map(
    filteredCompanyTrendMetrics.map((metric) => [metric.date, metric.metric]),
  );

  // Create a set of all unique dates
  const allDates = new Set([
    ...filteredTrendMetrics.map((metric) => metric.date),
    ...filteredCompanyTrendMetrics.map((metric) => metric.date),
  ]);

  // Combine the two arrays into a single array of metrics
  const chartData: {
    date: Date;
    trendMetric: number | null;
    companyMetric: number | null;
  }[] = Array.from(allDates)
    .map((date) => ({
      date: addDays(date, 6),
      trendMetric: trendMetricsMap.get(date) || null, // Default to null if no matching trend metric is found
      companyMetric: companyMetricsMap.get(date) || null, // Default to null if no matching company metric is found
    }))
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  const trendMetricsMin = Math.min(
    ...filteredTrendMetrics.map((m) => m.metric),
  );
  const companyTrendMetricsMin = Math.min(
    ...filteredCompanyTrendMetrics.map((m) => m.metric),
  );

  const { difference, differencePercent } =
    calculateNetChange(filteredTrendMetrics);

  const displayMetric = (metrics: Metric[]) => {
    let metric;
    if (metrics.length === 0) {
      metric = 0;
    } else {
      metric = round(metrics[metrics.length - 1].metric, 1);
    }

    return (
      <React.Fragment>
        {submodule.is_percent ? (
          <Typography sx={{ fontWeight: "bold", fontSize: 18, mb: 1 }}>
            {metric}%
          </Typography>
        ) : (
          <Typography sx={{ fontWeight: "bold", fontSize: 18, mb: 1 }}>
            {metric}
          </Typography>
        )}
      </React.Fragment>
    );
  };

  let trend: "neutral" | "bad" | "good" = "neutral";
  let chartColor = theme.palette.text.secondary;

  if (submodule.rank_low_to_high === null || difference === 0) {
    chartColor = theme.palette.text.secondary;
    trend = "neutral";
  } else if (
    (difference > 0 && submodule.rank_low_to_high) ||
    (difference < 0 && !submodule.rank_low_to_high)
  ) {
    chartColor = theme.palette.success.main;
    trend = "good";
  } else if (
    (difference < 0 && submodule.rank_low_to_high) ||
    (difference > 0 && !submodule.rank_low_to_high)
  ) {
    chartColor = theme.palette.error.main;
    trend = "bad";
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        margin: "0 2px 0 2px",
        borderRadius: "4px",
        color: (theme) =>
          trend === "bad"
            ? theme.palette.error.contrastText
            : trend === "good"
              ? theme.palette.success.contrastText
              : undefined,
        backgroundColor: (theme) =>
          trend === "bad"
            ? theme.palette.error.light
            : trend === "good"
              ? theme.palette.success.light
              : theme.palette.backgroundSecondary.main,
      }}
      aria-haspopup="true"
      onMouseEnter={(event) => {
        setFrame("Past Month");
        setAnchorEl(event.currentTarget);
      }}
      onMouseLeave={() => {
        setAnchorEl(null);
      }}
    >
      <ShowChartRoundedIcon style={{ fontSize: "18px" }} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        sx={{
          pointerEvents: "none",
        }}
        PaperProps={{
          sx: {
            pointerEvents: "auto",
          },
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper sx={{ maxHeight: "500px", padding: 1 }}>
          <div
            style={{
              marginTop: "8px",
              textAlign: "center",
            }}
          >
            <Typography variant="h3" sx={{ mb: 1 }}>
              {submodule.metric_title}
            </Typography>
            {displayMetric(filteredTrendMetrics)}
            <div
              style={{
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography style={{ fontSize: "18px" }}>
                <Typography
                  sx={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    justifyContent: "center",
                    alignItems: "center",
                    color: (theme) =>
                      trend === "bad"
                        ? theme.palette.error.main
                        : trend === "good"
                          ? theme.palette.success.main
                          : undefined,
                    mr: 2,
                  }}
                >
                  {difference > 0 ? (
                    <ArrowDropUpRoundedIcon />
                  ) : difference < 0 ? (
                    <ArrowDropDownRoundedIcon />
                  ) : (
                    "-"
                  )}
                  &nbsp;{" "}
                  {submodule.is_percent
                    ? Math.abs(difference) + "%"
                    : Math.abs(difference) + " (+" + differencePercent + "%)"}
                </Typography>
              </Typography>
              <Button
                onClick={() => {
                  if (frame === frames[0]) {
                    setFrame(frames[1]);
                  } else if (frame === frames[1]) {
                    setFrame(frames[2]);
                  } else if (frame === frames[2]) {
                    setFrame(frames[0]);
                  }
                }}
                style={{ textTransform: "none" }}
              >
                {frame}
              </Button>
            </div>
          </div>
          <LineChart
            colors={[chartColor]}
            xAxis={[
              {
                scaleType: "utc",
                dataKey: "date",
              },
            ]}
            yAxis={[
              {
                min:
                  trendMetricsMin > companyTrendMetricsMin
                    ? companyTrendMetricsMin
                    : trendMetricsMin,
                valueFormatter: (value) => {
                  if (submodule.is_percent) {
                    return value + "%";
                  } else {
                    return value.toString();
                  }
                },
              },
            ]}
            series={[
              {
                area: true,
                dataKey: "trendMetric",
                label: trainerName === "tw" ? "trainwell" : trainerName,
                showMark: false,
                curve: "linear",
              },
              {
                dataKey: "companyMetric",
                showMark: false,
                label: "trainwell",
                color: theme.palette.text.secondary,
                id: "company",
                curve: "linear",
              },
            ]}
            dataset={chartData}
            height={300}
            width={500}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
            skipAnimation
            sx={{
              ".MuiLineElement-series-company": {
                strokeDasharray: "5",
              },
            }}
          />
        </Paper>
      </Popover>
    </Box>
  );
}
