import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CalendarViewWeekRoundedIcon from "@mui/icons-material/CalendarViewWeekRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  alpha,
} from "@mui/material";
import type { Phase } from "@trainwell/types";
import Fuse from "fuse.js";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { shallowEqual } from "react-redux";
import SearchField from "src/components/misc/SearchField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { logClick } from "src/slices/analyticsSlice";
import {
  closeTemplateLibrary,
  openTemplateLibraryForUserId,
} from "src/slices/phaseTemplatesSlice";
import {
  createWorkout,
  selectCurrentPhaseIds,
  setPhaseEditing,
} from "src/slices/phasesSlice";
import PhaseCell from "./PhaseCell";
import WorkoutCell from "./WorkoutCell";

const phaseFuse = new Fuse<Phase>([], {
  includeScore: true,
  keys: ["name", "tags"],
});

export default function PhaseWorkoutPanelDroppable() {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "phase_workout_panel" }),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      canDrop({ source }) {
        return source.data.type === "phase_workout";
      },
    });
  }, []);

  return (
    <div ref={ref}>
      <PhaseWorkoutPanel isDraggedOver={isDraggedOver} />
    </div>
  );
}

type Props = {
  isDraggedOver: boolean;
};

function PhaseWorkoutPanel({ isDraggedOver }: Props) {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const phases = useAppSelector((state) => state.phases.phases);
  const userId = useAppSelector((state) => state.client.client!.user_id);
  const currentPhaseIds = useAppSelector(selectCurrentPhaseIds, shallowEqual);
  const templateLibraryOpen = useAppSelector((state) =>
    Boolean(state.phaseTemplates.openForUserId),
  );
  const clientProgramDayCount = useAppSelector(
    (state) =>
      state.client.client?.workout_times?.filter((t) => t.has_time).length ?? 0,
  );
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const menuOpen = Boolean(menuAnchorEl);

  useHotkeys("l", () => {
    if (templateLibraryOpen) {
      dispatch(closeTemplateLibrary());
    } else {
      dispatch(openTemplateLibraryForUserId(userId));
    }
  });
  useHotkeys("esc", () => {
    dispatch(closeTemplateLibrary());
  });

  const filteredPhases = useMemo(() => {
    const phasesCopy = [...phases].filter((phase) => !phase.deleted);

    if (search !== "") {
      const fusedItems = phaseFuse.search(search.toLowerCase());

      return fusedItems.map((item) => item.item);
    } else {
      return phasesCopy.sort((a, b) => {
        if (a.date_created > b.date_created) {
          return -1;
        } else if (a.date_created < b.date_created) {
          return 1;
        }

        return 0;
      });
    }
  }, [search, phases]);

  const currentPhases = filteredPhases.filter(
    (p) => currentPhaseIds.includes(p._id) && !p.date_deleted,
  );

  const nonWorkoutPhases = filteredPhases.filter(
    (p) => p.type === "multiple" && !p.date_deleted,
  );

  const otherPhases = filteredPhases.filter(
    (p) =>
      !currentPhaseIds.includes(p._id) &&
      p.type !== "multiple" &&
      !p.date_deleted,
  );

  const deletedPhases = filteredPhases.filter((p) => p.date_deleted);

  useEffect(() => {
    phaseFuse.setCollection(phases.filter((p) => !p.date_deleted));
  }, [phases]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundColor: (theme) =>
          isDraggedOver
            ? alpha(theme.palette.primary.main, 0.1)
            : theme.palette.background.paper,
        transition: "background-color 0.2s ease",
      }}
    >
      <Box
        sx={{
          px: 1,
          pt: 1,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <SearchField
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
          onClear={() => {
            setSearch("");
          }}
          name="workoutPanel.search"
        />
      </Box>
      <Box
        sx={{
          p: 1,
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        {!templateLibraryOpen ? (
          <ButtonGroup variant="contained" fullWidth disableElevation>
            <Button
              // fullWidth
              // variant="contained"
              startIcon={<AddRoundedIcon />}
              onClick={(event) => {
                setMenuAnchorEl(event.currentTarget);

                dispatch(
                  logClick({
                    elementName: "client.new_phase_or_workout",
                  }),
                );
              }}
            >
              New
            </Button>
            <Button
              size="small"
              onClick={() => {
                setMenuAnchorEl(null);

                dispatch(openTemplateLibraryForUserId(userId));

                dispatch(
                  logClick({
                    elementName: "client.open_template_library",
                  }),
                );
              }}
              sx={{ flex: 1 }}
            >
              <ExtensionRoundedIcon fontSize="inherit" />
            </Button>
          </ButtonGroup>
        ) : (
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              dispatch(closeTemplateLibrary());

              dispatch(
                logClick({
                  elementName: "client.close_template_library",
                }),
              );
            }}
            startIcon={<CloseRoundedIcon />}
          >
            Close template library
          </Button>
        )}
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
        {search !== "" ? (
          filteredPhases.map((phase) => {
            if (phase.type === "single") {
              return <WorkoutCell key={phase._id} phaseId={phase._id} />;
            } else {
              return <PhaseCell key={phase._id} phaseId={phase._id} />;
            }
          })
        ) : (
          <>
            <Accordion
              disableGutters
              elevation={0}
              square
              defaultExpanded
              sx={{ backgroundColor: "transparent" }}
              slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreRoundedIcon />}
                sx={{
                  borderTop: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  backgroundColor: (theme) =>
                    isDraggedOver
                      ? alpha(theme.palette.primary.main, 0.2)
                      : theme.palette.backgroundSecondary.main,
                  transition: "background-color 0.2s ease",
                  minHeight: 40,
                  "& .MuiAccordionSummary-content": {
                    my: 0.5,
                  },
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {nonWorkoutPhases.length} Phases
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                {nonWorkoutPhases.length
                  ? nonWorkoutPhases.map((phase) => {
                      if (phase.type === "single") {
                        return (
                          <WorkoutCell key={phase._id} phaseId={phase._id} />
                        );
                      } else {
                        return (
                          <PhaseCell key={phase._id} phaseId={phase._id} />
                        );
                      }
                    })
                  : null}
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              elevation={0}
              square
              defaultExpanded
              sx={{ backgroundColor: "transparent" }}
              slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreRoundedIcon />}
                sx={{
                  borderTop: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  backgroundColor: (theme) =>
                    isDraggedOver
                      ? alpha(theme.palette.primary.main, 0.2)
                      : theme.palette.backgroundSecondary.main,
                  transition: "background-color 0.2s ease",
                  minHeight: 40,
                  "& .MuiAccordionSummary-content": {
                    my: 0.5,
                  },
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {currentPhases.length} Current workouts
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                {currentPhases.length
                  ? currentPhases.map((phase) => {
                      if (phase.type === "single") {
                        return (
                          <WorkoutCell key={phase._id} phaseId={phase._id} />
                        );
                      } else {
                        return (
                          <PhaseCell key={phase._id} phaseId={phase._id} />
                        );
                      }
                    })
                  : null}
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              elevation={0}
              square
              slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
              sx={{ backgroundColor: "transparent" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreRoundedIcon />}
                sx={{
                  borderTop: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  backgroundColor: (theme) =>
                    isDraggedOver
                      ? alpha(theme.palette.primary.main, 0.2)
                      : theme.palette.backgroundSecondary.main,
                  transition: "background-color 0.2s ease",
                  minHeight: 40,
                  "& .MuiAccordionSummary-content": {
                    my: 0.5,
                  },
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {otherPhases.length} Other workouts
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                {otherPhases.length
                  ? otherPhases.map((phase) => {
                      if (phase.type === "single") {
                        return (
                          <WorkoutCell key={phase._id} phaseId={phase._id} />
                        );
                      } else {
                        return (
                          <PhaseCell key={phase._id} phaseId={phase._id} />
                        );
                      }
                    })
                  : null}
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              elevation={0}
              square
              slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
              sx={{ backgroundColor: "transparent" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreRoundedIcon />}
                sx={{
                  borderTop: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  backgroundColor: (theme) =>
                    isDraggedOver
                      ? alpha(theme.palette.primary.main, 0.2)
                      : theme.palette.backgroundSecondary.main,
                  transition: "background-color 0.2s ease",
                  minHeight: 40,
                  "& .MuiAccordionSummary-content": {
                    my: 0.5,
                  },
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {deletedPhases.length} Deleted
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                {deletedPhases.length
                  ? deletedPhases.map((phase) => {
                      if (phase.type === "single") {
                        return (
                          <WorkoutCell key={phase._id} phaseId={phase._id} />
                        );
                      } else {
                        return (
                          <PhaseCell key={phase._id} phaseId={phase._id} />
                        );
                      }
                    })
                  : null}
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>
      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setMenuAnchorEl(null);

            dispatch(createWorkout({ userId: userId }));

            dispatch(
              logClick({
                elementName: "client.new_workout",
              }),
            );
          }}
        >
          <ListItemIcon>
            <Avatar
              sx={{
                backgroundColor: (theme) => theme.palette.blue.main,
                width: 20,
                height: 20,
                borderRadius: "6px",
              }}
            >
              <FitnessCenterRoundedIcon
                sx={{
                  fontSize: 16,
                }}
              />
            </Avatar>
          </ListItemIcon>
          <ListItemText primary={"Workout"} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuAnchorEl(null);

            const defaultDays = Array.from(
              Array(clientProgramDayCount).keys(),
            ).map(() => ({
              draggable_id: crypto.randomUUID(),
              workouts: [],
            }));

            dispatch(
              setPhaseEditing({
                _id: null,
                date_created: new Date().toISOString(),
                date_updated: new Date().toISOString(),
                date_deleted: null,
                workout_ids: [],
                days_draggable: defaultDays,
                tags: [],
                name: "New phase",
                type: "multiple",
                user_id: userId,
              }),
            );

            dispatch(
              logClick({
                elementName: "client.new_phase",
              }),
            );
          }}
        >
          <ListItemIcon>
            <Avatar
              sx={{
                backgroundColor: (theme) => theme.palette.success.main,
                width: 20,
                height: 20,
                borderRadius: "6px",
              }}
            >
              <CalendarViewWeekRoundedIcon
                sx={{
                  fontSize: 16,
                }}
              />
            </Avatar>
          </ListItemIcon>
          <ListItemText primary={"Phase"} />
        </MenuItem>
      </Menu>
    </Box>
  );
}
