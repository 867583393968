import DirectionsRunRoundedIcon from "@mui/icons-material/DirectionsRunRounded";
import { alpha, Avatar, Box, CardActionArea, Typography } from "@mui/material";
import type { MessageWorkoutMissed } from "@trainwell/types";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectWorkoutById } from "src/slices/phasesSlice";

type Props = {
  message: MessageWorkoutMissed;
  combineBottom: boolean;
};

export default function WorkoutMissedMessage({
  message,
  combineBottom,
}: Props) {
  const chatVisualState = useAppSelector((state) => state.chat.chatMode);
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, message.content.workout_id),
  );

  return (
    <CardActionArea
      href={
        chatVisualState === "full"
          ? ""
          : `/clients/${message.from_id}/workouts/${message.content.workout_id}`
      }
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
        overflow: "hidden",
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.5),
        p: 1,
        display: "flex",
        alignItems: "center",
        border: 2,
        borderColor: "divider",
        borderStyle: "dashed",
        width: "fit-content",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          sx={{
            border: 1,
            borderColor: "#a3e635",
            backgroundColor: "transparent",
            mr: 1,
            width: 20,
            height: 20,
            borderRadius: "6px",
          }}
        >
          <DirectionsRunRoundedIcon
            sx={{
              fontSize: 16,
              color: (theme) => theme.palette.text.disabled,
            }}
          />
        </Avatar>
        <Box>
          <Typography
            variant="h6"
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {workout?.name ?? "Unknown"}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            Missed
          </Typography>
        </Box>
      </Box>
    </CardActionArea>
  );
}
