import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Popover,
  TextField,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import type { Client } from "@trainwell/types";
import { formatDistanceToNow } from "date-fns";
import { useState } from "react";
import { useAppDispatch } from "src/hooks/stateHooks";
import { updateClient } from "src/slices/clientSlice";
import { CompletedRestDayIcon } from "./CompletedRestDayIcon";
import { CompletedWorkoutIcon } from "./CompletedWorkoutIcon";
import { StreakBrokenIcon } from "./StreakBrokenIcon";
import { StreakFrozenIcon } from "./StreakFrozenIcon";
import { StreakInDangerIcon } from "./StreakInDangerIcon";
import { UpcomingRestDayIcon } from "./UpcomingRestDayIcon";
import { UpcomingWorkoutIcon } from "./UpcomingWorkoutIcon";

type Props = {
  client: Client;
  sx?: SxProps<Theme>;
};

export function ClientStreak({ client, sx = [] }: Props) {
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [streak, setStreak] = useState(client.lifetime_stats.streak ?? 0);
  const [streakFreezes, setStreakFreezes] = useState(
    client.lifetime_stats.streak_freezes ?? 0,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const hasMissedStreak =
    client?.last_login_date && (client.missed_workout_day_streak ?? 0) > 0;
  const hasWorkoutStreak =
    client?.last_login_date && (client.lifetime_stats.streak ?? 0) > 0;
  const clientHasAnyStreak = hasMissedStreak || hasWorkoutStreak;

  const streakLength =
    (hasMissedStreak
      ? client.missed_workout_day_streak
      : client.lifetime_stats.streak) ?? 0;

  function cancelEditing() {
    setIsEditing(false);

    setStreak(client.lifetime_stats.streak ?? 0);
    setStreakFreezes(client.lifetime_stats.streak_freezes ?? 0);
  }

  return (
    <Box sx={sx}>
      <Chip
        size="small"
        label={
          !clientHasAnyStreak
            ? "no streak"
            : `${hasMissedStreak ? "😢" : "🔥"} ${streakLength} day${
                streakLength > 1 ? "s" : ""
              }`
        }
        variant="outlined"
        sx={{
          backgroundColor: (theme) =>
            hasMissedStreak
              ? theme.palette.warningSurface.main
              : hasWorkoutStreak
                ? theme.palette.successSurface.main
                : theme.palette.background.paper,
          // background: (theme) =>
          //   clientHasAnyStreak
          //     ? `linear-gradient(90deg, ${hasMissedStreak ? theme.palette.warningSurface.main : theme.palette.successSurface.main} 0%, ${hasMissedStreak ? theme.palette.warningSurface.main : theme.palette.successSurface.main} ${hasMissedStreak ? (100 - (workoutDaysCompletedPercent ?? 0)).toString() : (workoutDaysCompletedPercent ?? 0).toString()}%, ${theme.palette.background.paper} 100%)`
          //     : undefined,
          borderColor: (theme) =>
            hasMissedStreak
              ? theme.palette.warning.main
              : hasWorkoutStreak
                ? theme.palette.success.main
                : theme.palette.divider,
        }}
        onClick={(event) => {
          event.stopPropagation();

          setAnchorEl(event.currentTarget);
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);

          cancelEditing();
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: { p: 1, width: 350 },
          },
        }}
        onBackdropClick={(event) => {
          event.stopPropagation();
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
        onFocus={(event) => {
          event.stopPropagation();
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4.5}>
            {isEditing ? (
              <TextField
                size="small"
                label="Streak"
                value={streak}
                onChange={(event) => {
                  setStreak(Number(event.target.value));
                }}
              />
            ) : (
              <>
                <Typography variant="overline">Current streak</Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  🔥 {client.lifetime_stats.streak ?? 0} day
                  {client.lifetime_stats.streak === 0 ||
                  client.lifetime_stats.streak > 1
                    ? "s"
                    : ""}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={4.5}>
            {isEditing ? (
              <TextField
                size="small"
                label="Streak freezes"
                value={streakFreezes}
                onChange={(event) => {
                  setStreakFreezes(
                    Math.max(Math.min(Number(event.target.value), 2), 0),
                  );
                }}
              />
            ) : (
              <>
                <Typography variant="overline">Streak freezes</Typography>
                <Box sx={{ display: "flex" }}>
                  {[0, 1].map((index) => (
                    <StreakFrozenIcon
                      key={index}
                      sx={{ mr: 0.75 }}
                      hasNoFreeze={
                        client.lifetime_stats.streak_freezes < index + 1
                      }
                    />
                  ))}
                </Box>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            {isEditing ? (
              <Button
                size="small"
                variant="text"
                startIcon={<SaveRoundedIcon />}
                onClick={() => {
                  dispatch(
                    updateClient({
                      user_id: client.user_id,
                      // @ts-expect-error
                      "lifetime_stats.streak": streak,
                      "lifetime_stats.streak_freezes": streakFreezes,
                    }),
                  );

                  setIsEditing(false);
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                size="small"
                variant="text"
                startIcon={<EditRoundedIcon />}
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                Edit
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            {(client.lifetime_stats.streak_previous ?? 0) > 0 && (
              <>
                <Typography variant="overline">Previous streak</Typography>
                <Typography>
                  <b>
                    {client.lifetime_stats.streak_previous ?? 0} day
                    {client.lifetime_stats.streak_previous === 0 ||
                    client.lifetime_stats.streak_previous > 1
                      ? "s"
                      : ""}
                  </b>
                  {client.lifetime_stats.streak_previous_date ? (
                    <Typography
                      component={"span"}
                      variant="body2"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      , reset{" "}
                      {formatDistanceToNow(
                        client.lifetime_stats.streak_previous_date,
                      )}{" "}
                      ago
                    </Typography>
                  ) : (
                    ""
                  )}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Typography variant="h2" sx={{ mt: 2 }}>
          How streaks work
        </Typography>
        <Typography>
          Your streak is the number of days you&apos;ve gone without missing a
          workout day (a day with a scheduled trainwell workout).
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CompletedWorkoutIcon sx={{ mr: 0.75 }} />
          <Typography fontWeight={"bold"}>Completed workout</Typography>
          <UpcomingWorkoutIcon
            sx={{
              ml: 2,
              mr: 0.75,
            }}
          />
          <Typography fontWeight={"bold"}>Upcoming workout</Typography>
        </Box>
        <Typography>
          On workout days, you can complete any trainwell workout (including
          extras) to keep your streak alive.
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <StreakInDangerIcon sx={{ mr: 0.75 }} />
          <Typography fontWeight={"bold"}>Streak in danger</Typography>
        </Box>
        <Typography>
          If a workout day is ending and you haven&apos;t done any trainwell
          workout, you&apos;ll receive a reminder to save your streak.
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <StreakFrozenIcon
            sx={{
              mr: 0.75,
            }}
          />
          <Typography fontWeight={"bold"}>Streak freezes</Typography>
        </Box>
        <Typography>
          If you don&apos;t do any trainwell workout on a workout day one of
          your streak freezes will be used to keep your streak.
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <StreakBrokenIcon
            sx={{
              mr: 0.75,
            }}
          />
          <Typography fontWeight={"bold"}>Streak broken</Typography>
        </Box>
        <Typography>
          If you don&apos;t do any trainwell workout on a workout day and you
          have no more streak freezes, you&apos;ll break your streak.
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CompletedRestDayIcon sx={{ mr: 0.75 }} />
          <Typography fontWeight={"bold"}>Completed rest</Typography>
          <UpcomingRestDayIcon
            sx={{
              ml: 2,
              mr: 0.75,
            }}
          />
          <Typography fontWeight={"bold"}>Upcoming rest</Typography>
        </Box>
        <Typography>Enjoy your rest days in between workout days.</Typography>
      </Popover>
    </Box>
  );
}
