import {
  SortableContext,
  defaultAnimateLayoutChanges,
  useSortable,
  verticalListSortingStrategy,
  type AnimateLayoutChanges,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Box,
  Card,
  IconButton,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { useMemo } from "react";
import type { DayDraggable } from "src/slices/phaseTemplatesSlice";
import { WorkoutTemplateDraggable } from "./WorkoutTemplateDraggable";

type Props = {
  dayIndex: number;
  day: DayDraggable;
  newDay?: boolean;
  onDeleteWorkoutTemplate: (workoutTemplateId: string) => void;
  onCopyWorkoutTemplate: (workoutTemplateId: string) => void;
  onDelete: () => void;
  disabled?: boolean;
};

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export default function DayDroppable({
  dayIndex,
  day,
  newDay,
  onDeleteWorkoutTemplate,
  onCopyWorkoutTemplate,
  onDelete,
  disabled,
}: Props) {
  const {
    attributes,
    isDragging,
    listeners,
    isOver,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id: day.draggable_id,
    disabled: disabled,
    data: {
      type: "phase_template_day",
      children: day.templates.map((template) => template.draggable_id),
    },
    animateLayoutChanges,
  });

  const itemIds = useMemo(
    () => day.templates.map((template) => template.draggable_id),
    [day.templates],
  );

  return (
    <div
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.4 : undefined,
      }}
    >
      {newDay ? (
        <Card
          ref={setNodeRef}
          variant="outlined"
          sx={{
            flex: 1,
            borderStyle: "dashed",
            borderWidth: "2px",
            borderColor: (theme) =>
              isOver ? theme.palette.primary.main : undefined,
            backgroundColor: (theme) =>
              isOver
                ? alpha(theme.palette.primary.main, 0.2)
                : theme.palette.background.paper,
            transition: "background-color 0.2s ease, border-color 0.2s ease",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              p: 1,
            }}
          >
            <AddRoundedIcon
              sx={{
                color: (theme) =>
                  isOver
                    ? theme.palette.primary.main
                    : theme.palette.text.secondary,
                transition: "color 0.2s ease",
              }}
            />
            <Typography variant="overline">Drop to add new day</Typography>
          </Box>
        </Card>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 0.5,
            }}
          >
            <Typography variant="body2">
              {
                [
                  "A",
                  "B",
                  "C",
                  "D",
                  "E",
                  "F",
                  "G",
                  "H",
                  "I",
                  "J",
                  "K",
                  "L",
                  "M",
                  "N",
                ][dayIndex]
              }{" "}
              day
            </Typography>
            {!disabled && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip
                  title={day.templates.length > 0 ? "Day must be empty" : ""}
                >
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => {
                        onDelete();
                      }}
                      color="error"
                      disabled={day.templates.length > 0}
                    >
                      <RemoveCircleRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </span>
                </Tooltip>
                <IconButton size="small" {...attributes} {...listeners}>
                  <DragIndicatorRoundedIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
          <Card
            variant="outlined"
            ref={setNodeRef}
            sx={{
              flex: 1,
              borderStyle: day.templates.length > 0 ? "solid" : "dashed",
              borderWidth: day.templates.length > 0 ? "1px" : "2px",
              // borderRadius: 1,
              borderColor: (theme) =>
                isOver ? theme.palette.primary.main : undefined,
              backgroundColor: (theme) =>
                isOver
                  ? alpha(theme.palette.primary.main, 0.2)
                  : theme.palette.background.paper,
              transition: "background-color 0.2s ease, border-color 0.2s ease",
              width: "100%",
            }}
          >
            <SortableContext
              items={itemIds}
              strategy={verticalListSortingStrategy}
            >
              {day.templates.map((template, workoutTemplateIndex) => (
                <WorkoutTemplateDraggable
                  key={template.draggable_id}
                  workoutTemplateId={template.template_id}
                  draggableId={template.draggable_id}
                  isLast={workoutTemplateIndex === day.templates.length - 1}
                  onDelete={() => {
                    onDeleteWorkoutTemplate(template.template_id);
                  }}
                  onCopy={() => {
                    onCopyWorkoutTemplate(template.template_id);
                  }}
                  disabled={disabled}
                  dayIndex={dayIndex}
                />
              ))}
              {day.templates.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    p: 1,
                  }}
                >
                  <AddRoundedIcon
                    sx={{
                      color: (theme) =>
                        isOver
                          ? theme.palette.primary.main
                          : theme.palette.text.secondary,
                      transition: "color 0.2s ease",
                    }}
                  />
                  <Typography variant="overline">
                    Drag templates here
                  </Typography>
                </Box>
              )}
            </SortableContext>
          </Card>
        </>
      )}
    </div>
  );
}
