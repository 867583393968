import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import {
  Box,
  Button,
  CardActionArea,
  IconButton,
  Typography,
} from "@mui/material";
import type { HabitTemplate } from "@trainwell/types";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import HabitDot from "src/components/misc/HabitDot";
import { DragPreview } from "src/components/WorkoutBuilderPage/DragPreview";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import { isProgramHabit } from "src/lib/habits";
import {
  duplicateHabitTemplate,
  removeHabitTemplate,
  restoreHabitTemplate,
} from "src/slices/clientSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import HabitEditDialog from "./HabitEditDialog";

type Props = {
  habit: HabitTemplate;
  index: number;
};

export default function HabitCell({ habit, index }: Props) {
  const dispatch = useAppDispatch();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const trainer = useAppSelector(selectPrimaryTrainer);
  // Dnd stuff
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "habit_week_source",
      id: habit.id,
      habit_type: isCoachTemplate || isTrainwellTemplate ? "template" : "habit",
    };

    return draggable({
      element: element,
      getInitialData: () => data,
      onDragStart: () => setDragging(true),
      onDrop: () => setDragging(false),
      onGenerateDragPreview({ nativeSetDragImage }) {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          getOffset: pointerOutsideOfPreview({
            x: "16px",
            y: "8px",
          }),
          render({ container }) {
            setPreviewContainer(container);
          },
        });
      },
    });
  }, [habit.id]);

  const isCoachTemplate = habit.trainer_id && !habit.user_id;
  const isTrainwellTemplate = !habit.trainer_id && !habit.user_id;

  const canEditGlobalHabits = trainerHasAccess(
    trainer?.access_roles,
    "global_habits",
  );

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div ref={ref}>
          <CardActionArea
            disabled={
              !(habit.user_id || habit.trainer_id) && !canEditGlobalHabits
            }
            onClick={() => {
              setIsEditDialogOpen(true);
            }}
            onContextMenu={(event) => {
              event.preventDefault();
              setIsEditDialogOpen(true);
            }}
          >
            <Box
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: (theme) => theme.palette.background.paper,
                opacity: dragging ? 0.5 : 1,
              }}
            >
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {habit.trainer_id && (
                    <LocalLibraryRoundedIcon
                      sx={{
                        fontSize: 14,
                        mr: 0.5,
                        color: (theme) => theme.palette.text.secondary,
                        // color: (theme) => getHabitColor(habit.type, theme)
                      }}
                    />
                  )}
                  {!habit.trainer_id && !habit.user_id && (
                    <LanguageRoundedIcon
                      sx={{
                        fontSize: 14,
                        mr: 0.5,
                        color: (theme) => theme.palette.text.secondary,
                        // color: (theme) => getHabitColor(habit.type, theme)
                      }}
                    />
                  )}
                  <HabitDot
                    type={habit.type}
                    complete
                    size="small"
                    sx={{ mr: 1, flexShrink: 0 }}
                  />
                  <Typography>{habit.name}</Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {habit.notes_coach_default}
                </Typography>
              </Box>
              {!isProgramHabit(habit) && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {(habit.user_id || habit.trainer_id) && (
                    <IconButton
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        dispatch(
                          duplicateHabitTemplate({
                            habitTemplateId: habit.id,
                            index: index,
                          }),
                        );
                      }}
                      size="small"
                    >
                      <ContentCopyRoundedIcon fontSize="inherit" />
                    </IconButton>
                  )}
                  {(habit.user_id ||
                    habit.trainer_id ||
                    canEditGlobalHabits) && (
                    <IconButton
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      onClick={(event) => {
                        event.stopPropagation();

                        dispatch(removeHabitTemplate(habit.id)).then(() => {
                          enqueueSnackbar(`Deleted ${habit.name}`, {
                            variant: "success",
                            action: (key) => (
                              <>
                                <Button
                                  onClick={() => {
                                    closeSnackbar(key);
                                    dispatch(
                                      restoreHabitTemplate({
                                        habitTemplateId: habit.id,
                                        index: index,
                                      }),
                                    );
                                  }}
                                  color="inherit"
                                  variant="text"
                                >
                                  Undo
                                </Button>
                                <Button
                                  color="inherit"
                                  variant="text"
                                  onClick={() => {
                                    closeSnackbar(key);
                                  }}
                                >
                                  Dismiss
                                </Button>
                              </>
                            ),
                          });
                        });
                      }}
                      size="small"
                      color="error"
                    >
                      <DeleteRoundedIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Box>
              )}
            </Box>
          </CardActionArea>
        </div>
      </div>
      {previewContainer
        ? createPortal(<DragPreview text={habit.name} />, previewContainer)
        : null}
      {(habit.user_id || habit.trainer_id || canEditGlobalHabits) && (
        <HabitEditDialog
          open={isEditDialogOpen}
          onClose={() => {
            setIsEditDialogOpen(false);
          }}
          habitTemplateId={habit.id}
          defaultHabit={habit}
        />
      )}
    </>
  );
}
