import {
  DndContext,
  useDraggable,
  type DraggableAttributes,
} from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { CSS, type Coordinates } from "@dnd-kit/utilities";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import { Box, Button, Paper, useTheme } from "@mui/material";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import ScreenCapture from "@uppy/screen-capture";
import Transloadit from "@uppy/transloadit";
import Webcam from "@uppy/webcam";
import { useSnackbar } from "notistack";
import { memo, useEffect, useRef, useState } from "react";
import { TRANSLOADIT_AUTH_KEY } from "src/config/config";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  selectSelectedChat,
  sendImageMessage,
  sendVideoMessage,
  setMediaUploadUi,
} from "src/slices/chatSlice";

export function ChatMediaUpload() {
  const { width } = useWindowDimensions();
  const [{ x, y }, setCoordinates] = useState<Coordinates>({
    x: width / 2 - 200,
    y: 0,
  });

  return (
    <DndContext
      modifiers={[restrictToWindowEdges]}
      onDragEnd={({ delta }) => {
        setCoordinates(({ x, y }) => {
          return {
            x: x + delta.x,
            y: y + delta.y,
          };
        });
      }}
    >
      <ChatMediaUploadDraggable top={y} left={x} />
    </DndContext>
  );
}

type DraggableProps = {
  top: number;
  left: number;
};

function ChatMediaUploadDraggable({ top, left }: DraggableProps) {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useDraggable({
      id: "draggable_chat_media_upload",
    });

  return (
    <Paper
      ref={setNodeRef}
      sx={{
        height: 400,
        width: 400,
        position: "absolute",
        top: top,
        left: left,
        transform: CSS.Translate.toString(transform),
        zIndex: 9999,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        boxShadow: isDragging ? 12 : 6,
      }}
    >
      <ChatMediaUploadContent
        dragAttributes={attributes}
        dragListeners={listeners}
      />
    </Paper>
  );
}

type ContentProps = {
  dragAttributes: DraggableAttributes;
  dragListeners?: SyntheticListenerMap;
};

const ChatMediaUploadContent = memo(function ChatMediaUploadContent({
  dragAttributes,
  dragListeners,
}: ContentProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const selectedChat = useAppSelector(selectSelectedChat);
  const chatRef = useRef(selectedChat);
  const theme = useTheme();
  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*", "video/*"],
      },
      onBeforeFileAdded: () => {
        dispatch(setMediaUploadUi("uploading"));
        return true;
      },
    })
      .use(Webcam, {
        modes: ["video-audio", "picture"],
        videoConstraints: {
          facingMode: "user",
          width: { min: 480, max: 720, ideal: 480 },
          height: { min: 480, max: 720, ideal: 480 },
        },
        showRecordingLength: true,
        mobileNativeCamera: true,
        preferredImageMimeType: "image/jpeg",
      })
      .use(ScreenCapture)
      .use(Transloadit, {
        assemblyOptions: {
          params: {
            auth: { key: TRANSLOADIT_AUTH_KEY },
            template_id: "chat-media",
          },
        },
        waitForEncoding: true,
      })
      .on("complete", () => {
        uppy.cancelAll();
        closeMediaUpload();
        dispatch(setMediaUploadUi("hide"));
      })
      .on("transloadit:complete", (assembly) => {
        console.log("Uppy: upload success", assembly);

        if (
          "videos" in assembly.results &&
          assembly.results.videos.length &&
          assembly.results.videos[0].ssl_url &&
          "thumbnails" in assembly.results &&
          assembly.results.thumbnails.length &&
          assembly.results.thumbnails[0].ssl_url
        ) {
          const videoResult = assembly.results.videos[0];
          const thumbnailResult = assembly.results.thumbnails[0];

          dispatch(
            sendVideoMessage({
              video_url: videoResult.ssl_url,
              thumbnail_url: thumbnailResult.ssl_url,
              userId: chatRef.current!.id,
              toGroup: chatRef.current!.isGroupChat,
              asTrainwell: chatRef.current!.isTrainwell ?? false,
              width: videoResult.meta.width,
              height: videoResult.meta.height,
            }),
          )
            .unwrap()
            .catch(() => {
              enqueueSnackbar("Video failed to send", {
                variant: "error",
              });
            });
        } else if (
          "images" in assembly.results &&
          assembly.results.images.length &&
          assembly.results.images[0].ssl_url
        ) {
          const imageResult = assembly.results.images[0];

          dispatch(
            sendImageMessage({
              image_url: imageResult.ssl_url,
              userId: chatRef.current!.id,
              toGroup: chatRef.current!.isGroupChat,
              asTrainwell: chatRef.current!.isTrainwell ?? false,
              width: imageResult.meta.width,
              height: imageResult.meta.height,
            }),
          )
            .unwrap()
            .catch(() => {
              enqueueSnackbar("Image failed to send", {
                variant: "error",
              });
            });
        }
      }),
  );

  function closeMediaUpload() {
    dispatch(setMediaUploadUi("hide"));
  }

  return (
    <>
      <Box sx={{ flex: 1, borderBottom: 1, borderColor: "divider" }}>
        <Dashboard
          uppy={uppy}
          plugins={["Webcam", "ScreenCapture"]}
          proudlyDisplayPoweredByUppy={false}
          showLinkToFileUploadResult={false}
          height={"100%"}
          doneButtonHandler={() => {
            closeMediaUpload();
          }}
          theme={theme.palette.mode}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
          py: 1,
        }}
      >
        <DragIndicatorRoundedIcon
          sx={{
            cursor: "move",
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
          {...dragAttributes}
          {...dragListeners}
        />
        <Button
          onClick={() => {
            dispatch(setMediaUploadUi("hide"));
          }}
          variant="text"
        >
          Cancel
        </Button>
      </Box>
    </>
  );
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
