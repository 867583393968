import { useDraggable } from "@dnd-kit/core";
import CalendarViewWeekRoundedIcon from "@mui/icons-material/CalendarViewWeekRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Avatar,
  Box,
  Button,
  CardActionArea,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import { getSmartTagDetails } from "src/lib/phaseTags";
import { api } from "src/lib/trainwellApi";
import {
  deletePhaseTemplate,
  downloadPhaseTemplate,
  duplicatePhaseTemplate,
  savePhaseTemplateToTrainerLibrary,
  selectCanEditTrainwellLibrary,
  selectPhaseTemplateById,
  setPhaseTemplateEditing,
  sharePhaseTemplateToCoach,
  updatePhaseTemplate,
} from "src/slices/phaseTemplatesSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { ShareToCoachPopover } from "../ClientPage/SelectorColumn/ShareToCoachPopover";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.25),
}));

type Props = {
  phaseTemplateId: string;
};

export default function PhaseTemplateCell({ phaseTemplateId }: Props) {
  const dispatch = useAppDispatch();
  const phaseTemplate = useAppSelector((state) =>
    selectPhaseTemplateById(state, phaseTemplateId!),
  );
  const canEdit = useAppSelector(selectCanEditTrainwellLibrary)
    ? true
    : phaseTemplate?.trainer_id !== "copilot";
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: phaseTemplate?.draggable_id!,
    disabled: !canEdit,
    data: {
      type: "phase_template",
      phaseTemplateId: phaseTemplate?._id,
    },
  });
  const realCoach = useAppSelector((state) => state.trainer.trainer);
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [shareToCoachAnchorEl, setShareToCoachAnchorEl] =
    useState<null | HTMLButtonElement>(null);
  const openForUserId = useAppSelector(
    (state) => state.phaseTemplates.openForUserId,
  );
  const isDialog = Boolean(openForUserId);
  const { enqueueSnackbar } = useSnackbar();
  const [saved, setSaved] = useState(false);
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );

  if (!phaseTemplate) {
    return null;
  }

  return (
    <>
      <CardActionArea
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        onClick={() => {
          dispatch(
            setPhaseTemplateEditing({
              ...phaseTemplate,
              days_draggable: phaseTemplate.workout_template_ids.map((day) => {
                return {
                  draggable_id: crypto.randomUUID(),
                  templates: day.map((template) => {
                    return {
                      draggable_id: crypto.randomUUID(),
                      template_id: template,
                    };
                  }),
                };
              }),
            }),
          );
        }}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          py: 0.5,
          pr: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {phaseTemplate.is_pinned ? (
            <PushPinRoundedIcon
              sx={{
                fontSize: 12,
                width: "20px",
                color: (theme) => theme.palette.text.secondary,
              }}
            />
          ) : (
            <Box sx={{ width: "20px" }} />
          )}
          <Avatar
            sx={{
              backgroundColor: (theme) => theme.palette.success.main,
              width: 20,
              height: 20,
              borderRadius: "6px",
            }}
          >
            <CalendarViewWeekRoundedIcon
              sx={{
                fontSize: 16,
              }}
            />
          </Avatar>
          <Box sx={{ px: 2 }}>
            <Typography variant="h6">{phaseTemplate.name}</Typography>
            <Typography variant="overline">
              {phaseTemplate.description}
            </Typography>
            <Typography variant="overline" sx={{ mb: 0.5 }}>
              Updated{" "}
              {formatDistanceToNow(
                new Date(
                  phaseTemplate.date_updated ?? phaseTemplate.date_created,
                ),
              )}{" "}
              ago
            </Typography>
            {phaseTemplate.shared_by_trainer_id && (
              <Chip
                avatar={
                  <Avatar
                    alt="Trainer"
                    src={
                      trainerNames.find(
                        (c) =>
                          c.trainer_id === phaseTemplate.shared_by_trainer_id,
                      )?.headshot_url ?? ""
                    }
                  />
                }
                label={`Shared by ${trainerNames.find((c) => c.trainer_id === phaseTemplate.shared_by_trainer_id)?.full_name ?? "Unknown"}`}
                size="small"
                color="blueSurface"
              />
            )}
            <Box
              component={"ul"}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0,
                m: 0,
                mx: -0.25,
              }}
            >
              {phaseTemplate.tags.map((tag) => {
                const tagDetails = getSmartTagDetails(tag);

                return (
                  <ListItem key={tag}>
                    <Chip
                      icon={tagDetails.icon}
                      label={tagDetails.label}
                      size="small"
                      sx={{
                        fontSize: 12,
                      }}
                    />
                  </ListItem>
                );
              })}
            </Box>
            {isDialog && (
              <Button
                size="small"
                variant="contained"
                // fullWidth
                startIcon={<PersonRoundedIcon />}
                sx={{ mt: 1 }}
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(
                    downloadPhaseTemplate({
                      phaseTemplateId: phaseTemplate._id,
                      userId: openForUserId!,
                    }),
                  );
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              >
                Copy to client
              </Button>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            flex: "1 0 auto",
            justifyContent: "flex-end",
            alignItems: "center",
            display: "flex",
          }}
        >
          {phaseTemplate.trainer_id === "copilot" && (
            <Tooltip title="Save to your library" disableInteractive>
              <Button
                variant="text"
                size="small"
                startIcon={
                  saved ? <CheckRoundedIcon /> : <DownloadRoundedIcon />
                }
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(
                    savePhaseTemplateToTrainerLibrary({
                      phaseTemplateId: phaseTemplateId,
                      trainerId: trainerId,
                    }),
                  ).then(() => {
                    setSaved(true);
                  });
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                color={saved ? "success" : "primary"}
              >
                {saved ? "Saved" : "Save"}
              </Button>
            </Tooltip>
          )}
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();

              setMoreAnchorEl(event.currentTarget);
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            disabled={!canEdit}
          >
            <MoreVertRoundedIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </CardActionArea>
      <Menu
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(moreAnchorEl)}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            dispatch(
              updatePhaseTemplate({
                id: phaseTemplateId,
                isPinned: !phaseTemplate.is_pinned,
              }),
            )
              .unwrap()
              .catch(() => {
                enqueueSnackbar("Error pinning / unpinning", {
                  variant: "error",
                });
              });

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            {phaseTemplate.is_pinned ? (
              <RemoveCircleRoundedIcon fontSize="small" />
            ) : (
              <PushPinRoundedIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText primary={phaseTemplate.is_pinned ? "Unpin" : "Pin"} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(
              duplicatePhaseTemplate({
                phaseTemplateId: phaseTemplateId,
              }),
            );
            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ContentCopyRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Make a copy" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShareToCoachAnchorEl(moreAnchorEl);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <IosShareRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Share to trainer" />
        </MenuItem>
        {trainerHasAccess(
          realCoach?.access_roles,
          "trainwell_template_library",
        ) && (
          <MenuItem
            onClick={() => {
              setMoreAnchorEl(null);

              dispatch(
                sharePhaseTemplateToCoach({
                  phaseTemplateId,
                  trainerId: "copilot",
                }),
              )
                .unwrap()
                .then(() => {
                  enqueueSnackbar("Shared", {
                    variant: "success",
                  });
                })
                .catch(() => {
                  enqueueSnackbar("Error sharing", {
                    variant: "error",
                  });
                });
            }}
          >
            <ListItemIcon>
              <IosShareRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Share to trainwell" />
          </MenuItem>
        )}
        <Box>
          <Divider sx={{ my: 1 }} />
        </Box>
        <MenuItem
          onClick={() => {
            dispatch(deletePhaseTemplate({ phaseTemplateId: phaseTemplateId }));
            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            sx={{ color: (theme) => theme.palette.error.main }}
          />
        </MenuItem>
      </Menu>
      <ShareToCoachPopover
        onShare={(targetTrainerId) => {
          return api.phaseTemplates.shareToCoach({
            phaseTemplateId,
            trainerId: targetTrainerId,
          });
        }}
        anchorEl={shareToCoachAnchorEl}
        onClose={() => {
          setShareToCoachAnchorEl(null);
        }}
      />
    </>
  );
}
