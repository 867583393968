import { Box, Dialog, DialogContent } from "@mui/material";
import { useAppSelector } from "src/hooks/stateHooks";
import { DialogTitleWithClose } from "../misc/DialogTitleWithClose";
import { ClientNotes } from "./ClientNotes";

type Props = {
  open: boolean;
  onClose: () => void;
};

export function ClientNotesDialog({ open, onClose }: Props) {
  const client = useAppSelector((state) => state.client.client);

  if (!client) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitleWithClose onClose={onClose}>Notes</DialogTitleWithClose>
      <DialogContent sx={{ pb: 16 }}>
        <Box sx={{ mt: 1 }} />
        <ClientNotes userId={client.user_id} />
      </DialogContent>
    </Dialog>
  );
}
