import BedtimeRoundedIcon from "@mui/icons-material/BedtimeRounded";
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import WavingHandRoundedIcon from "@mui/icons-material/WavingHandRounded";
import {
  Box,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Tooltip,
} from "@mui/material";
import type { ActionItem, Client } from "@trainwell/types";
import { isFuture } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { notCheckedInToday } from "src/lib/misc";
import { api } from "src/lib/trainwellApi";
import { selectSentActionItems } from "src/slices/actionItemSlice";
import {
  fetchAllMessages,
  fetchChats,
  resetMassMessaging,
  setCheckInMode,
  type CheckInMode,
} from "src/slices/chatSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";
import LoadingComponent from "../miscPages/LoadingComponent";
import CheckInChatList from "./CheckInChatList";
import { MagicKeyTextField } from "./MagicKeyTextField";

type Props = {
  condensed?: boolean;
};

export default function CheckInPanel({ condensed }: Props) {
  const dispatch = useAppDispatch();
  const scrollRef = useRef<HTMLDivElement>();
  const clients = useAppSelector((state) => state.clients.clients);
  const clientsStatus = useAppSelector((state) => state.clients.status);
  const checkInMode = useAppSelector((state) => state.chat.checkInMode);
  const actionItems = useAppSelector(selectSentActionItems);
  const actionItemStatus = useAppSelector((state) => state.actionItems.status);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const isFocusMode = trainer?.settings.check_in_focus_mode ?? false;
  const [quickMessageData, setQuickMessageData] = useState<{
    enabled: boolean;
    checkInMode: CheckInMode | null;
  }>({
    enabled: false,
    checkInMode: null,
  });

  useEffect(() => {
    if (clientsStatus === "succeeded") {
      dispatch(fetchChats());
      dispatch(fetchAllMessages());
    }
  }, [dispatch, clientsStatus]);

  useEffect(() => {
    if (checkInMode === undefined) {
      dispatch(resetMassMessaging());
      setQuickMessageData({
        enabled: false,
        checkInMode: null,
      });
    }
  }, [checkInMode]);

  const {
    notCheckInTodayUserIds,
    workoutInternalFeedbackUserIds,
    workoutInternalNoFeedbackUserIds,
    workoutExternalUserIds,
    messageUserIds,
    missedWorkoutUserIds,
    questionUserIds,
    hiddenUserIds,
    saveForLaterUserIds,
    nothingNewUserIds,
    newUserIds,
    trialUserIds,
    unmessagedUserIds,
    billingUserIds,
  } = useMemo(() => {
    if (actionItemStatus !== "succeeded") {
      return {
        notCheckInTodayUserIds: [],
        workoutInternalFeedbackUserIds: [],
        workoutInternalNoFeedbackUserIds: [],
        workoutExternalUserIds: [],
        messageUserIds: [],
        missedWorkoutUserIds: [],
        questionUserIds: [],
        hiddenUserIds: [],
        saveForLaterUserIds: [],
        nothingNewUserIds: [],
        newUserIds: [],
        trialUserIds: [],
        unmessagedUserIds: [],
        billingUserIds: [],
      };
    }

    const notCheckInTodayUserIds = clients
      .filter(
        (client) =>
          notCheckedInToday(client) && !client.account.dashboard.is_hidden,
      )
      .map((client) => client.user_id);

    const workoutInternalFeedbackUserIds =
      getSortedUserIdsWithInternalFeedbackWorkouts(actionItems);
    const workoutInternalNoFeedbackUserIds =
      getSortedUserIdsWithInternalNoFeedbackWorkouts(actionItems);
    const workoutExternalUserIds = getSortedUserIdsWithExternalWorkouts(
      actionItems,
      isFocusMode,
    );

    const messageUserIds = getSortedUserIdsWithMessages(
      actionItems,
      isFocusMode,
    );

    const questionUserIds = getSortedUserIdsWithQuestions(actionItems);

    const missedWorkoutUserIds = getSortedUserIdsWithMissedWorkouts(
      actionItems,
      isFocusMode,
    );

    const unmessagedUserIds = getSortedUserIdsUnmessaged(clients, isFocusMode);

    const trialUserIds = clients
      .filter(
        (client) =>
          notCheckedInToday(client) &&
          !client.account.dashboard.is_hidden &&
          (!client.account.dashboard.date_onboarded ||
            isFuture(client.account.plan.date_trial_end as string)),
      )
      .sort((a, b) => {
        return a.full_name.localeCompare(b.full_name);
      })
      .map((client) => client.user_id);

    const newUserIds = getSortedUserIdsNew(actionItems);

    const hiddenUserIds = clients
      .filter((client) => client.account.dashboard.is_hidden)
      .sort((a, b) => {
        return a.full_name.localeCompare(b.full_name);
      })
      .map((client) => client.user_id);

    const saveForLaterUserIds = getSortedUserIdsSaveForLater(actionItems);

    const billingUserIds = getSortedUserIdsBilling(actionItems);

    const nothingNewUserIds = clients
      .filter(
        (client) =>
          notCheckedInToday(client) &&
          !client.account.dashboard.is_hidden &&
          !actionItems.some(
            (actionItem) => actionItem.user_id === client.user_id,
          ),
      )
      .map((client) => client.user_id);

    return {
      notCheckInTodayUserIds,
      workoutInternalFeedbackUserIds,
      workoutInternalNoFeedbackUserIds,
      workoutExternalUserIds,
      messageUserIds,
      missedWorkoutUserIds,
      questionUserIds,
      hiddenUserIds,
      saveForLaterUserIds,
      nothingNewUserIds,
      trialUserIds,
      newUserIds,
      unmessagedUserIds,
      billingUserIds,
    };
  }, [actionItemStatus, Boolean(checkInMode), isFocusMode]);

  function selectCheckInMode(mode: CheckInMode) {
    api.analytics.trackEvent({
      trainerId: trainer?.trainer_id,
      eventType: "trainer_check_in_mode",
      platform: "coach_dash",
      sessionId: "",
      eventContent: {
        check_in_mode: mode,
        quick_message: quickMessageData.enabled,
        focus: isFocusMode,
      },
    });

    if (quickMessageData.enabled) {
      setQuickMessageData({
        ...quickMessageData,
        checkInMode: mode,
      });
    } else {
      dispatch(
        setCheckInMode({
          type: mode,
        }),
      );
    }
  }

  function getUserIds(mode: CheckInMode) {
    switch (mode) {
      case "save_for_later":
        return saveForLaterUserIds;
      case "workouts_internal_feedback":
        return workoutInternalFeedbackUserIds;
      case "workouts_internal_no_feedback":
        return workoutInternalNoFeedbackUserIds;
      case "workouts_external":
        return workoutExternalUserIds;
      case "messages":
        return messageUserIds;
      case "questions":
        return questionUserIds;
      case "workouts_missed":
        return missedWorkoutUserIds;
      case "unmessaged":
        return unmessagedUserIds;
      case "hidden":
        return hiddenUserIds;
      case "nothing_new":
        return nothingNewUserIds;
      case "new_clients":
        return newUserIds;
      case "trial":
        return trialUserIds;
      case "everyone":
        return notCheckInTodayUserIds;
      case "billing_changes":
        return billingUserIds;
      default:
        return [];
    }
  }

  if (actionItemStatus !== "succeeded") {
    return <LoadingComponent />;
  }

  if (checkInMode) {
    return (
      <CheckInChatList
        userIds={getUserIds(checkInMode.type)}
        condensed={condensed}
      />
    );
  }

  if (quickMessageData.enabled && quickMessageData.checkInMode) {
    return (
      <MagicKeyTextField
        onBack={() => {
          setQuickMessageData({
            ...quickMessageData,
            checkInMode: null,
          });
          dispatch(resetMassMessaging());
        }}
        onContinue={() => {
          dispatch(
            setCheckInMode({
              type: quickMessageData.checkInMode!,
            }),
          );
        }}
        userIds={getUserIds(quickMessageData.checkInMode)}
        sx={{
          my: 2,
          mx: 1,
        }}
      />
    );
  }

  return (
    <Box
      ref={scrollRef}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.background.paper,
        px: 2,
        pb: 4,
        pt: 1,
      }}
    >
      <FormGroup>
        <Tooltip
          disableInteractive
          title="Changes some of the filters to include ONLY what they say"
          placement="top"
        >
          <FormControlLabel
            control={
              <Switch
                checked={isFocusMode}
                onChange={(event) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer!.trainer_id,
                      // @ts-expect-error
                      "settings.check_in_focus_mode": event.target.checked,
                    }),
                  );
                }}
              />
            }
            label="Focus"
          />
        </Tooltip>
        <FormControlLabel
          control={
            <Switch
              checked={quickMessageData.enabled}
              onChange={(event) => {
                setQuickMessageData({
                  ...quickMessageData,
                  enabled: event.target.checked,
                });
              }}
            />
          }
          label="Quick message"
        />
      </FormGroup>
      <List sx={{ width: "100%", mt: 2 }}>
        {checkInModes.map(({ mode, label, tooltip, icon }) => (
          <Tooltip
            disableInteractive
            title={
              typeof tooltip === "string"
                ? tooltip
                : tooltip
                  ? tooltip(isFocusMode)
                  : ""
            }
            key={mode}
          >
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  selectCheckInMode(mode);
                }}
                disabled={getUserIds(mode).length === 0}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  primary={label({
                    count: getUserIds(mode).length,
                    focusMode: isFocusMode,
                  })}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </Box>
  );
}

const getSortedUserIdsWithInternalFeedbackWorkouts = (
  actionItems: ActionItem[],
) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) =>
      item.type === "workout_review" &&
      item.is_internal &&
      item.has_alerts_or_feedback,
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithInternalNoFeedbackWorkouts = (
  actionItems: ActionItem[],
) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) =>
      item.type === "workout_review" &&
      item.is_internal &&
      !item.has_alerts_or_feedback,
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithExternalWorkouts = (
  actionItems: ActionItem[],
  isFocusMode: boolean,
) => {
  const excludeUserIds = isFocusMode
    ? actionItems
        .filter((item) => item.type === "workout_review" && item.is_internal)
        .map((item) => item.user_id)
    : [];

  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) =>
      item.type === "workout_review" &&
      !item.is_internal &&
      !excludeUserIds.includes(item.user_id),
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithMessages = (
  actionItems: ActionItem[],
  isFocusMode: boolean,
) => {
  const excludeUserIds = isFocusMode
    ? actionItems
        .filter((item) => item.type === "workout_review")
        .map((item) => item.user_id)
    : [];

  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) => item.type === "dm" && !excludeUserIds.includes(item.user_id),
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsUnmessaged = (
  clients: Client[],
  isFocusMode: boolean,
) => {
  const filteredClients = isFocusMode
    ? clients.filter(
        (client) =>
          notCheckedInToday(client) && !client.account.dashboard.is_hidden,
      )
    : clients.filter((client) => !client.account.dashboard.is_hidden);

  // Step 2: Sort by last message oldest to newest
  const sortedClients = filteredClients.sort((a, b) => {
    const aLastMessageDate = a.last_trainer_message_date as
      | string
      | null
      | undefined;
    const bLastMessageDate = b.last_trainer_message_date as
      | string
      | null
      | undefined;

    if (aLastMessageDate && bLastMessageDate) {
      return aLastMessageDate.localeCompare(bLastMessageDate);
    } else if (aLastMessageDate) {
      return 1;
    } else if (bLastMessageDate) {
      return -1;
    } else {
      return a.full_name.localeCompare(b.full_name);
    }
  });

  // Step 3: Map to get user_ids
  const userIds = sortedClients.map((client) => client.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithQuestions = (actionItems: ActionItem[]) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) => item.type === "respond" && item.respond_type === "question",
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithMissedWorkouts = (
  actionItems: ActionItem[],
  isFocusMode: boolean,
) => {
  const excludeUserIds = isFocusMode
    ? actionItems
        .filter((item) => item.type === "workout_review" || item.type === "dm")
        .map((item) => item.user_id)
    : [];

  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) =>
      item.type === "missed_workout" && !excludeUserIds.includes(item.user_id),
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsNew = (actionItems: ActionItem[]) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) =>
      item.type === "new_client" ||
      item.type === "client_returned_from_cancel" ||
      item.type === "client_returned_from_pause" ||
      item.type === "switched_coach_to_you" ||
      item.type === "client_unhidden",
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsBilling = (actionItems: ActionItem[]) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) =>
      item.type === "user_requested_cancelation" ||
      item.type === "user_requested_pause",
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsSaveForLater = (actionItems: ActionItem[]) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter((item) => item.type === "custom");

  // Get an array of user_ids from the fitlered items and order it by item.content.custom_type
  const sortedItems = filteredItems.sort((a, b) => {
    const aType = a.content?.custom_type ?? "other";
    const bType = b.content?.custom_type ?? "other";

    if (aType === bType) {
      return 0;
    } else if (aType === "priority") {
      return -1;
    } else if (bType === "priority") {
      return 1;
    } else if (aType === "workout") {
      return -1;
    } else if (bType === "workout") {
      return 1;
    } else if (aType === "video") {
      return -1;
    } else if (bType === "video") {
      return 1;
    } else if (aType === "message") {
      return -1;
    } else if (bType === "message") {
      return 1;
    } else if (aType === "calendar") {
      return -1;
    } else if (bType === "calendar") {
      return 1;
    } else {
      return 0;
    }
  });

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const checkInModes: {
  mode: CheckInMode;
  label: (options: { count: number; focusMode: boolean }) => string;
  tooltip?: ((focusMode: boolean) => string | undefined) | string;
  icon: React.ReactNode;
}[] = [
  {
    mode: "save_for_later",
    label: ({ count }) => `Later (${count})`,
    icon: <BookmarkRoundedIcon />,
  },
  {
    mode: "workouts_internal_feedback",
    label: ({ count }) => `Workouts with feedback (${count})`,
    icon: <FitnessCenterRoundedIcon />,
  },
  {
    mode: "workouts_internal_no_feedback",
    label: ({ count }) => `Workouts without feedback (${count})`,
    icon: <FitnessCenterRoundedIcon />,
  },
  {
    mode: "workouts_external",
    label: ({ count, focusMode }) =>
      focusMode
        ? `Only external workouts (${count})`
        : `External workouts (${count})`,
    icon: <FitnessCenterRoundedIcon />,
    tooltip: (focusMode) =>
      focusMode ? "Excludes clients with trainwell workouts" : "",
  },
  {
    mode: "questions",
    label: ({ count }) => `Questions (${count})`,
    icon: <QuestionMarkRoundedIcon />,
  },
  {
    mode: "messages",
    label: ({ count, focusMode }) =>
      `${focusMode ? "Only DMs" : "DMs"} (${count})`,
    icon: <ChatBubbleRoundedIcon />,
    tooltip: (focusMode) =>
      `Texts, images, and videos${focusMode ? ". Excludes clients with workouts" : ""}`,
  },
  {
    mode: "new_clients",
    label: ({ count }) => `New clients (${count})`,
    icon: <FiberNewOutlinedIcon />,
    tooltip:
      "New, transferred to you, returned from cancel, returned from pause",
  },
  {
    mode: "trial",
    label: ({ count }) => `Trial clients (${count})`,
    icon: <EmojiPeopleRoundedIcon />,
    tooltip: "Excludes clients who you checked in on today",
  },
  {
    mode: "workouts_missed",
    label: ({ count, focusMode }) =>
      `${focusMode ? "Only missed workouts" : "Missed workouts"} (${count})`,
    icon: <CropFreeRoundedIcon />,
    tooltip: (focusMode) =>
      focusMode ? "Excludes clients with DMs or workouts" : "",
  },
  {
    mode: "unmessaged",
    label: ({ count, focusMode }) =>
      `${focusMode ? "Unmessaged & not checked in" : "Unmessaged"} (${count})`,
    icon: <ChatBubbleOutlineRoundedIcon />,
    tooltip: "Sorts clients by unmessaged and then oldest message from you",
  },
  {
    mode: "billing_changes",
    label: ({ count }) => `Billing changes (${count})`,
    icon: <ReceiptRoundedIcon />,
    tooltip: "Requests to pause/cancel",
  },
  {
    mode: "everyone",
    label: ({ count }) => `Not checked in today (${count})`,
    icon: <WavingHandRoundedIcon />,
  },
  {
    mode: "nothing_new",
    label: ({ count }) => `Nothing new (${count})`,
    icon: <BedtimeRoundedIcon />,
    tooltip: "Excludes clients who you checked in on today",
  },
  {
    mode: "hidden",
    label: ({ count }) => `Hidden (${count})`,
    icon: <VisibilityOffRoundedIcon />,
  },
];
