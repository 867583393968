import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import type { Audit, HabitTemplate, Trainer } from "@trainwell/types";
import set from "lodash-es/set";
import { trainerHasAccess, type AccessLocation } from "src/lib/accessRoles";
import { api } from "src/lib/trainwellApi";
import { resetActionItems } from "./actionItemSlice";
import { resetChat, resetMassMessaging, setChatMode } from "./chatSlice";
import { resetClients } from "./clientsSlice";
import { resetPhaseTemplates } from "./phaseTemplatesSlice";
import type { RootState } from "./store";
import { resetTemplates } from "./templatesSlice";
import { updateTrainer } from "./trainersSlice";
import { resetVacations } from "./vacationsSlice";

export const fetchTrainer = createAsyncThunk(
  "trainer/fetchTrainer",
  async (
    data: { trainerId: string; auditeeTrainerId: string | null },
    { dispatch },
  ) => {
    const coachPromise = api.trainers.login(data.trainerId);

    const habitLibraryPromise = api.habitTemplates.getManyCoach(
      data.auditeeTrainerId ?? data.trainerId,
    );

    let auditeeTrainer: Trainer | undefined = undefined;

    if (data.auditeeTrainerId && data.auditeeTrainerId !== data.trainerId) {
      const trainer = await coachPromise;

      auditeeTrainer = await api.trainers.getOne(data.auditeeTrainerId);

      if (!trainer.is_admin && !auditeeTrainer.allow_audit_without_auth) {
        auditeeTrainer = undefined;
      }
    }

    const auditTagsPromise = api.audits.getTags(data.trainerId);
    const workoutCompletionPromise = api.trainers.getWorkoutCompletion(
      data.trainerId,
    );

    const [trainer, habitLibrary, auditTags, workoutCompletion] =
      await Promise.all([
        coachPromise,
        habitLibraryPromise,
        auditTagsPromise,
        workoutCompletionPromise,
      ]);

    dispatch(setChatMode(trainer.settings.chat_mode));

    Sentry.setUser({
      id: trainer.trainer_id,
      email: trainer.email,
      username: trainer.email,
    });

    return {
      trainer: trainer,
      auditCoach: auditeeTrainer,
      habitLibrary: habitLibrary,
      auditTags: auditTags,
      workoutCompletion,
    };
  },
);

export const fetchAuditeeTrainer = createAsyncThunk(
  "trainer/fetchAuditeeTrainer",
  async (auditeeTrainerId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    const auditeeTrainer = await api.trainers.getOne(auditeeTrainerId);

    if (
      !state.trainer.trainer?.is_admin &&
      !auditeeTrainer.allow_audit_without_auth
    ) {
      throw new Error("Not an admin");
    }

    console.log(`Audit: trainer id: '${auditeeTrainerId}`);

    const workoutCompletion =
      await api.trainers.getWorkoutCompletion(auditeeTrainerId);

    dispatch(setauditeeTrainer(auditeeTrainer));
    dispatch(setWorkoutCompletion(workoutCompletion.workoutCompletion));
    dispatch(resetTemplates());
    dispatch(resetPhaseTemplates());
    dispatch(resetActionItems());
    dispatch(resetChat());
    dispatch(resetClients());
    dispatch(resetVacations());
    dispatch(resetMassMessaging());

    return { coach: auditeeTrainer, workoutCompletion };
  },
);

export const exitAuditMode = createAsyncThunk(
  "trainer/exitAuditMode",
  async (_, { dispatch }) => {
    dispatch(setauditeeTrainer(undefined));
    dispatch(resetTemplates());
    dispatch(resetPhaseTemplates());
    dispatch(resetActionItems());
    dispatch(resetChat());
    dispatch(resetClients());

    return;
  },
);

export const toggleFavoriteExercise = createAsyncThunk(
  "trainer/toggleFavoriteExercise",
  async (exerciseId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    if (!trainer) {
      return;
    }

    let favoriteExerciseIds = JSON.parse(
      JSON.stringify(trainer.favorite_exercise_ids),
    ) as string[] | undefined;

    if (!favoriteExerciseIds) {
      favoriteExerciseIds = [exerciseId];
    } else {
      const index = favoriteExerciseIds.indexOf(exerciseId);

      if (index === -1) {
        favoriteExerciseIds.push(exerciseId);
      } else {
        favoriteExerciseIds.splice(index, 1);
      }
    }

    dispatch(
      updateTrainer({
        trainer_id: trainer.trainer_id,
        favorite_exercise_ids: favoriteExerciseIds,
      }),
    );

    return;
  },
);

export const fetchAudits = createAsyncThunk(
  "trainer/fetchAudits",
  async (trainerId: string) => {
    const audits = await api.audits.getClientAudits(trainerId);

    return audits;
  },
);

// Define a type for the slice state
interface TrainerState {
  trainer: Trainer | undefined;
  auditeeTrainer: Trainer | undefined;
  authenticated: boolean;
  auditTags: string[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  auditeeTrainerStatus: "idle" | "loading" | "succeeded" | "failed";
  habitLibrary: HabitTemplate[];
  audits: Audit[];
  workoutCompletion: undefined | number;

  /**
   * Certain dash features are disabled while ghosting
   * ex. socket registration, reading messages, completing action items
   * Set this to true to temporarily disable restrictions for testing
   */
  disableGhostingProtections: boolean;
}

// Define the initial state using that type
const initialState: TrainerState = {
  trainer: undefined,
  auditeeTrainer: undefined,
  authenticated: false,
  auditTags: [],
  status: "idle",
  error: undefined,
  auditeeTrainerStatus: "idle",
  habitLibrary: [],
  audits: [],
  workoutCompletion: undefined,
  disableGhostingProtections: false,
};

export const trainerSlice = createSlice({
  name: "trainer",
  initialState,
  reducers: {
    resetCoach: () => initialState,
    updateTrainerLocal: (
      state,
      action: PayloadAction<Partial<Trainer> & Pick<Trainer, "trainer_id">>,
    ) => {
      const update = action.payload;

      if (update.trainer_id === state.trainer?.trainer_id) {
        for (const [key, value] of Object.entries(update)) {
          set(state.trainer, key, value);
        }
      } else if (update.trainer_id === state.auditeeTrainer?.trainer_id) {
        for (const [key, value] of Object.entries(update)) {
          set(state.auditeeTrainer, key, value);
        }
      }
    },
    toggleAuditTag: (state, action: PayloadAction<string>) => {
      const tag = action.payload;

      const index = state.auditTags.findIndex((t) => t === tag);

      if (index === -1) {
        state.auditTags.unshift(tag);
      } else {
        state.auditTags = state.auditTags.filter((t) => t !== tag);
      }
    },
    upsertAudit: (state, action: PayloadAction<Audit>) => {
      const audit = action.payload;

      const index = state.audits.findIndex((a) => a.id === audit.id);

      if (index === -1) {
        state.audits.push(audit);
      } else {
        state.audits[index] = audit;
      }
    },
    deleteAudit: (state, action: PayloadAction<string>) => {
      const auditId = action.payload;

      state.audits = state.audits.filter((audit) => audit.id !== auditId);
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      const authenticated = action.payload;

      state.authenticated = authenticated;
    },
    setWorkoutCompletion: (state, action: PayloadAction<number>) => {
      const workoutCompletion = action.payload;

      state.workoutCompletion = workoutCompletion;
    },
    setDisableGhostingProtections: (state, action: PayloadAction<boolean>) => {
      const disableGhostingProtections = action.payload;

      state.disableGhostingProtections = disableGhostingProtections;
    },
    setauditeeTrainer: (state, action: PayloadAction<Trainer | undefined>) => {
      const auditeeTrainer = action.payload;

      if (
        !auditeeTrainer ||
        (!state.trainer?.is_admin && !auditeeTrainer.allow_audit_without_auth)
      ) {
        state.auditeeTrainer = undefined;
        return;
      }

      if (!auditeeTrainer.note_templates) {
        auditeeTrainer.note_templates = {};
      }

      auditeeTrainer.note_templates.personal =
        auditeeTrainer.note_templates.personal ??
        "- Work:\n- Fun/Life:\n- Location:\n- Relationships / Support System:";
      auditeeTrainer.note_templates.source =
        auditeeTrainer.note_templates.source ??
        "- How did you find trainwell:\n- What made you join / what's your why?:";
      auditeeTrainer.note_templates.exercise_history =
        auditeeTrainer.note_templates.exercise_history ??
        "- Health/exercise history:\n- Loved/hated exercises:\n- Injuries/constraints:\n- Prefered training/programming style:";
      auditeeTrainer.note_templates.programming =
        auditeeTrainer.note_templates.programming ??
        "- Equipment:\n- Frequency:\n- Duration:\n- Days per week:";

      state.auditeeTrainer = auditeeTrainer;
      state.disableGhostingProtections = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrainer.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchTrainer.fulfilled, (state, action) => {
      console.log("Redux: Got coach");
      state.status = "succeeded";

      const {
        trainer,
        auditCoach,
        habitLibrary,
        auditTags,
        workoutCompletion,
      } = action.payload;

      if (!trainer.note_templates) {
        trainer.note_templates = {};
      }

      trainer.note_templates.personal =
        trainer.note_templates.personal ??
        "- Work:\n- Fun/Life:\n- Location:\n- Relationships / Support System:";
      trainer.note_templates.source =
        trainer.note_templates.source ??
        "- How did you find trainwell:\n- What made you join / what's your why?:";
      trainer.note_templates.exercise_history =
        trainer.note_templates.exercise_history ??
        "- Health/exercise history:\n- Loved/hated exercises:\n- Injuries/constraints:\n- Prefered training/programming style:";
      trainer.note_templates.programming =
        trainer.note_templates.programming ??
        "- Equipment:\n- Frequency:\n- Duration:\n- Days per week:";

      if (auditCoach) {
        if (!auditCoach.note_templates) {
          auditCoach.note_templates = {};
        }

        auditCoach.note_templates.personal =
          auditCoach.note_templates.personal ??
          "- Work:\n- Fun/Life:\n- Location:\n- Relationships / Support System:";
        auditCoach.note_templates.source =
          auditCoach.note_templates.source ??
          "- How did you find trainwell:\n- What made you join / what's your why?:";
        auditCoach.note_templates.exercise_history =
          auditCoach.note_templates.exercise_history ??
          "- Health/exercise history:\n- Loved/hated exercises:\n- Injuries/constraints:\n- Prefered training/programming style:";
        auditCoach.note_templates.programming =
          auditCoach.note_templates.programming ??
          "- Equipment:\n- Frequency:\n- Duration:\n- Days per week:";

        state.auditeeTrainer = auditCoach;
      }

      state.trainer = trainer;
      state.habitLibrary = habitLibrary.habit_templates;
      state.auditTags = auditTags;
      state.workoutCompletion = workoutCompletion.workoutCompletion;
    });
    builder.addCase(fetchTrainer.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchAuditeeTrainer.pending, (state) => {
      state.auditeeTrainerStatus = "loading";
    });
    builder.addCase(fetchAuditeeTrainer.fulfilled, (state) => {
      console.log("Redux: Got auditee coach");
      state.audits = [];
      state.auditTags = [];
      state.habitLibrary = [];
      state.auditeeTrainerStatus = "succeeded";
    });
    builder.addCase(fetchAuditeeTrainer.rejected, (state) => {
      state.auditeeTrainerStatus = "failed";
    });
    builder.addCase(fetchAudits.fulfilled, (state, action) => {
      console.log("Redux: Got audits");

      state.audits = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetCoach,
  setAuthenticated,
  updateTrainerLocal,
  setauditeeTrainer,
  toggleAuditTag,
  upsertAudit,
  deleteAudit,
  setWorkoutCompletion,
  setDisableGhostingProtections,
} = trainerSlice.actions;

export default trainerSlice.reducer;

export const selectIsAuditing = (state: RootState) => {
  if (
    state.trainer.auditeeTrainer &&
    (state.trainer.trainer?.is_admin === true ||
      state.trainer.auditeeTrainer.allow_audit_without_auth === true)
  ) {
    return true;
  } else {
    return false;
  }
};

export const selectPrimaryTrainer = (state: RootState) => {
  const isAuditing = selectIsAuditing(state);

  if (isAuditing) {
    return state.trainer.auditeeTrainer;
  } else {
    return state.trainer.trainer;
  }
};

export const selectPrimaryCoachTrainerId = (state: RootState) => {
  const primaryCoach = selectPrimaryTrainer(state);

  return primaryCoach?.trainer_id;
};

export const selectShouldUseDefaultWeights = (state: RootState) => {
  const trainer = selectPrimaryTrainer(state);

  return !trainer?.settings.disable_smart_orm;
};

export const selectHasAccess = (state: RootState, location: AccessLocation) => {
  const trainer = selectPrimaryTrainer(state);

  if (!trainer || !trainer.access_roles) {
    return false;
  }

  const hasAccess = trainerHasAccess(trainer.access_roles, location);

  return hasAccess;
};
