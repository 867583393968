import MouseIcon from "@mui/icons-material/Mouse";
import PreviewRoundedIcon from "@mui/icons-material/PreviewRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getDateWithTimezoneOffset } from "src/lib/date";
import {
  addMassMessage,
  readChat,
  removeMassMessage,
  resetMassMessaging,
  selectMassMessages,
  sendMassMessages,
  setChatView,
  setFocusedUserId,
  setPreviewMassMessages,
  stageMassMessages,
} from "src/slices/chatSlice";
import { MagicKeyTextField } from "./MagicKeyTextField";
import { ThreadCard } from "./threads/ThreadCard";

export default function MassMessageView() {
  const dispatch = useAppDispatch();
  const [sending, setSending] = useState(false);
  const massMessagesToSendCount = useAppSelector(
    (state) => state.chat.chatIdsToMassMessage.length,
  );
  const previewMassMessages = useAppSelector(
    (state) => state.chat.previewMassMessages,
  );
  const { enqueueSnackbar } = useSnackbar();
  const [messageHtml, setMessageHtml] = useState("");

  function handleCloseDialog() {
    console.log("Resetting slate");
    dispatch(setChatView("default"));

    dispatch(resetMassMessaging());
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        p: 1,
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ flex: 1 }}>
        {previewMassMessages ? (
          <MessagePreviewPage messageHtml={messageHtml} />
        ) : (
          <>
            <MagicKeyTextField
              onUpdate={(newMessageHtml) => {
                setMessageHtml(newMessageHtml);
              }}
            />
          </>
        )}
      </Box>
      <DialogActions>
        {previewMassMessages ? (
          <>
            <Button
              variant="text"
              onClick={() => {
                dispatch(setPreviewMassMessages(false));
              }}
              disabled={sending}
            >
              Back
            </Button>
            <LoadingButton
              variant="contained"
              startIcon={<SendRoundedIcon />}
              onClick={() => {
                setSending(true);

                // const keys = editor.children
                //   .map((child) =>
                //     // @ts-expect-error
                //     child.children.map((item) =>
                //       item.type === "dynamic_key" ? item.key : undefined,
                //     ),
                //   )
                //   .flat()
                //   .flat()
                //   .filter(Boolean);

                dispatch(sendMassMessages({ dynamicKeys: [] }))
                  .unwrap()
                  .then(() => {
                    handleCloseDialog();
                    enqueueSnackbar("Sent messages", {
                      variant: "success",
                    });
                    setSending(false);
                  })
                  .catch(() => {
                    enqueueSnackbar("Something went wrong sending messages", {
                      variant: "error",
                    });
                    setSending(false);
                  });
              }}
              disabled={massMessagesToSendCount === 0}
              loading={sending}
            >
              Send {massMessagesToSendCount} message
              {massMessagesToSendCount !== 1 ? "s" : ""}
            </LoadingButton>
          </>
        ) : (
          <>
            <Button
              variant="text"
              onClick={() => {
                handleCloseDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              startIcon={<PreviewRoundedIcon />}
              onClick={() => {
                dispatch(stageMassMessages({ messageHtml: messageHtml }));
                dispatch(setPreviewMassMessages(true));
              }}
            >
              Preview
            </Button>
          </>
        )}
      </DialogActions>
    </Box>
  );
}

type MessagePreviewPageProps = {
  messageHtml: string;
};

function MessagePreviewPage({ messageHtml }: MessagePreviewPageProps) {
  const dispatch = useAppDispatch();
  /** used to perserve the order of mass message chats */
  const displayedChatIds = useRef<string[]>([]);

  const massMessageChats = useAppSelector((state) => {
    const messages = selectMassMessages(state, messageHtml);

    if (displayedChatIds.current.length === 0) {
      return messages;
    } else {
      // return messages in the order they were displayed
      messages.sort((a, b) => {
        return (
          displayedChatIds.current.indexOf(a.chat.id) -
          displayedChatIds.current.indexOf(b.chat.id)
        );
      });

      return messages;
    }
  });

  const chatIdsToMassMessage = useAppSelector(
    (state) => state.chat.chatIdsToMassMessage,
  );

  useEffect(() => {
    displayedChatIds.current = massMessageChats
      .sort((a, b) => {
        if (a.error && !b.error) {
          return 1;
        } else if (!a.error && b.error) {
          return -1;
        }

        if (
          a.chat.oldestUnreadMessageFromClient &&
          !b.chat.oldestUnreadMessageFromClient
        ) {
          return -1;
        } else if (
          !a.chat.oldestUnreadMessageFromClient &&
          b.chat.oldestUnreadMessageFromClient
        ) {
          return 1;
        }

        const aClientDate = getDateWithTimezoneOffset(
          today,
          a.chat.clientTimezoneOffset ?? 0,
        );
        const bClientDate = getDateWithTimezoneOffset(
          today,
          b.chat.clientTimezoneOffset ?? 0,
        );

        const aDayIndex = aClientDate.getDay();
        const bDayIndex = bClientDate.getDay();

        if (aDayIndex === todaysDayIndex && bDayIndex !== todaysDayIndex) {
          return 1;
        } else if (
          aDayIndex !== todaysDayIndex &&
          bDayIndex === todaysDayIndex
        ) {
          return -1;
        }

        return 0;
      })
      .map((chat) => chat.chat.id);
  }, []);

  const today = new Date();
  const todaysDayIndex = today.getDay();

  const errorCount = massMessageChats.filter((message) => message.error).length;

  return (
    <>
      <Typography sx={{ mb: 2 }}>
        Use the checkbox for messages you want to send. All unread messages are
        included in each preview, plus the latest message.
      </Typography>
      {errorCount > 0 && (
        <Chip
          label={`${errorCount} chats with errors`}
          color="error"
          sx={{ mb: 2 }}
        />
      )}
      <Box sx={{ display: "flex", maxWidth: "100%" }}>
        <Box sx={{ pr: 2, pl: 1 }}>
          <Tooltip
            title="Hover your mouse in this column to make scrolling easier"
            disableInteractive
          >
            <MouseIcon />
          </Tooltip>
        </Box>
        <Stack
          direction="column"
          spacing={4}
          flex={1}
          maxWidth={"100%"}
          overflow={"hidden"}
        >
          {massMessageChats.map((chat) => {
            const clientDate = getDateWithTimezoneOffset(
              today,
              chat.chat.clientTimezoneOffset ?? 0,
            );

            const clientDayIndex = clientDate.getDay();

            return (
              <Box key={chat.chat.id}>
                <ThreadCard
                  chatId={chat.chat.id}
                  color={chat.error === true ? "error" : "default"}
                  onMouseOverLinger={() => {
                    dispatch(setFocusedUserId(chat.chat.id));
                    dispatch(readChat(chat.chat.id));
                  }}
                />
                <Box sx={{ mx: 1, mt: 0.5 }}>
                  {chat.chat.oldestUnreadMessageFromClient && (
                    <Typography variant="overline">
                      ⚠️ Unread messages
                    </Typography>
                  )}
                  {clientDayIndex !== todaysDayIndex && (
                    <Typography variant="overline">
                      ⚠️ Client timezone is different day
                    </Typography>
                  )}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={chatIdsToMassMessage.includes(chat.chat.id)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              dispatch(addMassMessage(chat.chat.id));
                            } else {
                              dispatch(removeMassMessage(chat.chat.id));
                            }
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          disabled={chat.error}
                          size="small"
                        />
                      }
                      componentsProps={{
                        typography: { variant: "body2" },
                      }}
                      label="^ Looks good"
                    />
                  </FormGroup>
                </Box>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </>
  );
}
