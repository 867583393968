import CallRoundedIcon from "@mui/icons-material/CallRounded";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectClientById } from "src/slices/clientsSlice";
import { CallClientButton } from "../ClientPage/CallClientButton";
import CheckInDialog from "../misc/CheckInDialog";
import { ChatMediaDialog } from "./ChatMediaDialog";
import { ChatSearchDialog } from "./ChatSearchDialog";

type Props = {
  userId: string;
};

export function ChatMoreButton({ userId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [checkInDialogOpen, setCheckInDialogOpen] = useState(false);
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const client = useAppSelector((state) => selectClientById(state, userId));

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <IconButton
        size="small"
        onClick={(event) => {
          event.stopPropagation();

          setMoreAnchorEl(event.currentTarget);
        }}
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
      >
        <MoreVertRoundedIcon fontSize="small" />
      </IconButton>
      <Menu
        open={Boolean(moreAnchorEl)}
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={(event) => {
          (event as any).stopPropagation();

          setMoreAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();

            setSearchDialogOpen(true);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <SearchRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Search" />
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();

            setMediaDialogOpen(true);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <InsertPhotoRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Media" />
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();

            setCheckInDialogOpen(true);

            setMoreAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <SendRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Check-in" />
        </MenuItem>
        {client?.tests?.includes("coach_organic3") && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();

              setMoreAnchorEl(null);

              navigator.clipboard
                .writeText(
                  `https://join.trainwell.net/register?user_id=${userId}`,
                )
                .then(() => {
                  enqueueSnackbar("Copied to clipboard", {
                    variant: "success",
                  });
                });
            }}
          >
            <ListItemIcon>
              <PaymentRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Copy payment link" />
          </MenuItem>
        )}
        <CallClientButton
          clientPhoneNumber={client?.phone_number!}
          button={
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <ListItemIcon>
                <CallRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Call" />
            </MenuItem>
          }
        />
      </Menu>
      <ChatMediaDialog
        userId={userId}
        open={mediaDialogOpen}
        onClose={() => {
          setMediaDialogOpen(false);
        }}
      />
      <CheckInDialog
        userId={userId}
        open={checkInDialogOpen}
        onClose={() => {
          setCheckInDialogOpen(false);
        }}
      />
      <ChatSearchDialog
        userId={userId}
        open={searchDialogOpen}
        onClose={() => {
          setSearchDialogOpen(false);
        }}
      />
    </div>
  );
}
