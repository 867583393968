import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import DoNotDisturbRoundedIcon from "@mui/icons-material/DoNotDisturbRounded";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import type { HabitDay } from "@trainwell/types";
import { useEffect, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectChatById } from "src/slices/chatSlice";

const days = ["S", "M", "T", "W", "T", "F", "S"];

type Props = {
  userId: string;
};

export function WeekPreview({ userId }: Props) {
  const selectedChat = useAppSelector((state) => selectChatById(state, userId));
  const [habitDays, setHabitDays] = useState<HabitDay[]>();

  useEffect(() => {
    setHabitDays(undefined);

    if (userId && !selectedChat?.isGroupChat) {
      api.clients.getCurrentWeekHabitDays(userId).then((data) => {
        setHabitDays(data.habit_days);
      });
    }
  }, [userId, selectedChat?.isGroupChat]);

  if (!habitDays) {
    return <CircularProgress />;
  }

  if (habitDays.length < 6) {
    return (
      <Typography
        variant="body2"
        sx={{ color: (theme) => theme.palette.text.secondary, m: 1 }}
      >
        ⚠️ Could not find a current week for this client
      </Typography>
    );
  }

  return (
    <Box>
      {/* <Typography variant="overline" textAlign={"center"}>
        trainwell workouts
      </Typography> */}
      <Grid container>
        {days.map((dayLabel, dayIndex) => {
          const day = habitDays[dayIndex];
          const todaysIndex = new Date().getDay();

          const workouts = day.habit_tasks.filter(
            (task) => task.movement_type === "copilot_workout",
          );

          const didCompleteSomeWorkout = workouts.some(
            (workoutTask) => workoutTask.date_completed,
          );

          let state: "empty" | "missed" | "complete" | "todo" = "empty";

          if (
            dayIndex <= todaysIndex &&
            workouts.length > 0 &&
            didCompleteSomeWorkout
          ) {
            state = "complete";
          } else if (dayIndex >= todaysIndex && workouts.length > 0) {
            state = "todo";
          } else if (
            dayIndex < todaysIndex &&
            workouts.length > 0 &&
            !didCompleteSomeWorkout
          ) {
            state = "missed";
          }

          return (
            <Grid
              item
              xs
              key={dayIndex}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {dayLabel}
              </Typography>
              <Box
                sx={{
                  flex: 1,
                  border: 2,
                  borderLeft: dayIndex === 0 ? 2 : 0,
                  borderColor: "divider",
                  borderTopLeftRadius: dayIndex === 0 ? 10 : 0,
                  borderBottomLeftRadius: dayIndex === 0 ? 10 : 0,
                  borderTopRightRadius: dayIndex === 6 ? 10 : 0,
                  borderBottomRightRadius: dayIndex === 6 ? 10 : 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 0.5,
                }}
              >
                {state === "missed" ? (
                  <DoNotDisturbRoundedIcon color="error" />
                ) : state === "complete" ? (
                  <CheckCircleRoundedIcon color="primary" />
                ) : state === "todo" ? (
                  <CircleRoundedIcon color="primary" />
                ) : (
                  <CircleRoundedIcon sx={{ opacity: 0 }} />
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
