import { alpha, Box, type SxProps, type Theme } from "@mui/material";

type Props = {
  sx?: SxProps<Theme>;
};

export function UpcomingRestDayIcon({ sx = [] }: Props) {
  return (
    <Box
      sx={{
        width: 18,
        height: 18,
        borderRadius: 9,
        border: 2,
        borderColor: (theme) => alpha(theme.palette.text.disabled, 0.5),
        ...sx,
      }}
    />
  );
}
