import type { SxProps, Theme } from "@mui/material";
import { Button, Stack } from "@mui/material";
import type { Message } from "@trainwell/types";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { updateMessage } from "src/slices/chatSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

const reactions: { display: string; code: string }[] = [
  {
    display: String.fromCodePoint(parseInt("1F44D", 16)),
    code: "1F44D",
  },
  {
    display: String.fromCodePoint(parseInt("1F4AA", 16)),
    code: "1F4AA",
  },
  {
    display: String.fromCodePoint(parseInt("1F389", 16)),
    code: "1F389",
  },
  {
    display: String.fromCodePoint(parseInt("1F525", 16)),
    code: "1F525",
  },
  {
    display: "\u2764\ufe0f",
    code: "2764",
  },
];

type Props = {
  message: Message;
  onChoose?: () => void;
  sx?: SxProps<Theme>;
};

export default function Reactions({ message, sx = [], onChoose }: Props) {
  const dispatch = useAppDispatch();
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.trainer_id,
  );

  return (
    <Stack direction={"row"} spacing={0.5} sx={{ ...sx }}>
      {reactions.map((reaction, i) => (
        <Button
          variant={
            message.reactions?.some(
              (r) => r.user_id === trainerId && r.reaction === reaction.code,
            )
              ? "contained"
              : "text"
          }
          key={i}
          sx={{ width: "40px", minWidth: "40px", borderRadius: "8px" }}
          onClick={() => {
            let newReactions = [...(message.reactions ?? [])];

            const existingReaction = newReactions.find(
              (reaction) => reaction.user_id === trainerId,
            );

            if (existingReaction) {
              if (existingReaction.reaction === reaction.code) {
                // remove

                newReactions = newReactions.filter(
                  (reaction) => reaction.user_id !== trainerId,
                );
              } else {
                // swap

                newReactions = newReactions.filter(
                  (reaction) => reaction.user_id !== trainerId,
                );

                newReactions.push({
                  reaction: reaction.code,
                  user_id: trainerId!,
                  date: new Date().toISOString(),
                });
              }
            } else {
              // add new

              newReactions.push({
                reaction: reaction.code,
                user_id: trainerId!,
                date: new Date().toISOString(),
              });
            }

            dispatch(
              updateMessage({
                messageId: message.message_id,
                reactions: newReactions,
              }),
            );

            onChoose && onChoose();
          }}
        >
          {reaction.display}
        </Button>
      ))}
    </Stack>
  );
}
