import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { closeChat, setCheckInMode } from "src/slices/chatSlice";
import ControlBar from "../misc/ControlBar";
import ClientSelector from "./ClientSelector";

export default function HomePage() {
  const dispatch = useAppDispatch();
  const chatView = useAppSelector((state) => state.chat.view);

  useEffect(() => {
    if (chatView === "check_in") {
      dispatch(setCheckInMode(undefined));
      dispatch(closeChat());
    }
  }, []);

  console.log("Render: home");

  return (
    <>
      <ControlBar />
      <main>
        <ClientSelector />
      </main>
    </>
  );
}
